import {
  PageBody,
  Spacer,
  Tab,
  TabGroup,
  TabPanelProps,
  TextTitle,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';
import { FC, useState } from 'react';

import { General } from './tabs';
import { IPage } from '../types';

const content = [
  {
    tab: 'General',
    name: 'general',
    render: (props: TabPanelProps) => <General key={props.name} {...props} />,
  },
];

export const MyAccount: FC<IPage> = ({ title }) => {
  const [activePanel, setActivePanel] = useState<string>(content[0].name);

  return (
    <>
      <PageBody background="none">
        <TextTitle test-id="my-account-title">
          <TranslationComponent i18nKey="manager/preferences:title">
            {title}
          </TranslationComponent>
        </TextTitle>
        <Spacer size="large" />
        <TabGroup variant="contained">
          {content.map(({ tab, name }) => (
            <Tab
              active={activePanel === name}
              slot="tab"
              panel={name}
              key={name}
              onClick={() => setActivePanel(name)}
              // @todo: remove it when tabs will be required
              style={{ display: 'none' }}
            >
              {tab}
            </Tab>
          ))}
          {content.map(({ name, render }) =>
            render({ name, active: activePanel === name })
          )}
        </TabGroup>
      </PageBody>
    </>
  );
};
