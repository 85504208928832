import { formatTimeWithTimezone, FormatType } from '@pypestream/utils';
import { dateFnsLocales, fallbackLanguage } from '@pypestream/translations';
import {
  useUserCtxSelector,
  useManagerCtxSelector,
} from '../xstate/app.xstate';

export const useUserTimeZone = () => {
  const { timezones } = useManagerCtxSelector((ctx) => ({
    timezones: ctx.timezones,
  }));

  const { userTimeZoneId } = useUserCtxSelector((ctx) => ({
    userTimeZoneId: ctx.user?.settings?.defaultTimezone,
  }));

  return timezones.find((tz) => tz.id === userTimeZoneId)?.originalIdentifier;
};

type UseFormatTimeWithTimezoneProps = {
  fromDate?: string;
  formatType?: FormatType;
  addSuffix?: boolean;
};

export const useFormatTimeWithTimezone = ({
  fromDate,
  formatType,
  addSuffix = true,
}: UseFormatTimeWithTimezoneProps): string => {
  const { defaultLanguage } = useManagerCtxSelector((ctx) => ({
    defaultLanguage: ctx.userInfo?.defaultLanguage,
  }));

  const userTimeZone = useUserTimeZone();

  return formatTimeWithTimezone({
    fromDate,
    locale: dateFnsLocales[defaultLanguage || fallbackLanguage],
    addSuffix,
    userTimeZone,
    formatType,
  });
};
