/* eslint-disable no-plusplus */
import { html, nothing, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { customElement, BaseElement } from '../base-element';

import '../stack/stack.wc';
import '../list/src/inline-list.wc';
import '../buttons/icon-button/icon-button.wc';
import '../text/text-caption.wc';
import '../icon/icon.wc';
import '../input/input.wc';
import '../select';

import paginationStyles from './pagination.scss?inline';

@customElement('ps-pagination')
export class PaginationWC extends BaseElement {
  @property({ type: Number }) total = 50;

  @property({ type: Number }) pageSize = 10;

  @property({ type: Number }) currentPage = 1;

  @property({ type: Array }) pageSizeOptions = [10, 20, 50, 100];

  @property({ type: Boolean, reflect: true }) showPageSizeSelector = true;

  static styles = unsafeCSS(paginationStyles);

  get totalPages() {
    return Math.ceil(this.total / this.pageSize);
  }

  handlePageChange(_page: number) {
    let page = _page;
    if (page < 1) page = 1;
    if (page > this.totalPages) page = this.totalPages;
    this.currentPage = page;
    this.emit('change', {
      detail: { page: this.currentPage, pageSize: this.pageSize },
    });
  }

  handlePageSizeChange(event: Event) {
    const select = event.target as HTMLSelectElement;
    this.pageSize = parseInt(select.value, 10);
    this.currentPage = 1;
    this.emit('show-size-change', {
      detail: { page: this.currentPage, pageSize: this.pageSize },
    });
  }

  renderPageButtons() {
    const maxVisibleButtons = 6;
    const { totalPages } = this;
    const { currentPage } = this;
    const pages = [];

    const dots = html`<ps-icon name="meatballs" size="medium"></ps-icon>`;

    if (totalPages <= maxVisibleButtons) {
      // If total pages are within limit, just show all page numbers
      for (let i = 1; i <= totalPages; i++) {
        pages.push(this.renderPageButton(i));
      }
    } else {
      // Always show the first and last page
      pages.push(this.renderPageButton(1));

      // Determine range for middle pages
      const left = Math.max(2, currentPage - 1);
      const right = Math.min(totalPages - 1, currentPage + 1);

      if (left > 2) {
        pages.push(html`${dots}`); // Left ellipsis if we're beyond the beginning
      }

      // Add range of middle pages
      for (let i = left; i <= right; i++) {
        pages.push(this.renderPageButton(i));
      }

      if (right < totalPages - 1) {
        pages.push(html`${dots}`); // Right ellipsis if we're far from the end
      }

      pages.push(this.renderPageButton(totalPages)); // Always show the last page
    }

    return pages;
  }

  renderPageButton(page: number) {
    return html`
      <button
        class="${classMap({
          'c-pagination__button': true,
          'c-pagination__button--selected': this.currentPage === page,
        })}"
        @click="${() => this.handlePageChange(page)}"
      >
        <span class="c-pagination__button-inner">${page}</span>
      </button>
    `;
  }

  render() {
    return html`
      <div class="c-pagination">
        <ps-stack
          justifyContent="space-between"
          alignItems="center"
          gutter="large"
        >
          <ps-stack alignItems="center" gutter="xsmall">
            <button
              class="c-pagination__button"
              ?disabled="${this.currentPage === 1}"
              @click="${() => this.handlePageChange(this.currentPage - 1)}"
            >
              <span class="c-pagination__button-inner">
                <ps-icon name="chevron-left" size="small"></ps-icon>
              </span>
            </button>

            ${this.renderPageButtons()}

            <button
              class="c-pagination__button"
              ?disabled="${this.currentPage === this.totalPages}"
              @click="${() => this.handlePageChange(this.currentPage + 1)}"
            >
              <span class="c-pagination__button-inner">
                <ps-icon name="chevron-right" size="small"></ps-icon>
              </span>
            </button>
          </ps-stack>

          <ps-stack alignItems="center">
            <ps-text-caption
              >${`Showing ${this.pageSize * (this.currentPage - 1) + 1} - ${this.pageSize * this.currentPage} of ${this.total}`}</ps-text-caption
            >
            ${this.showPageSizeSelector
              ? html`<ps-select
                  size="small"
                  @change="${(e: Event) => {
                    e.stopPropagation();
                    this.handlePageSizeChange(e);
                  }}"
                  value="${this.pageSize}"
                  variant="outlined"
                  style="width:128px"
                >
                  ${this.pageSizeOptions.map(
                    (size) =>
                      html`<ps-select-option value="${size}">
                        ${`${size}/page`}
                      </ps-select-option>`
                  )}
                </ps-select>`
              : nothing}
          </ps-stack>
        </ps-stack>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-pagination': PaginationWC;
  }
}
