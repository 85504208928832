/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable wc/guard-super-call */
import { createElement } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import CodeMirror, { EditorState, EditorView } from '@uiw/react-codemirror';
import * as allThemes from '@uiw/codemirror-themes-all';
import type { Extension } from '@codemirror/state';
import { minimap } from '@replit/codemirror-minimap';

import { BaseElementWithGlobalStyles, customElement } from '../../base-element';
import { watch } from '../../base-element/directives/watch.directive';

import styles from './code-editor.scss?inline';

export type { Extension };

// themes' list: https://uiwjs.github.io/react-codemirror/#/theme/home
export type ThemeNames =
  | 'abcdef'
  | 'abyss'
  | 'androidstudio'
  | 'andromeda'
  | 'atomone'
  | 'aura'
  | 'basicDark'
  | 'basicLight'
  | 'bbedit'
  | 'bespin'
  | 'consoleDark'
  | 'consoleLight'
  | 'copilot'
  | 'darcula'
  | 'dracula'
  | 'duotoneDark'
  | 'duotoneLight'
  | 'eclipse'
  | 'githubDark'
  | 'githubLight'
  | 'gruvboxDark'
  | 'gruvboxLight'
  | 'kimbie'
  | 'material'
  | 'materialDark'
  | 'materialLight'
  | 'monokai'
  | 'monokaiDimmed'
  | 'noctisLilac'
  | 'nord'
  | 'okaidia'
  | 'quietlight'
  | 'red'
  | 'solarizedDark'
  | 'solarizedLight'
  | 'sublime'
  | 'tokyoNight'
  | 'tokyoNightDay'
  | 'tokyoNightStorm'
  | 'tomorrowNightBlue'
  | 'vscodeDark'
  | 'vscodeLight'
  | 'whiteDark'
  | 'whiteLight'
  | 'xcodeDark'
  | 'xcodeLight';

export const codeEditorThemeList = Object.entries(allThemes)
  .filter(([_key, value]) => typeof value !== 'function')
  .map(([key]) => key)
  .filter((key) => !key.includes('Style'))
  .filter((key) => !key.includes('Init'))
  .filter((key) => !key.includes('defaultSettings')) as ThemeNames[];

@customElement('ps-code-editor')
export class CodeEditorWC extends BaseElementWithGlobalStyles {
  static styles = unsafeCSS(styles);

  root: Root;

  private editorTheme: Extension;

  @property({ type: String }) theme?: ThemeNames = 'githubDark';

  @watch('theme')
  handleThemeChange() {
    // @ts-expect-error
    this.editorTheme = allThemes[this.theme];
  }

  @property() height?: string;

  @property({ type: Array }) extensions?: Extension[];

  @property() value?: string;

  @property({ type: Object }) onChange?: (value: string) => void;

  @property() name?: string;

  @property({ type: Boolean }) disabled?: boolean;

  private hiddenInput?: HTMLInputElement;

  connectedCallback() {
    super.connectedCallback();
    if (!this.root) {
      this.root = createRoot(this.renderRoot);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    // Remove the hidden input
    if (this.hiddenInput) {
      this.hiddenInput.remove();
    }
  }

  private updateHiddenInput(value: string) {
    if (this.name && !this.hiddenInput) {
      this.hiddenInput = document.createElement('input');
      this.hiddenInput.type = 'hidden';
      this.hiddenInput.name = this.name;
      this.hiddenInput.value = this.value || '';
      this.appendChild(this.hiddenInput);
    }
    if (this.hiddenInput) {
      this.hiddenInput.value = value;
    }
  }

  public getValue() {
    return this.value;
  }

  render() {
    this.root.render(
      createElement(CodeMirror, {
        height: this.height,
        extensions: [...(this.extensions || []), minimap()],
        value: this.value,
        onCreateEditor: (view: EditorView, state: EditorState) => {
          if (this.value) {
            this.updateHiddenInput(this.value); // Update hidden input value
          }
        },
        onChange: (value: string) => {
          this.value = value;
          this.updateHiddenInput(value); // Update hidden input value
          if (this.onChange) {
            this.onChange(value);
          }
        },
        theme: this.editorTheme,
        editable: !this.disabled,
        basicSetup: {
          highlightActiveLine: !this.disabled,
          highlightActiveLineGutter: !this.disabled,
        },
      })
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-code-editor': CodeEditorWC;
  }
  enum PSElementTagNameMap {
    'ps-code-editor' = 'ps-code-editor',
  }
}
