import { Button, Icon } from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';

export const CreateProjectButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <Button
      size="large"
      test-id="create-project-btn"
      data-cy="new-project"
      onClick={onClick}
      disabled={disabled}
    >
      <Icon slot="prefix" name="plus" size="xsmall" />
      <TranslationComponent i18nKey="manager/common:homepage.projects.createProject">
        Create Project
      </TranslationComponent>
    </Button>
  );
};
