import * as React from 'react';
import { createComponent } from '@lit/react';

import { CodeEditorWC } from './code-editor.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type CodeEditorProps = GenerateComponentTypeWithChildren<CodeEditorWC>;

export type CodeEditorPropsWithRef = GeneratePropsWithRef<CodeEditorProps>;

export const CodeEditor = createComponent({
  tagName: CodeEditorWC.tagname,
  elementClass: CodeEditorWC,
  react: React,
}) as CodeEditorPropsWithRef;
