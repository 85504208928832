/* eslint-disable lit/no-invalid-html */
import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { nanoid } from 'nanoid';
import { BaseElement, customElement } from '../base-element';
import styles from './avatar.scss?inline';

import '../image/image.wc';

const avatarOptions = {
  small: {
    width: '24px',
    height: '24px',
  },
  medium: {
    width: '32px',
    height: '32px',
  },
  large: {
    width: '40px',
    height: '40px',
  },
  xlarge: {
    width: '48px',
    height: '48px',
  },
  '2xlarge': {
    width: '56px',
    height: '56px',
  },
  '3xlarge': {
    width: '152px',
    height: '152px',
  },
};

@customElement('ps-avatar')
export class AvatarWC extends BaseElement {
  static styles = unsafeCSS(styles);

  // eslint-disable-next-line lit/no-native-attributes
  @property({ reflect: true }) id = `avatar-${nanoid()}`;

  /**
   * Component size.
   */
  @property({ reflect: true }) size:
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | '2xlarge'
    | '3xlarge' = 'medium';

  /**
   * The src attribute for the img element.
   */
  @property({ reflect: true }) src?: string;

  /**
   * Load failure fault-tolerant src
   */
  @property({ type: String }) fallback?: string = '';

  /**
   * The label attribute for the img element.
   */
  @property({ reflect: true }) label: string;

  /** The avatar's shapes: square or circle */
  @property({ reflect: true }) shape: 'square' | 'circle' = 'circle';

  private _onError(_event: Event) {
    if (this.fallback) {
      this.src = this.fallback;
    } else {
      this.src = '';
    }
  }

  render() {
    const label =
      this.label
        ?.split(' ')
        ?.map((w) => w.charAt(0).toUpperCase())
        ?.join('') || 'U';

    return html`
      <span
        data-cy="avatar"
        class="${classMap({
          'c-avatar': true,
          [`c-avatar--size-${this.size}`]: this.size,
          [`c-avatar--shape-${this.shape}`]: this.shape,
        })}"
      >
        <!-- @todo:
          1. need to actually get srcset assets but adding this attribute as a reminder
          2. referrerpolicy needed to load profile photo coming from Google
          -->
        ${this.src || this.fallback
          ? html`<ps-image
              width="${avatarOptions[this.size].width}"
              height="${avatarOptions[this.size].height}"
              src="${ifDefined(this.src)}"
              alt="${this.label}"
              data-cy="avatar-img"
              @onerror="${this._onError}"
              fallback="${ifDefined(this.fallback)}"
              radius="${this.shape === 'circle' ? 'medium' : 'small'}"
            />`
          : html`<span class="c-avatar__name"
              >${this.slotted() ? html`<slot></slot>` : html`${label}`}</span
            >`}
      </span>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-avatar': AvatarWC;
  }
  enum PSElementTagNameMap {
    'ps-avatar' = 'ps-avatar',
  }
}
