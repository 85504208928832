import { createComponent } from '@lit/react';
import * as React from 'react';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../components/component-types-generics';
import { UniversalNavWC } from './universal-nav.wc';

export type UniversalNavProps =
  GenerateComponentTypeWithChildren<UniversalNavWC> & {
    onProjectSelect?: ({
      detail: { selection },
    }: {
      detail: { selection: string | null };
    }) => void;
    onSelectionChange?: ({
      detail: { selection },
    }: {
      detail: { selection: string | undefined };
    }) => void;
  };

export type UniversalNavPropsWithRef = GeneratePropsWithRef<UniversalNavProps>;

export const UniversalNav = createComponent({
  tagName: UniversalNavWC.tagname,
  elementClass: UniversalNavWC,
  react: React,
  events: {
    onProjectSelect: 'project-select',
    onSelectionChange: 'selection-change',
  },
}) as UniversalNavPropsWithRef;
