/* eslint-disable lit/no-invalid-html, lit/binding-positions */
import { PropertyValueMap, unsafeCSS, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { html, literal } from 'lit/static-html.js';
import { property, state, query } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { FormControlMixin } from '@open-wc/form-control';
import {
  BaseElementWithGlobalStyles,
  customElement,
  FormControlController,
  FormControl,
  validValidityState,
  NonCheckableFormControl,
  SlotObserver,
} from '../../base-element';
import buttonStyles from './button.scss?inline';
import { Translate } from '../../base-element/mixins/translation-mixin';

// demonstrating how global var definitions work
import buttonVars1 from './button.vars1.scss?inline';
import buttonVars2 from './button.vars2.scss?inline';

export const ButtonVariants = {
  primary: 'primary',
  secondary: 'secondary',
  ghost: 'ghost',
  warning: 'warning',
  danger: 'danger',
} as const;

export type ButtonVariant = keyof typeof ButtonVariants;

/**
 * @element ps-button
 *
 * @cssprop --ps-button--icon-size - adjusts the size of icons in the `prefix` and `suffix` slots
 * @cssprop --ps-button--icon-spacing - adjusts the space between slotted icons and button text
 */
@customElement('ps-button')
export class ButtonWC
  extends Translate(
    FormControlMixin(
      SlotObserver(BaseElementWithGlobalStyles, [
        '[slot]',
        '[slot="prefix"]',
        '[slot="suffix"]',
      ])
    )
  )
  implements NonCheckableFormControl
{
  /** Checks for validity but does not show a validation message. Returns `true` when valid and `false` when invalid. */
  checkValidity() {
    if (this.isButton()) {
      return (this.button as HTMLButtonElement).checkValidity();
    }

    return true;
  }

  defaultValue?: unknown;

  defaultChecked?: boolean | undefined;

  pattern?: string | undefined;

  min?: number | string | Date;

  max?: number | string | Date;

  step?: number | 'any' | undefined;

  required?: boolean | undefined;

  minlength?: number | undefined;

  maxlength?: number | undefined;

  static styles = unsafeCSS(buttonStyles);

  static globalStyles = [unsafeCSS(buttonVars1), unsafeCSS(buttonVars2)];

  /** An optional value for the button. Ignored when `href` is set. */
  @property({ attribute: false }) value: unknown = '';

  @property() validationMode: FormControl['validationMode'] = 'onSubmit';

  /** Gets the validity state object */
  get validity() {
    if (this.isButton()) {
      return (this.button as HTMLButtonElement).validity;
    }

    return validValidityState;
  }

  /** Gets the validation message */
  get validationMessage() {
    if (this.isButton()) {
      return (this.button as HTMLButtonElement).validationMessage;
    }

    return '';
  }

  // The following properties and methods aren't strictly required,
  // but browser-level form controls provide them. Providing them helps
  // ensure consistency with browser-provided controls.
  /** Gets the associated form, if one exists. */
  getForm(): HTMLFormElement | null {
    return this.formControlController.getForm();
  }

  /** An optional name for the button. Ignored when `href` is set. */
  @property() name = '';

  /**
   * @method
   * @return {boolean} Returns true if internals's target element has no validity
   * problems; otherwise, returns false, fires an invalid event at the element, and (if
   * the event isn't canceled) reports the problem to the user.
   */
  reportValidity() {
    // return this.internals.reportValidity();
    if (this.isButton()) {
      return (this.button as HTMLButtonElement).reportValidity();
    }

    return true;
  }

  _variant: ButtonVariant = 'primary';

  set variant(newValue: ButtonVariant) {
    const oldVal = this._variant;
    this._variant = newValue;
    this.requestUpdate('variant', oldVal);
  }

  /** The button's variant. */
  @property({ reflect: true, attribute: true })
  get variant() {
    return this._variant;
  }

  /** The button's data-testid. */
  @property({ reflect: true }) 'data-testid'?: string = 'wc-button';

  /** The width's option. */
  @property({ reflect: true }) width: 'auto' | 'full' = 'auto';

  /** The button's size. */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' | 'xlarge' =
    'medium';

  /** Set button rounded shape. */
  @property({ reflect: true, type: Boolean }) rounded = false;

  /** Disables the button. */
  @property({ reflect: true, type: Boolean }) disabled?: boolean = false;

  /**
   * The type of button. When the type is `submit`, the button will submit the surrounding form. Note that the default
   * value is `button` instead of `submit`, which is opposite of how native `<button>` elements behave.
   */
  @property({ reflect: true }) type?: 'button' | 'submit' | 'reset' = 'button';

  /** When set, the underlying button will be rendered as an `<a>` with this `href` instead of a `<button>`. */
  @property() href?: HTMLAnchorElement['href'];

  /** If button rendered as an `<a>`, it is possible to set target attribute. */
  @property({ reflect: true }) target?: HTMLAnchorElement['target'];

  private readonly formControlController = new FormControlController(this, {
    assumeInteractionOn: ['click'],
    form: (input) => {
      // Buttons support a form attribute that points to an arbitrary form, so if this attribute it set we need to query
      // the form from the same root using its id
      if (input.hasAttribute('form')) {
        const doc = input.getRootNode() as Document | ShadowRoot;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const formId = input.getAttribute('form')!;
        return doc.getElementById(formId) as HTMLFormElement;
      }

      // Fall back to the closest containing form
      return input.closest('form');
    },
  });

  private handleInvalid(event: Event) {
    this.formControlController.setValidity(false);
    this.formControlController.emitInvalidEvent(event);
  }

  @query('.c-btn') button?: HTMLButtonElement | HTMLLinkElement;

  /** Sets a custom validation message. If `message` is not empty, the field will be considered invalid. */
  setCustomValidity(message: string) {
    if (this.isButton()) {
      (this.button as HTMLButtonElement).setCustomValidity(message);
      this.formControlController.updateValidity();
    }
  }

  @state() invalid = false;

  handleClick(event: MouseEvent) {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    // if the React implementation prevents default when an onClick is detected, we should intelligently respond to allow that
    if (event.defaultPrevented === true) {
      event.preventDefault();
      return;
    }

    if (this.type === 'submit') {
      this.formControlController.submit(this);
    }

    if (this.type === 'reset') {
      this.formControlController.reset(this);
    }
  }

  private isButton() {
    return !this.href;
  }

  private isLink() {
    return !!this.href;
  }

  firstUpdated(
    changedProperties: PropertyValueMap<unknown> | Map<PropertyKey, unknown>
  ) {
    super.firstUpdated(changedProperties);
    this.addEventListener('click', this.handleClick);
  }

  // workaround to the make sure the extendable `variant` prop's attribute is initially set when booting up
  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    this.setAttribute('variant', this.variant);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this.handleClick);
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
  }

  focus(options?: FocusOptions) {
    (this.button as HTMLButtonElement).focus(options);
  }

  render() {
    const isLink = this.isLink();
    const tag = isLink ? literal`a` : literal`button`;

    return html`
      <${tag}
        class="${classMap({
          'c-btn': true,
          [`c-btn--variant-${this.variant}`]: this.variant,
          [`c-btn--size-${this.size}`]: this.size,
          [`c-btn--width-${this.width}`]: this.width,
          'c-btn--rounded': this.rounded,
        })}"
        ?disabled=${ifDefined(isLink ? undefined : this.disabled)}
        href=${ifDefined(isLink ? this.href : undefined)}
        target=${ifDefined(isLink ? this.target : undefined)}
        type=${ifDefined(isLink ? undefined : this.type)}
        name="${this.name}"
        value=${ifDefined(isLink ? undefined : this.value)}
        part="button"
        @invalid=${this.isButton() ? this.handleInvalid : null}
        @click=${this.handleClick}
      >
        <span class="c-btn__inner">
          ${
            this.hasSlottedContent('[slot="prefix"]')
              ? html`
                  <slot
                    name="prefix"
                    class="c-btn__prefix"
                    part="prefix"
                  ></slot>
                `
              : nothing
          }
          <span class="c-btn__label">
            <slot></slot>
          </span>
          ${
            this.hasSlottedContent('[slot="suffix"]')
              ? html`
                  <slot
                    name="suffix"
                    class="c-btn__suffix"
                    part="suffix"
                  ></slot>
                `
              : nothing
          }
        </span>
      </${tag}>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-button': ButtonWC;
  }
  enum PSElementTagNameMap {
    'ps-button' = 'ps-button',
  }
}
