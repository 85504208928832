import React from 'react';
import {
  format as formatDateFns,
  formatRelative as formatRelativeDateFns,
  isValid as isDateValid,
  formatDistance,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  parseISO,
} from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { Translation } from 'react-i18next';
import { i18n, dateFnsLocales } from '@pypestream/translations';

enum DateFormats {
  // Need to fix localization for `at` key word.
  'default' = "MM/dd/yyyy 'at' hh:mm aa",
}

export { isDateValid };

export const format = (
  date: Date | number,
  dateFormat: DateFormats = DateFormats.default,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }
): JSX.Element => {
  return (
    <Translation>
      {() =>
        formatDateFns(date, dateFormat, {
          ...options,
          ...(!options?.locale && {
            locale: dateFnsLocales[i18n.language],
          }),
        })
      }
    </Translation>
  );
};

export const formatRelative = (
  date: Date | number,
  baseDate: Date | number,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  }
): JSX.Element => {
  return (
    <Translation>
      {() =>
        formatRelativeDateFns(date, baseDate, {
          ...options,
          ...(!options?.locale && {
            locale: dateFnsLocales[i18n.language],
          }),
        })
      }
    </Translation>
  );
};

export const getDateOrNull = (value: Date | string | null): Date | null => {
  if (value !== null && isDateValid(new Date(value))) {
    return new Date(value);
  }

  return null;
};

export type FormatType = 'strict' | 'toNow' | 'distance';

interface FormatTimeOptions {
  fromDate?: string;
  toDate?: string; // Only for `formatDistance`.
  locale?: Locale;
  formatType?: FormatType;
  userTimeZone?: string;
  addSuffix?: boolean;
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'; // Only for `formatDistanceToNowStrict`.
  roundingMethod?: 'floor' | 'ceil' | 'round'; // Only for `formatDistanceToNowStrict`.
}

export const formatTimeWithTimezone = (options: FormatTimeOptions): string => {
  try {
    const {
      fromDate,
      toDate,
      userTimeZone,
      locale,
      formatType = 'toNow',
      addSuffix = true,
      unit,
      roundingMethod,
    } = options;

    if (!fromDate) {
      throw new Error('fromDate is required');
    }

    if (formatType === 'distance' && !toDate) {
      throw new Error('Parameter toDate is required for formatDistance method');
    }

    if (formatType !== 'distance' && toDate) {
      throw new Error(
        'Parameter toDate is required only for formatDistance method'
      );
    }

    if (formatType !== 'strict' && (unit || roundingMethod)) {
      throw new Error(
        'Parameter unit or roundingMethod is required only for formatDistanceToNowStrict method'
      );
    }

    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const parsedFromDate = parseISO(fromDate);
    const parsedToDate = parseISO(toDate || new Date().toISOString());

    const zonedToDate = toZonedTime(
      parsedToDate,
      userTimeZone || browserTimeZone
    );

    const zonedFromDate = toZonedTime(
      parsedFromDate,
      userTimeZone || browserTimeZone
    );

    switch (formatType) {
      case 'strict':
        return formatDistanceToNowStrict(zonedFromDate, {
          addSuffix,
          unit,
          roundingMethod,
          locale,
        });

      case 'distance':
        return formatDistance(zonedFromDate, zonedToDate, {
          addSuffix,
          locale,
        });

      case 'toNow':
      default:
        return formatDistanceToNow(zonedFromDate, {
          addSuffix,
          locale,
        });
    }
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date format';
  }
};
