/* eslint-disable react-hooks/exhaustive-deps */
import { ProductName } from '@pypestream/api-services';
import {
  NavLink,
  NavLinkProps,
  NavLinkWC,
  NavLinks,
  UniversalNav,
  UniversalUserDropdown,
  UniversalUserDropdownTrigger,
} from '@pypestream/design-system';
import {
  TransKeyProp,
  TranslationComponent,
  TranslationKeys,
} from '@pypestream/translations';
import { removeTrailingSlash } from '@pypestream/utils';
import { usePostHog } from 'posthog-js/react';
import React, { FC, useMemo } from 'react';
import {
  NavLinkProps as NavLinkPropsReactRouter,
  useHref,
  useLinkClickHandler,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom';

import { OrganizationRoleNames, useAuthRole } from '../../hooks';
import {
  sendUserEvent,
  useGlobalAppCtxSelector,
  useManagerCtxSelector,
  useManagerStateMatchesOneOf,
} from '../../xstate/app.xstate';

type NavItem = {
  to: string;
  label: string;
  hidden: boolean;
  i18nKey?: string;
};

export const NavLinkWrapper = React.forwardRef<
  NavLinkProps,
  TransKeyProp &
    NavLinkPropsReactRouter & {
      onClick?: React.MouseEventHandler<NavLinkWC>;
      children?: React.ReactNode;
      isActive?: boolean;
      disabled?: boolean;
      external?: boolean;
      label?: string;
    }
>(
  (
    {
      label,
      onClick,
      replace = false,
      state,
      target,
      to,
      children,
      i18nKey,
      isActive = false,
      disabled = false,
      external = false,
    },
    ref
  ) => {
    const href = useHref(to);
    const handleClick = useLinkClickHandler<NavLinkWC>(to, {
      replace,
      state,
      target,
    });

    return (
      <NavLink
        test-id={`nav-link-${label?.toLowerCase()}`}
        active={isActive}
        href={external ? String(to) : href}
        onClick={(event: React.MouseEvent<NavLinkWC, MouseEvent>) => {
          if (!external) {
            onClick?.(event);
            if (!event.defaultPrevented && !disabled) {
              handleClick(event);
            }
          }
        }}
        ref={ref}
        target={target}
        i18nKey={i18nKey}
        disabled={disabled}
      >
        {children}
      </NavLink>
    );
  }
);

export const PrimaryNav: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isUserProfilePage = useMatch('/organization/:org_id/my-account');
  const posthog = usePostHog();

  const shouldUpdateUser = !!useManagerStateMatchesOneOf([
    'orgRelated.ready.orgs.added',
    'orgRelated.ready.orgs.updated',
    'orgRelated.ready.orgs.deleted',
    'orgRelated.ready.userInfo.refetched',
  ]);

  const shouldWaitDataLoad = !!useManagerStateMatchesOneOf([
    'orgRelated.ready.projects.loading',
    'orgRelated.ready.users.loading',
    'orgRelated.ready.teams.loading',
    'orgRelated.ready.childOrgs.loading',
    'orgRelated.ready.orgs.loading',
  ]);

  const {
    orgName,
    selectedOrgId,
    selectedProject,
    routes,
    isPypestreamEmployee,
    selectedProjectDetails,
  } = useManagerCtxSelector((ctx) => ({
    orgName: ctx.orgs?.find(({ id }) => id === ctx.selectedOrgId)?.name,
    routes: ctx.routes,
    selectedOrgId: ctx.selectedOrgId,
    isPypestreamEmployee: Boolean(ctx.userInfo?.isPypestreamEmployee),
    selectedProject: ctx.selectedProject,
    selectedProjectDetails: ctx.projects?.find(
      (project) => project.projectId === ctx.selectedProject
    ),
    defaultLanguage: ctx.userInfo?.defaultLanguage,
  }));

  const isAdminRole = useAuthRole([
    OrganizationRoleNames.ADMIN,
    OrganizationRoleNames.SUPER_ADMIN,
  ]);

  const pathAsArray = removeTrailingSlash(pathname).split('/').filter(Boolean);

  const featureFlags = useGlobalAppCtxSelector((ctx) => ctx.featureFlags);

  const navItems = useMemo(() => {
    const items: NavItem[] = [
      {
        to: routes.wipTeams,
        label: 'WIP Teams',
        hidden: !featureFlags?.wipUsers,
      },
      {
        to: routes.users,
        label: 'Users',
        hidden: false,
        i18nKey: 'manager/common:navMenu.users',
      },
      {
        to: routes.teams,
        label: 'Teams',
        hidden: !isPypestreamEmployee,
        i18nKey: 'manager/common:navMenu.teams',
      },
      {
        to: routes.projects,
        label: 'Projects',
        hidden: false,
        i18nKey: 'manager/common:navMenu.projects',
      },
      {
        to: routes.orgs,
        label: 'Orgs',
        hidden: false,
        i18nKey: 'manager/common:navMenu.orgs',
      },
      {
        to: routes.logs,
        label: 'Logs',
        hidden: !isAdminRole,
        i18nKey: 'manager/common:navMenu.logs',
      },
    ];

    return items.filter((item) => !item.hidden);
  }, [routes, featureFlags?.wipUsers, isAdminRole]);

  const isHomePage = useMemo(
    () => pathname === '/' || pathAsArray.length === 2,
    [pathname, pathAsArray]
  );

  return (
    <UniversalNav
      app={ProductName.Organization}
      preventRedirect
      shouldUpdateUser={shouldUpdateUser}
      slot="nav"
      org={selectedOrgId}
      projectId={selectedProject}
      hideGoHome={isHomePage}
      onManagerToolClick={navigate}
      isDisabledOrgs={shouldWaitDataLoad}
      onSelectionChange={({ detail: { selection: orgId } }) => {
        if (!orgId || shouldWaitDataLoad) return;

        const entityPaths = ['users', 'projects', 'teams'];
        const currentPath = pathname;

        if (isUserProfilePage) {
          return navigate(routes.myAccount);
        }

        const getMatchedEntity = (path: string, entities: string[]) =>
          entities.find((entity) => path.includes(`/${entity}/`));

        const matchedEntity = getMatchedEntity(currentPath, entityPaths);

        if (selectedOrgId && matchedEntity) {
          navigate(`/organization/${orgId}/${matchedEntity}`);
          return;
        }

        if (selectedProject) {
          navigate(`/organization/${orgId}/projects`);
          return;
        }

        if (selectedOrgId) {
          const updatedPath = currentPath.replace(selectedOrgId, orgId);
          navigate(updatedPath);
          return;
        }

        navigate(`/organization/${orgId}`);
      }}
    >
      <NavLinks slot="navigation">
        {!isHomePage && (
          <>
            {navItems.map(({ to, label, i18nKey }) => (
              <NavLinkWrapper
                label={label}
                key={label}
                to={to}
                isActive={
                  (pathname.endsWith('edit') && label === 'Orgs') ||
                  pathname.includes(to)
                }
                external={to.includes(routes.admin)}
              >
                {i18nKey ? (
                  <TranslationComponent i18nKey={i18nKey as TranslationKeys}>
                    {label}
                  </TranslationComponent>
                ) : (
                  label
                )}
              </NavLinkWrapper>
            ))}
          </>
        )}
      </NavLinks>

      <UniversalUserDropdownTrigger test-id="user-dropdown-trigger" />

      <UniversalUserDropdown
        onPreferencesClick={() => navigate(routes.myAccount)}
        onLogout={async () => {
          posthog.reset();
          sendUserEvent('user.signOut');
        }}
      />
    </UniversalNav>
  );
};
