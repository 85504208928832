import { html, unsafeCSS } from 'lit';
import { BaseElement, customElement } from '../base-element';
import styles from './empty-state.scss?inline';

@customElement('ps-empty-state')
export class EmptyStateWC extends BaseElement {
  static styles = unsafeCSS(styles);

  render() {
    return html`
      <div class="c-empty-state">
        <div class="c-empty-state__inner">
          <div class="c-empty-state__content">
            <slot></slot>
          </div>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-empty-state': EmptyStateWC;
  }
  enum PSElementTagNameMap {
    'ps-empty-state' = 'ps-empty-state',
  }
}
