import {
  BlockList,
  Grid,
  GridCell,
  Input,
  Spacer,
  TextSubtitle,
  TextTitle,
} from '@pypestream/design-system';
import { TranslationComponent, useTranslation } from '@pypestream/translations';
import { FC, useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { IPage } from '../../types';

type IUserDetailsProps = IPage & {
  isInviteUser?: boolean;
  form: UseFormReturn<UserProfileDetailsFormValues>;
};

interface UseUserProfileDetailsFormProps {
  formValues: UserProfileDetailsFormValues;
}

export type UserProfileDetailsFormValues = {
  firstName: string;
  lastName: string;
  email: string;
};

export const useUserProfileDetailsForm = ({
  formValues,
}: UseUserProfileDetailsFormProps) => {
  const form = useForm<UserProfileDetailsFormValues>({
    defaultValues: formValues,
    shouldUseNativeValidation: true,
  });

  const { setValue, watch, getValues, reset, trigger } = form;

  const watchedValues = {
    firstName: watch('firstName'),
    lastName: watch('lastName'),
    email: watch('email'),
  };

  useEffect(() => {
    Object.entries(formValues).forEach(([key, value]) => {
      setValue(key as keyof UserProfileDetailsFormValues, value);
    });
  }, [setValue, formValues]);

  return {
    form,
    watchedValues,
    setValue,
    getValues,
    reset,
    trigger,
  };
};

export const UserProfileDetails: FC<IUserDetailsProps> = ({
  isInviteUser = false,
  form,
}) => {
  const { t } = useTranslation();

  const formElements = {
    get firstName() {
      return (
        <Input
          {...form.register('firstName', { required: true, minLength: 1 })}
          placeholder={`${t('manager/common:firstName')}`}
          variant="outlined"
          required
          disabled={!isInviteUser}
        />
      );
    },
    get lastName() {
      return (
        <Input
          {...form.register('lastName', { required: true, minLength: 1 })}
          placeholder={`${t('manager/common:lastName')}`}
          variant="outlined"
          required
          disabled={!isInviteUser}
        />
      );
    },
    get email() {
      return (
        <Input
          {...form.register('email', {
            required: true,
            pattern: {
              // https://github.com/manishsaraan/email-validator/blob/master/index.js#L3C14-L3C143
              // using same email pattern as email-validator as it's used with backend service
              value:
                /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/,
              message: 'Entered value does not match email format',
            },
          })}
          placeholder={`${t('manager/common:emailAddress')}`}
          type="email"
          variant="outlined"
          required
          disabled={!isInviteUser}
        />
      );
    },
  };

  return (
    <form>
      <TextTitle test-id="user-details-subtitle" size="xsmall">
        Details
      </TextTitle>
      <Spacer size="large" />

      <BlockList>
        <Grid alignItems="center">
          <GridCell xsmall="12" medium="3">
            <TextSubtitle size="small" variant="secondary">
              <TranslationComponent i18nKey="manager/preferences:general.name">
                Name*
              </TranslationComponent>
            </TextSubtitle>
          </GridCell>
          <GridCell xsmall="12" medium="9">
            <Grid alignItems="center">
              <GridCell xsmall="12" medium="6">
                {formElements.firstName}
              </GridCell>
              <GridCell xsmall="12" medium="6">
                {formElements.lastName}
              </GridCell>
            </Grid>
          </GridCell>
        </Grid>

        <Grid alignItems="center">
          <GridCell xsmall="12" medium="3">
            <TextSubtitle size="small" variant="secondary">
              <TranslationComponent i18nKey="manager/preferences:general.email_address">
                Email address*
              </TranslationComponent>
            </TextSubtitle>
          </GridCell>
          <GridCell xsmall="12" medium="9">
            {formElements.email}
          </GridCell>
        </Grid>
      </BlockList>
    </form>
  );
};
