import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  dateFnsLocales,
  fallbackLanguage,
  TranslationComponent,
  useTranslation,
} from '@pypestream/translations';
import {
  DataTable,
  DataTableWC,
  PageBody,
  PageSection,
  Spacer,
  Stack,
  Text,
  TextTitle,
  Pagination,
  Button,
  Icon,
} from '@pypestream/design-system';
import { formatTimeWithTimezone } from '@pypestream/utils';
import { html } from 'lit/static-html.js';

import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
} from '../../xstate/app.xstate';
import { Loader } from '../../components';
import { IPage } from '../types';
import { useUserTimeZone } from '../../hooks';

const INITIAL_SKIP_PAGES = 0;
const INITIAL_PAGE_SIZE = 100;

export const LogsPage: React.FC<IPage> = ({ title }) => {
  const [skipPages, setSkipPages] = useState<number>(INITIAL_SKIP_PAGES);

  const isNewOrgLoading = useManagerStateMatches(
    'orgRelated.ready.orgs.loading'
  );

  const isReadyToLoad = useManagerStateMatches(
    'orgRelated.ready.currentOrg.selected'
  );
  const isLoaded = useManagerStateMatches('orgRelated.ready.logs.loaded');
  const isLoading = useManagerStateMatches('orgRelated.ready.logs.loading');

  const { defaultLanguage, auditLogs } = useManagerCtxSelector((ctx) => ({
    orgId: ctx.selectedOrgId,
    auditLogs: ctx.logs,
    defaultLanguage: ctx.userInfo?.defaultLanguage,
  }));

  const { rows: logs, count } = auditLogs;

  const tableRef = useRef<DataTableWC>(null);
  const [t] = useTranslation();
  const userTimeZone = useUserTimeZone();

  const fetchLogsData = useCallback(
    (skip: number, size: number, invalidateCache: boolean = false) => {
      sendManagerEvent({
        type: 'manager.logs.loadLogs',
        queryMetadata: {
          skipPages: skip,
          pageSize: size,
        },
        invalidateCache: invalidateCache,
      });
    },
    []
  );

  const onPaginationChange = useCallback(
    (_index: number, _size: number, invalidateCache: boolean = false) => {
      // The first page is 0, but the first page in the pagination is 1, also prevent initial render if the index is 0
      const skip = _index === 0 ? 0 : _index - 1;

      setSkipPages(skip);
      fetchLogsData(skip, _size, invalidateCache);
    },
    [fetchLogsData]
  );

  // Fetch logs when the page is loaded and there are no logs
  useEffect(() => {
    if (isReadyToLoad && (!logs || !logs.length)) {
      fetchLogsData(INITIAL_SKIP_PAGES, INITIAL_PAGE_SIZE);
    }
  }, [isReadyToLoad, fetchLogsData, logs]);

  // Reset the skip pages when a new org is loaded
  useEffect(() => {
    if (isNewOrgLoading) {
      onPaginationChange(INITIAL_SKIP_PAGES, INITIAL_PAGE_SIZE);
    }
  }, [isNewOrgLoading, onPaginationChange]);

  const pagination = useMemo(
    () =>
      logs && logs.length ? (
        <Pagination
          total={count}
          pageSize={INITIAL_PAGE_SIZE}
          currentPage={skipPages + 1}
          onChange={({ detail: { page, pageSize: _pageSize } }) =>
            onPaginationChange(page, _pageSize)
          }
          onShowSizeChange={({ detail: { page, pageSize: _pageSize } }) =>
            onPaginationChange(page, _pageSize)
          }
          showPageSizeSelector={false}
        />
      ) : null,
    [count, logs, onPaginationChange, skipPages]
  );

  return (
    <PageBody background="none">
      <PageSection>
        <>
          <Stack alignItems="center" justifyContent="space-between">
            <TextTitle size="xsmall">
              <TranslationComponent i18nKey="manager/logs:title">
                {title}
              </TranslationComponent>
            </TextTitle>
            <Stack
              justifyContent="end"
              alignItems="center"
              nowrap
              style={{ flex: 1 }}
            >
              {pagination}
              <Button
                size="large"
                onClick={() =>
                  onPaginationChange(
                    INITIAL_SKIP_PAGES,
                    INITIAL_PAGE_SIZE,
                    true
                  )
                }
                disabled={isLoading}
              >
                <Icon slot="prefix" name="refresh" size="xsmall" />
                Refresh
              </Button>
            </Stack>
          </Stack>
          <Spacer size="xlarge" />
          {!isLoaded ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Loader relative />
            </div>
          ) : (
            <>
              <Spacer size="xlarge" />
              <DataTable
                data={logs}
                ref={tableRef}
                columns={[
                  {
                    accessorKey: 'action',
                    header: () =>
                      t('manager/logs:table.column1', {
                        defaultValue: 'Action',
                      }) || '',
                    cell: ({ row, renderValue }) => {
                      return html`
                        <ps-text
                          size="2xsmall"
                          font-weight="medium"
                          style="max-width: 400px;"
                          >${row.original?.auditLogDetail
                            ?.displayMessage}</ps-text
                        >
                      `;
                    },
                  },
                  {
                    accessorKey: 'user',
                    accessorFn: (row) =>
                      `${row.user?.firstName || ''} ${row.user?.lastName || ''} ${row.user?.userName || ''}`,
                    header: () =>
                      t('manager/logs:table.column2', {
                        defaultValue: 'User',
                      }) || '',
                    cell: ({ row, renderValue }) => {
                      return html`<ps-stack
                        gutter="small"
                        display="inline-flex"
                        direction="row"
                        nowrap
                        alignItems="center"
                      >
                        <ps-avatar
                          label="${row.original?.user?.firstName} ${row.original
                            ?.user?.lastName}"
                          src="${row.original?.user?.picture || ''}"
                        ></ps-avatar>
                        <div>
                          <ps-text-body variant="secondary"
                            >${row.original?.user?.firstName}
                            ${row.original?.user?.lastName}</ps-text-body
                          >
                          <ps-text-body size="small" variant="secondary"
                            >${row.original?.user?.userName}</ps-text-body
                          >
                        </div>
                      </ps-stack>`;
                    },
                  },
                  {
                    accessorKey: 'when',
                    header: () =>
                      t('manager/logs:table.column3', {
                        defaultValue: 'When',
                      }) || '',
                    cell: ({ row, renderValue }) =>
                      html`<ps-text-body variant="secondary" truncate>
                        ${formatTimeWithTimezone({
                          fromDate: row.original.createdAt,
                          locale:
                            dateFnsLocales[defaultLanguage || fallbackLanguage],
                          userTimeZone,
                        })}
                      </ps-text-body> `,
                  },
                ]}
              />
              <Spacer size="xlarge" />
              {!logs ||
                (!logs.length && (
                  <Text
                    size="xsmall"
                    textAlign="center"
                    className="u-margin-top-xlarge"
                    i18nKey="manager/logs:empty"
                  >
                    No data.
                  </Text>
                ))}
              {pagination}
            </>
          )}
        </>
      </PageSection>
    </PageBody>
  );
};
