import { AppletInstance } from '@pypestream/api-services';
import {
  Grid,
  GridCell,
  Icon,
  PageSection,
  Spacer,
  Stack,
  TextTitle,
} from '@pypestream/design-system';
import {
  TranslationComponent,
  TranslationKeys,
} from '@pypestream/translations';
import { FC } from 'react';
import { useFieldArray } from 'react-hook-form';

import { ProjectEnvironmentType, ReturnFormType } from '../../../../components';
import { getEnvironmentName } from '../../../../utils';
import { EnvironmentApplets } from './environment-applets';
import { EnvironmentCertificates } from './environment-certificates';
import { EnvironmentPlatforms } from './environment-platforms';
import { EnvironmentSummary } from './environment-summary';

interface EnvironmentPanelProps {
  form: ReturnFormType;
  environment: ProjectEnvironmentType;
  projectId: string;
  environmentIndex: number;
  disabled?: boolean;
  accountId?: string;
}

export const EnvironmentPanel: FC<EnvironmentPanelProps> = ({
  form,
  environment,
  projectId,
  environmentIndex,
  disabled,
  accountId,
}) => {
  const { control, getValues } = form;

  // Dynamically create the field array for the found release channel
  const environmentField = useFieldArray({
    control,
    name: `projectEnvironmentConfigs.${environmentIndex}.domains`,
    rules: {
      minLength: 1,
    },
  });

  const [applets] = getValues(['applets']);

  const [environmentId] = getValues([
    `projectEnvironmentConfigs.${environmentIndex}.id`,
  ]);

  const envVariant = (() => {
    switch (environment?.name) {
      case 'Testing':
        return 'staging';
      case 'Production':
        return 'prod';
      case 'Development':
        return 'dev';
      default:
        return 'dev';
    }
  })();

  return (
    <div className={`c-environments c-environments--${envVariant}`}>
      <Stack alignItems="center" gutter="small">
        <div className="c-environments__icon">
          <Icon name="cloud" size="small" color="teal" />
        </div>
        <TextTitle size="medium">
          <TranslationComponent
            i18nKey={
              `manager/projects:environments.${getEnvironmentName(environment?.name)}` as TranslationKeys
            }
          >
            {environment?.name}
          </TranslationComponent>
        </TextTitle>
      </Stack>
      <Spacer size="large" />
      <PageSection className="c-environments__section">
        <EnvironmentSummary
          form={form}
          environment={getEnvironmentName(environment?.name || '')}
          environmentField={environmentField}
          environmentIndex={environmentIndex}
          projectId={projectId}
        />
      </PageSection>
      <PageSection className="c-environments__section">
        <EnvironmentPlatforms disabled={disabled} />
      </PageSection>
      <Grid gutter="large">
        <GridCell xsmall="12" medium="9">
          <PageSection className="c-environments__section">
            <EnvironmentApplets
              applets={applets.filter(
                (applet: AppletInstance) =>
                  applet.projectEnvironmentConfigId === environmentId
              )}
              disabled={disabled}
              accountId={accountId}
              projectId={projectId}
              environmentId={environmentId}
            />
          </PageSection>
        </GridCell>
        <GridCell xsmall="12" medium="3">
          <PageSection size="small">
            <EnvironmentCertificates />
          </PageSection>
        </GridCell>
      </Grid>
    </div>
  );
};
