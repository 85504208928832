/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Client,
  CombinedError,
  fetchExchange,
  subscriptionExchange,
} from '@urql/core';
import { AuthConfig, authExchange } from '@urql/exchange-auth';
import { SubscribePayload, createClient as createWSClient } from 'graphql-ws';
import { getSdk } from './generated/graphql.urql';
// re-enable when we're working to get gql caching back up and running
// import schema from './generated/graphql.schema.urql.json' assert { type: 'json' };
// need to spend more time to figure out if this is helpful until we have full graphql subscriptions wired up
// import { refocusExchange } from '@urql/exchange-refocus';

let userId = '';
let id = '';

const wsClient = createWSClient({
  url: import.meta.env.FE_GQL_WSS_ENDPOINT,
  connectionParams: () => {
    return {
      credentials: 'include',
      headers: {
        // while not fully wired up right now, this header is how we can look up / enforce low-level permissions in Hasura
        'x-hasura-user-id': userId,
      },
    };
  },
});

async function getUserId(): Promise<string> {
  if (!import.meta.env.FE_KRATOS_ENDPOINT) return '';
  if (id) return id;
  try {
    // @todo: update to use env var (ex. so we can swap this out based on being on the next vs latest branch)
    const kratosEndpoint = import.meta.env.FE_KRATOS_ENDPOINT;
    const kratosResponse = await fetch(`${kratosEndpoint}/sessions/whoami`, {
      method: 'get',
      credentials: 'include',
    });
    const sessionData = await kratosResponse.json();
    if (sessionData?.identity?.id) {
      id = sessionData?.identity?.id;
    }
  } catch (err) {
    console.log('Err with api-services whoami call!');
  }
  return id;
}

// Default gqlClient with all endpoints configured.
const gqlClient = (customHeaders?: HeadersInit) =>
  new Client({
    url: import.meta.env.FE_GQL_ENDPOINT,
    requestPolicy: 'cache-and-network',
    exchanges: [
      authExchange(async (utils) => {
        userId = await getUserId();

        return {
          addAuthToOperation(operation) {
            if (!userId) return operation;
            return utils.appendHeaders(operation, {
              // 'x-hasura-role': 'admin',
              'x-hasura-user-id': userId,
            });
          },
        } as AuthConfig;
      }),
      // @todo: need to re-enable and work on getting this to work with new Apollo GQL resolvers in Grizzly codebase
      // cacheExchange({
      //   // schema: schema,
      //   keys: new Proxy(
      //     {
      //       // UserRole: () => null,
      //       GetLoginInfo: () => null,
      //     },
      //     {
      //       get(_target) {
      //         // @todo: spend more time with this urql caching functionality to see if we can get this more fully typed
      //         // eslint-disable-next-line @typescript-eslint/no-explicit-any
      //         // return (data: any) => data[`${prop.split('_')[1]}_id`];
      //         return (data: any) => data['id'];
      //       },
      //     }
      //   ),
      //   resolvers: {
      //     // Google's API for manipulating this data programmatically isn't great: https://developers.google.com/people/image-sizing
      //     User: {
      //       picture: (user: User) => transformProfilePicture(user?.picture), // bump up the user profile image resolution
      //     },
      //   },
      // }),
      // refocusExchange(),
      fetchExchange,
      subscriptionExchange({
        forwardSubscription(operation) {
          return {
            subscribe: (sink) => {
              const dispose = wsClient.subscribe(
                operation as SubscribePayload,
                sink
              );
              return {
                unsubscribe: dispose,
              };
            },
          };
        },
      }),
    ],
    fetchOptions: () => {
      const headers: HeadersInit = {};
      if (userId) {
        headers['x-hasura-user-id'] = userId;
      }

      if (import.meta.env?.APP_LOADER_API_KEY) {
        headers['x-app-loader-api-key'] = import.meta.env.APP_LOADER_API_KEY;
      }

      return {
        credentials: 'include',
        headers: {
          ...headers,
          ...customHeaders,
        },
      };
    },
  });

// Export all generated types
export * from './generated/graphql.urql';

export const urqlGql = getSdk(gqlClient());

const getUrqlGql = (customHeaders?: HeadersInit) =>
  getSdk(gqlClient(customHeaders));

export const proStudioUrqlGql = getUrqlGql({
  'x-hasura-admin-secret': import.meta.env.FE_GQL_ADMIN_SECRET,
  UserID: 'UserID',
  UserEmail: 'UserEmail',
  UserFirstName: 'UserFirstName',
  UserLastName: 'UserLastName',
});

export { CombinedError };
