import * as React from 'react';
import { createComponent } from '@lit/react';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';
import { EmptyStateWC } from './empty-state.wc';

export interface EmptyStateCSSVars extends React.CSSProperties {}

export type EmptyStateProps = GenerateComponentTypeWithChildren<EmptyStateWC>;

export type EmptyStatePropsWithRef = GeneratePropsWithRef<EmptyStateProps>;

export const EmptyState = createComponent({
  tagName: EmptyStateWC.tagname,
  elementClass: EmptyStateWC,
  react: React,
}) as EmptyStatePropsWithRef;
