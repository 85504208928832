/* eslint-disable @typescript-eslint/no-explicit-any */
import { html, nothing } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { property, state } from 'lit/decorators.js';
import {
  getCoreRowModel,
  getPaginationRowModel,
  Table,
  ColumnDef,
  Row,
  TableState,
  getFilteredRowModel,
  TableOptions,
} from '@tanstack/table-core';
import { compact } from 'lodash';
import {
  i18n,
  allLocales,
  TranslationData,
  LanguageScopes,
} from '@pypestream/translations';

import { customElement, BaseElement, PSCustomEvent } from '../../base-element';
import { watch } from '../../base-element/directives/watch.directive';
import '../../table';

import { flexRenderer } from './flex-renderer';
import { UseTableCore } from './use-table-core';
import { Translate } from '../../base-element/mixins/translation-mixin';

export type TableData = {
  name: string;
  email: string;
  roles: string;
  team: string;
  created: string;
};

const designSystemTranslations = allLocales.filter(
  (locale) => locale.namespace === LanguageScopes.designSystem
) as unknown as TranslationData[];

@customElement('ps-data-table')
export class DataTableWC<T = TableData> extends Translate(BaseElement) {
  @property({ type: Array }) columns: ColumnDef<T>[];

  @property({ type: Array }) data: T[];

  @property({ type: Boolean, reflect: true, attribute: 'enable-row-selection' })
  enableRowSelection = false;

  @property({ type: Boolean, reflect: true }) actionable = true;

  @property({
    type: Boolean,
    reflect: true,
    attribute: 'enable-multi-row-selection',
  })
  enableMultiRowSelection = false;

  @property({
    type: Boolean,
    reflect: true,
    attribute: 'enable-row-selection-ui',
  })
  enableRowSelectionUi = false;

  @property({ reflect: true }) size:
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge' = 'large';

  // exposing the Tanstack table instance allows access to internal methods (ex. reset row selection)
  table: Table<T>;

  @state() private tableState: TableState;

  @watch('tableState', {
    waitUntilFirstUpdate: true,
  })
  async handleTableStateChange() {
    await this.updateComplete;

    if (this.enableRowSelection) {
      const rowsSelected = this.table.getSelectedRowModel().rows;
      this.emit('row-selection-change', {
        detail: {
          selected: rowsSelected,
        },
      });
    }
  }

  @property({ type: Array }) filterColumns: string[] = [];

  @property({ type: Array }) translations: TranslationData[] | undefined =
    designSystemTranslations;

  @watch('translations')
  onTranslationsChanged() {
    if (this.translations?.length) {
      // @ts-expect-error use method from translation mixin to add external translations to i18next
      this.loadTranslations(this.translations);
    }
  }

  @watch('lang')
  async onLangChanged() {
    // @ts-expect-error use method from translation mixin to add external translations to i18next
    await this.reloadLanguage();
  }

  getTableOptions(): TableOptions<T> {
    let columnsDef: ColumnDef<T>[] = this.columns;

    if (this.enableRowSelection && this.enableRowSelectionUi) {
      columnsDef = [
        {
          id: 'select',
          header: ({ table }) => html`
            <ps-checkbox
              value="select-all"
              ?checked=${table.getIsAllRowsSelected()}
              ?indeterminate=${table.getIsSomeRowsSelected()}
              @click=${table.getToggleAllRowsSelectedHandler()}
            >
            </ps-checkbox>
          `,
          cell: ({ row }) => html`
            <ps-checkbox
              ?disabled=${!row?.getCanSelect()}
              ?checked=${row.getIsSelected()}
              ?indeterminate=${row.getIsSomeSelected()}
              @click=${row.getToggleSelectedHandler()}
            >
            </ps-checkbox>
          `,
        },
        ...columnsDef,
      ];
    }

    let options: TableOptions<T> = {
      state: {
        ...this.table?.getState(),
        ...this.tableState,
      },
      data: this.data,
      columns: columnsDef,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      enableRowSelection: this.enableRowSelection,
      enableMultiRowSelection: this.enableMultiRowSelection,
      onStateChange: (updater) => {
        this.tableState =
          typeof updater === 'function'
            ? updater(this.table.getState())
            : updater;
      },
      initialState: {
        pagination: {
          pageIndex: 0,
          pageSize: 1000,
        },
      },
    };

    if (this.filterColumns.length) {
      options = {
        ...options,
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: (row: Row<T>, columnId, filterValue: string) => {
          if (!filterValue) return true;
          const filterValueParts = compact(filterValue.split(' '));
          return !!this.filterColumns?.some((col) =>
            filterValueParts.every((part) =>
              row
                .getValue(col)
                ?.toString()
                .toLowerCase()
                .includes(part.toLowerCase())
            )
          );
        },
        enableGlobalFilter: true,
      };
    }

    return options;
  }

  render() {
    if (!this.table) {
      this.table = UseTableCore<T>({
        ...this.getTableOptions(),
      });
      if (this.filterColumns.length) {
        this.table.setGlobalFilter('');
      }
    } else {
      this.table.setOptions((prev) => ({
        ...prev,
        ...this.getTableOptions(),
      }));
    }
    return html`
      <ps-table size=${this.size}>
        <ps-table-head>
          ${this.table
            ?.getHeaderGroups()
            ?.map(
              (headerGroup) => html`
                <ps-table-row key=${headerGroup.id}>
                  ${headerGroup.headers.map(
                    (header) => html`
                      <ps-table-cell
                        variant="th"
                        key=${header.id}
                        align=${(header.column.columnDef.meta as any)?.align}
                      >
                        ${header.isPlaceholder
                          ? null
                          : flexRenderer(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </ps-table-cell>
                    `
                  )}
                </ps-table-row>
              `
            )}
        </ps-table-head>
        <ps-table-body>
          ${this.table?.getRowModel()?.rows.map((row) => {
            const rowId = (row.original as { id: string })?.id || '';
            return html`
              <ps-table-row
                test-id=${ifDefined(rowId ? `row-${rowId}` : '')}
                data-name=${(row.original as { name: string }).name}
                key=${row.id}
                class=${row.getIsSelected() ? 'is-selected' : null}
                selected=${ifDefined(row.getIsSelected() ? true : null)}
                ?actionable=${this.actionable}
                @click=${() => {
                  row.toggleSelected();
                }}
                @keydown=${(e: KeyboardEvent) => {
                  if (e.key === 'Enter' || e.code === 'Space') {
                    e.preventDefault();
                    row.toggleSelected();
                  }
                }}
                tabindex="0"
                role="button"
              >
                ${row
                  .getVisibleCells()
                  .map(
                    (cell) => html`
                      <ps-table-cell key=${cell.id}>
                        ${flexRenderer(
                          cell.column.columnDef?.cell,
                          cell.getContext()
                        )}
                      </ps-table-cell>
                    `
                  )}
              </ps-table-row>
            `;
          })}
        </ps-table-body>
      </ps-table>
      ${!this.table?.getRowModel()?.rows?.length &&
      this.table.getState().globalFilter
        ? html`
            <ps-spacer size="2xlarge"></ps-spacer>
            <div style="display: flex; justify-content: center">
              ${i18n.t('designSystem:dataTable.noSearchResultsFound', {
                defaultValue: 'No search results found.',
              })}
            </div>
            <ps-spacer size="xlarge"></ps-spacer>
          `
        : nothing}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-data-table': DataTableWC;
  }
  enum PSElementTagNameMap {
    'ps-data-table' = 'ps-data-table',
  }
}

export type DataTableRowSelection<T> = PSCustomEvent<
  DataTableWC<T>,
  { selected: Row<T>[] }
>;
