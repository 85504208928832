import {
  Button,
  Modal,
  ModalIcon,
  Spacer,
  Stack,
  TextBody,
  TextTitle,
} from '@pypestream/design-system';
import { FC } from 'react';

interface DeleteUserConfirmationModalProps {
  open: boolean;
  isDeletingUser: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteUserConfirmationModal: FC<
  DeleteUserConfirmationModalProps
> = ({ open, isDeletingUser, onClose, onDelete }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalIcon name="error" slot="header" />
      <TextTitle size="small">Delete user from the Org</TextTitle>
      <Spacer size="small" />
      <TextBody variant="secondary">
        Are you sure to delete current user?
      </TextBody>
      <Stack slot="footer" justifyContent="end">
        <Button
          variant="secondary"
          size="large"
          onClick={() => {
            onClose();
          }}
          disabled={isDeletingUser}
        >
          Cancel
        </Button>
        <Button
          variant="warning"
          size="large"
          onClick={() => {
            onDelete();
          }}
          disabled={isDeletingUser}
        >
          {isDeletingUser ? 'Deleting...' : 'Delete User'}
        </Button>
      </Stack>
    </Modal>
  );
};
