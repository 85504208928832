import { datadogLogs } from '@datadog/browser-logs';
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import { Session } from '@ory/client/api';
import { signal } from '@preact/signals-react';
import { ConsentStatusEnum } from '@pypestream/api-services';
import { Background, Hero, Page, PageBody } from '@pypestream/design-system';
import * as Sentry from '@sentry/react';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect } from 'react';

import { removeTrailingSlash } from '@pypestream/utils';
import { SidebarWrapper } from './components';
import { kratosApi } from './kratos.api';
import {
  sendGlobalAppEvent,
  sendUserEvent,
  useUserCtxSelector,
} from './xstate/app.xstate';

const session = signal<Session | null>(null);

kratosApi
  .toSession()
  .then(({ data }) => {
    session.value = data;

    sendGlobalAppEvent({
      type: 'user.signIn',
      user: {
        // I'm trying to avoid passing in this info, only to have it immediately get overwritten
        // by our getCurrentUser GQL query
        //
        // email: data.identity.traits.email,
        // firstName: data.identity.traits.firstName,
        // lastName: data.identity.traits.lastName,
        // profilePhoto: data.identity.traits.picture,
        kratosId: data.identity.id,
      },
    });
  })
  .catch((error) => {
    console.error('Session error:', JSON.stringify(error));
    sendUserEvent('user.signOut');
  });

export const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}): JSX.Element => {
  const posthog = usePostHog();

  const { pathname } = window.location;

  const pathAsArray = removeTrailingSlash(pathname).split('/').filter(Boolean);
  const isHomePage = pathname === '/' || pathAsArray.length === 2;

  const { optionalConsentStatus, userId, userEmail, userName } =
    useUserCtxSelector((ctx) => ({
      optionalConsentStatus: ctx.user?.optionalConsentStatus,
      userId: ctx.user?.id,
      userEmail: ctx.user?.email,
      userName: `${ctx.user?.firstName} ${ctx.user?.lastName}`,
    }));

  useEffect(() => {
    if (!optionalConsentStatus) return;

    let datadogTrackingConsent: RumInitConfiguration['trackingConsent'] =
      'not-granted';

    if (optionalConsentStatus === ConsentStatusEnum.Accepted) {
      datadogTrackingConsent = 'granted';
      posthog.opt_in_capturing();
      datadogRum.startSessionReplayRecording();
      // not sure if we need this or if we can remove
      posthog.startSessionRecording();
      posthog.identify(userId, { email: userEmail, name: userName });
      console.log('start capturing');
    } else {
      console.log('opting out of posthog');
      posthog.stopSessionRecording();
      datadogRum.stopSessionReplayRecording();
      posthog.opt_out_capturing();
      Sentry.close().then(function () {
        console.log('opting out of Sentry');
      });
    }

    // https://docs.datadoghq.com/logs/log_collection/javascript/#user-tracking-consent
    datadogLogs.setTrackingConsent(datadogTrackingConsent);
    datadogRum.setTrackingConsent(datadogTrackingConsent);
  }, [optionalConsentStatus, posthog, userEmail, userId, userName]);

  // TODO remove if not needed (should not show white page without it)
  // if (!session.value && isHomePage) {
  //   return (
  //     <Page background="alt">
  //       <SidebarWrapper />
  //       <Hero variant={'minimal'}>
  //         <div
  //           style={{
  //             minHeight: '204px',
  //           }}
  //         ></div>
  //       </Hero>
  //       <Background />
  //       <PageBody background="none" />
  //     </Page>
  //   );
  // }

  // todo: do we need this?
  // const splittedPath = window.location.href.split('?');
  // window.history.pushState('authenticated', '', splittedPath[0]);
  return <>{children}</>;
};
