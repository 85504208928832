import { html, nothing, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement, SlotObserver } from '../base-element';
import './text';
import { Translate } from '../base-element/mixins/translation-mixin';
import textStyles from './text.scss?inline';

const textTitleOptions = {
  xlarge: {
    size: '2xlarge',
    fontWeight: 'light',
  },
  large: {
    size: 'xlarge',
    fontWeight: 'light',
  },
  medium: {
    size: 'large',
    fontWeight: 'medium',
  },
  small: {
    size: 'medium',
    fontWeight: 'medium',
  },
  xsmall: {
    size: 'small',
    fontWeight: 'normal',
  },
};

@customElement('ps-text-title')
export class TextTitleWC extends Translate(
  SlotObserver(BaseElement, ['[slot]', '[slot="prefix"]', '[slot="suffix"]'])
) {
  static styles = unsafeCSS(textStyles);

  /** Title size. */
  @property() size: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' =
    'medium';

  /** Text-align */
  @property() textAlign: 'start' | 'center' | 'end' | 'justify' = 'start';

  renderSlots() {
    if (
      this.hasSlottedContent('[slot="prefix"]') ||
      this.hasSlottedContent('[slot="suffix"]')
    ) {
      return html`
        <span class="c-text-title__wrapper">
          ${this.hasSlottedContent('[slot="prefix"]')
            ? html`<slot name="prefix" part="prefix"></slot>`
            : nothing}
          <slot></slot>
          ${this.hasSlottedContent('[slot="suffix"]')
            ? html`<slot name="suffix" part="suffix"></slot>`
            : nothing}
        </span>
      `;
    }
    return html`<slot></slot>`;
  }

  render() {
    const { size, fontWeight } = textTitleOptions[this?.size ?? 'medium'];

    return html`
      <ps-text
        size="${size}"
        font-weight="${fontWeight}"
        textAlign="${this.textAlign}"
        data-cy="text-title"
      >
        ${this.renderSlots()}
      </ps-text>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-text-title': TextTitleWC;
  }
  enum PSElementTagNameMap {
    'ps-text-title' = 'ps-text-title',
  }
}
