import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import tableStyles from './table.scss?inline';

@customElement('ps-table')
export class TableWC extends BaseElement {
  static styles = unsafeCSS(tableStyles);

  @property({ reflect: true }) size:
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge' = 'xlarge';

  /** Whether to show table borders between table cells  */
  @property({ reflect: true, type: Boolean }) bordered = false;

  render() {
    return html`
      <ps-scrollbar force-visible="true" disable-auto-hide="true">
        <div class="c-table" part="table">
          <slot></slot>
        </div>
      </ps-scrollbar>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-table': TableWC;
  }
  enum PSElementTagNameMap {
    'ps-table' = 'ps-table',
  }
}
