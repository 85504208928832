import {
  Button,
  Grid,
  GridCell,
  Icon,
  PageBody,
  PageSection,
  Select,
  SelectOption,
  Snackbar,
  Spacer,
  TextTitle,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';
import { FC, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../../components';
import { DeleteUserConfirmationModal } from '../../../components/modals/delete-user-confirmation-modal/delete-user-confirmation-modal';
import {
  sendManagerEvent,
  useGlobalAppCtxSelector,
  useManagerCtxSelector,
  useManagerStateMatches,
  useManagerStateMatchesOneOf,
  useUserCtxSelector,
} from '../../../xstate/app.xstate';
import {
  NEW_USER_TEMP_ID,
  ProductNames,
  UserDetailsType,
} from '../../../xstate/user-details-xstate-helper';
import { IPage } from '../../types';
import PermissionsTable from './permissions-table';
import {
  UserProfileDetails,
  UserProfileDetailsFormValues,
  useUserProfileDetailsForm,
} from './user-profile-details';

function hasContactCenterRole(userDetails: UserDetailsType) {
  return userDetails.orgs.some((org) => {
    const hasDirectRole = org.assignedProjects?.some((project) =>
      project.project.project_product_settings.some(
        (projectProduct) =>
          projectProduct.product.name === ProductNames.AGENT_ASSIST
      )
    );
    const hasTeamRole = org.assignedTeams?.some((team) =>
      team.team.team_accounts.some((teamAccount) =>
        teamAccount.team_account_projects.some((project) =>
          project.project.project_product_settings.some(
            (projectProduct) =>
              projectProduct.product.name === ProductNames.AGENT_ASSIST
          )
        )
      )
    );

    return hasDirectRole || hasTeamRole;
  });
}
type IUserDetailsProps = IPage & {
  isInviteUser?: boolean;
};

export const UserDetails: FC<IUserDetailsProps> = ({
  isInviteUser = false,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const { org_id: orgIdQueryParam = '', user_id: userIdQueryParam = '' } =
    params;

  const { allUserDetails, selectedUser } = useManagerCtxSelector((ctx) => ({
    allUserDetails: ctx.userDetails,
    selectedUser: ctx.selectedUser,
  }));

  const featureFlags = useGlobalAppCtxSelector((ctx) => ctx.featureFlags);

  const userDetails = isInviteUser
    ? allUserDetails[NEW_USER_TEMP_ID]
    : allUserDetails[userIdQueryParam] && allUserDetails[userIdQueryParam];
  const { state: userDetailsState, userDetailUpdates } = (userDetails &&
    userDetails) || { state: null, userDetailUpdates: null };

  const { loggedInUserId } = useUserCtxSelector((ctx) => ({
    loggedInUserId: ctx.user?.id,
  }));

  const loadingUserDetails = Boolean(
    useManagerStateMatchesOneOf([
      'orgRelated.ready.userDetails.loading',
      'orgRelated.ready.userDetails.addNewUser.loadingPrerequisites',
      'orgRelated.ready.userDetails.deleteUser.deleting',
    ])
  );

  const showDeleteUserConfirmationModal = useManagerStateMatches(
    'orgRelated.ready.userDetails.deleteUser.confirmUserDeletion'
  );

  const hasInvitedUserState = useManagerStateMatches(
    'orgRelated.ready.userDetails.addNewUser.invited'
  );

  const isDeletingUser = useManagerStateMatches(
    'orgRelated.ready.userDetails.deleteUser.deleting'
  );

  const hasDeletedUserState = useManagerStateMatches(
    'orgRelated.ready.userDetails.deleteUser.deleted'
  );

  useEffect(() => {
    // TODO: Find a way to navigate user from xstate directly instead of waiting on one full re-render
    // Once user is invited, navigate to user details page
    if (hasDeletedUserState) {
      navigate(`/organization/${orgIdQueryParam}/users`);
    }
  }, [hasDeletedUserState, navigate, orgIdQueryParam]);

  useEffect(() => {
    // TODO: Find a way to navigate user from xstate directly instead of waiting on one full re-render
    // Once user is invited, navigate to user details page
    if (hasInvitedUserState && selectedUser) {
      navigate(`/organization/${orgIdQueryParam}/users/${selectedUser}`);
    }
  }, [hasInvitedUserState, navigate, orgIdQueryParam, selectedUser]);

  const hasErrors = useManagerStateMatches(
    'orgRelated.ready.userDetails.error'
  );

  const savingUpdates = Boolean(
    useManagerStateMatchesOneOf([
      'orgRelated.ready.userDetails.saveUpdates',
      'orgRelated.ready.userDetails.addNewUser.invitingUser',
    ])
  );

  const formValues = useMemo<UserProfileDetailsFormValues>(
    () => ({
      firstName: userDetails?.state?.firstName || '',
      lastName: userDetails?.state?.lastName || '',
      email: userDetails?.state?.userName || '',
    }),
    [
      userDetails?.state?.firstName,
      userDetails?.state?.lastName,
      userDetails?.state?.userName,
    ]
  );

  const {
    form,
    getValues: getUserProfileDetailsValues,
    trigger: triggerUserProfileDetailsForm,
    reset: resetUserDetailsForm,
  } = useUserProfileDetailsForm({
    formValues,
  });

  if (hasErrors) {
    return (
      <PageBody>
        <TextTitle size="small" i18nKey="manager/common:genericError">
          Something went wrong: Try again
        </TextTitle>
      </PageBody>
    );
  }

  if (!userDetailsState) {
    if (loadingUserDetails) {
      return (
        <PageBody background="none">
          <PageSection>
            <Loader relative />
          </PageSection>
        </PageBody>
      );
    }
    return null;
  }

  return (
    <PageBody background="none" width="large">
      <Button
        variant="ghost"
        onClick={() => navigate(`/organization/${orgIdQueryParam}/users`)}
      >
        <Icon slot="prefix" name="chevron-left" />
        All Users
      </Button>
      <TextTitle test-id="user-details-subtitle">
        {(isInviteUser && (
          <TranslationComponent i18nKey="manager/users:usersList.inviteUser">
            Invite User
          </TranslationComponent>
        )) ||
          `${userDetailsState.firstName} ${userDetailsState.lastName}`}
      </TextTitle>
      <Spacer size="2xlarge" />
      <>
        <PageSection>
          <UserProfileDetails form={form} isInviteUser={isInviteUser} />
        </PageSection>
        <PageSection>
          <TextTitle
            test-id="permissions-table"
            size="xsmall"
            i18nKey="manager/users:userDetails.permissionAndAccessTable.title"
          >
            Permissions & Access
          </TextTitle>
          <Spacer size="large" />
          <PermissionsTable
            orgs={userDetailsState.orgs}
            canManageAccess={userDetailsState.canManageAccess}
          />
          <Snackbar
            id="snackbar"
            open={
              userDetailUpdates.hasPendingUpdates ||
              // consider dirty form state only for user invite flow
              (form.formState.isDirty && isInviteUser)
            }
          >
            {isInviteUser
              ? 'When you are ready, invite the user'
              : 'When you are ready, save your changes'}
            <Button
              size="medium"
              variant="secondary"
              slot="action"
              onClick={() => {
                if (isInviteUser) {
                  resetUserDetailsForm();
                  sendManagerEvent({
                    type: 'manager.userDetails.discardUpdates',
                    userId: NEW_USER_TEMP_ID,
                  });
                } else {
                  sendManagerEvent({
                    type: 'manager.userDetails.discardUpdates',
                    userId: userIdQueryParam,
                  });
                }
              }}
              disabled={savingUpdates}
            >
              Cancel
            </Button>
            <Button
              size="medium"
              variant="primary"
              slot="action"
              onClick={async () => {
                if (isInviteUser) {
                  // Validate user details form first
                  const isUserDetailsValid =
                    await triggerUserProfileDetailsForm();
                  const { firstName, lastName, email } =
                    getUserProfileDetailsValues();
                  if (isUserDetailsValid) {
                    sendManagerEvent({
                      type: 'manager.userDetails.inviteUser',
                      orgId: orgIdQueryParam,
                      firstName,
                      lastName,
                      email: email,
                    });
                  }
                } else {
                  sendManagerEvent({
                    type: 'manager.userDetails.saveUpdates',
                    userId: userIdQueryParam,
                    orgId: orgIdQueryParam,
                  });
                }
              }}
              disabled={savingUpdates}
            >
              {(isInviteUser &&
                (savingUpdates ? 'Sending Invite...' : 'Send Invitation')) ||
                (savingUpdates ? 'Updating...' : 'Update')}
            </Button>
          </Snackbar>
        </PageSection>
        {userDetailsState.canManageAccess &&
          !isInviteUser &&
          loggedInUserId !== userIdQueryParam && (
            <Button
              variant="warning"
              disabled={loadingUserDetails}
              onClick={() =>
                sendManagerEvent({
                  type: 'manager.userDetails.deleteUser.openConfirmation',
                })
              }
            >
              Delete User
            </Button>
          )}
      </>
      <DeleteUserConfirmationModal
        open={showDeleteUserConfirmationModal || isDeletingUser}
        onClose={() =>
          sendManagerEvent({
            type: 'manager.userDetails.setStateToIdle',
          })
        }
        onDelete={() => {
          sendManagerEvent({
            type: 'manager.userDetails.deleteUser.confirmed',
            userId: userIdQueryParam,
            orgId: orgIdQueryParam,
          });
        }}
        isDeletingUser={isDeletingUser}
      />
      {featureFlags?.agentConversationLimit &&
        hasContactCenterRole(userDetailsState) && (
          <PageSection>
            <TextTitle
              size="xsmall"
              i18nKey="manager/users:userDetails.permissionAndAccessTable.agentConversationLimit"
            >
              Agent Conversation Limit
            </TextTitle>
            <Spacer size="large" />
            <Grid>
              <GridCell xsmall="12" medium="3">
                <Select
                  value={
                    userDetailsState.settings?.agentConversationLimit?.toString() ||
                    ''
                  }
                  variant="outlined"
                  onChange={(e) => {
                    sendManagerEvent({
                      type: 'manager.userDetails.updateUserSettings',
                      settings: {
                        agentConversationLimit: e.target.value
                          ? Number(e.target.value)
                          : null,
                      },
                    });
                  }}
                >
                  <>
                    {new Array(10).fill(null).map((val, index) => (
                      <SelectOption value={(index + 1).toString()} key={index}>
                        {index + 1}
                      </SelectOption>
                    ))}
                  </>
                  <SelectOption value="">No limit</SelectOption>
                </Select>
              </GridCell>
            </Grid>
          </PageSection>
        )}
    </PageBody>
  );
};
