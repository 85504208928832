import { isProduction } from '@pypestream/utils';
import i18n, { ReadCallback } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { AvailableLanguages, LanguageScopes } from './types';

const getScopeNameSpaces = (scope: LanguageScopes) => {
  switch (scope) {
    case 'pro-studio':
      return [
        `${scope}/common`,
        `${scope}/homepage`,
        `${scope}/versions`,
        `${scope}/channels`,
        `${scope}/actions`,
        `${scope}/navigation`,
        `${scope}/files`,
      ];
    case 'manager':
      return [
        `${scope}/common`,
        `${scope}/projects`,
        `${scope}/preferences`,
        `${scope}/users`,
        `${scope}/teams`,
        `${scope}/logs`,
        `${scope}/super-admin`,
        `${scope}/organizations`,
      ];

    default:
      return `${scope}/common`;
  }
};
const fallbackLanguage = 'en';

const initializeI18n = (scope: LanguageScopes) => {
  i18n
    .use({
      type: 'backend',
      read: function (
        language: string,
        namespace: string,
        callback: ReadCallback
      ) {
        const fileName = namespace.split('/')[1];
        import(`../locales/${scope}/${language}/${fileName}.json`)
          .then((obj) => {
            callback(null, obj);
          })
          .catch((err) => {
            // @todo - Need to log these errors with datadog
            console.error(err.message);
            console.warn(`Language fallback to - ${i18n.language}`);
          });
      },
    })
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
      {
        debug: !isProduction,
        fallbackLng: fallbackLanguage,

        // namespaces:
        // https://youtu.be/SA_9i4TtxLQ?t=314
        // https://www.i18next.com/principles/namespaces
        ns: getScopeNameSpaces(scope),
        defaultNS: `${scope}/common`,

        supportedLngs: AvailableLanguages.map((lang) => lang.code),
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      },
      () => {
        document.documentElement?.setAttribute('lang', i18n.language);
        document.documentElement?.setAttribute('dir', i18n.dir());
        // This will always gets call whenever language gets changed.
        i18n.on('languageChanged', function (lng) {
          document.documentElement?.setAttribute('lang', lng);
          // document.documentElement?.setAttribute('lang', i18n.language);
          document.documentElement?.setAttribute('dir', i18n.dir());
        });
      }
    );
};

const init = (scope: LanguageScopes) =>
  new Promise((resolve, reject) => {
    if (i18n.isInitialized) {
      resolve(true);
    } else {
      initializeI18n(scope);
    }
    i18n.on('initialized', () => {
      resolve(true);
    });
    i18n.on('failedLoading', (lng: string, ns: string, msg: string) => {
      reject(`Failed loading lng - ${lng}, ns - ${ns}, msg - ${msg}`);
    });
  });

export { i18n, init, LanguageScopes, fallbackLanguage };
