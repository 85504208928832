import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { UserDetails } from '../pages';
import { IPage } from '../pages/types';
import { WithRouteData } from '../render-routes';
import {
  sendManagerEvent,
  useUserCtxSelector,
  useUserStateMatches,
} from '../xstate/app.xstate';
import {
  getRoleLevelForAccountRoles,
  NEW_USER_TEMP_ID,
} from '../xstate/user-details-xstate-helper';

export const UserPage = (props: IPage) => {
  const params = useParams();
  const { org_id: orgIdQueryParam = '', user_id: userIdQueryParam = '' } =
    params;
  const isInviteUser = userIdQueryParam === NEW_USER_TEMP_ID;
  const loadedLoggedInUserDetails = useUserStateMatches('loggedIn.loaded');
  const { assignedRoles: loggedInUserAssignedRoles } = useUserCtxSelector(
    (ctx) => ({
      loggedInUserId: ctx.user?.id,
      assignedRoles: ctx.user?.assignedRoles,
    })
  );

  const loggedInUserHighestRole = useMemo(
    () =>
      loggedInUserAssignedRoles
        ?.map((loggedInUserAssignedRole) => ({
          ...getRoleLevelForAccountRoles(loggedInUserAssignedRole.name || ''),
          ...loggedInUserAssignedRole,
        }))
        .sort((a, b) => a.roleLevel - b.roleLevel)
        .shift(),
    [loggedInUserAssignedRoles]
  );

  useEffect(() => {
    if (loadedLoggedInUserDetails) {
      if (isInviteUser) {
        sendManagerEvent({
          type: 'manager.addNewUser',
          orgId: orgIdQueryParam,
          loggedInUserHighestRole: loggedInUserHighestRole || {},
        });
      } else {
        sendManagerEvent({
          type: 'manager.loadUserDetails',
          orgId: orgIdQueryParam,
          userId: userIdQueryParam,
          loggedInUserHighestRole: loggedInUserHighestRole || {},
        });
      }
    }

    return () => {
      sendManagerEvent({
        type: 'manager.userDetails.discardUpdates',
        userId: userIdQueryParam,
      });
      sendManagerEvent({
        type: 'manager.clearSelectedUser',
      });
    };
  }, [
    userIdQueryParam,
    orgIdQueryParam,
    isInviteUser,
    loggedInUserHighestRole,
    loadedLoggedInUserDetails,
  ]);

  return <UserDetails {...props} isInviteUser={isInviteUser} />;
};

export default WithRouteData(UserPage);
