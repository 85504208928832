import { Params } from 'react-router-dom';
import { managerService, sendManagerEvent } from '../xstate/app.xstate';

export const syncXstateWithRouteParams = ({
  params,
}: {
  params: Params<string>;
  searchQuery?: URLSearchParams;
}) => {
  const {
    org_id: newOrgId,
    project_id: newProjectId,
    user_id: newUserId,
    team_id: newTeamId,
    environment_id: newEnvironmentId,
  } = params;

  const managerContext = managerService.getSnapshot().context;

  const { orgId, projectId, userId, teamId, environmentId } = {
    orgId: managerContext.selectedOrgId,
    projectId: managerContext.selectedProject,
    userId: managerContext.selectedUser,
    teamId: managerContext.selectedTeam,
    environmentId: managerContext.selectedEnvironment,
  };

  if (newOrgId !== orgId || (newOrgId === undefined && orgId === undefined)) {
    sendManagerEvent({
      type: 'manager.selectOrgId',
      orgId: newOrgId,
    });
  }

  if (newProjectId !== projectId) {
    sendManagerEvent({
      type: 'manager.selectProject',
      id: newProjectId,
    });
  }

  if (newUserId !== userId) {
    sendManagerEvent({
      type: 'manager.selectUser',
      id: newUserId,
    });
  }

  if (newTeamId !== teamId) {
    sendManagerEvent({
      type: 'manager.selectTeam',
      id: newTeamId,
    });
  }

  if (newEnvironmentId !== environmentId) {
    sendManagerEvent({
      type: 'manager.selectEnvironment',
      id: newEnvironmentId,
    });
  }
};
