import { ProjectFormFields } from '../../../../components';

export { ProjectSettingsGeneralTab } from './general';
export { ProjectSettingsAccessTab } from './access';
export { ProjectSettingsEnvironmentsTab } from './environments';
export { RenderProjectDetailsContent } from './render-page-content';

export const {
  ProjectNameField,
  ProjectDescriptionField,
  ProjectIconField,
  TimeZoneSelect,
  ProjectEnvironmentField,
  ProjectToolField,
} = new ProjectFormFields();
