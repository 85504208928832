import { ProductName } from '@pypestream/api-services';
import {
  Button,
  ButtonGroup,
  Dropdown,
  Icon,
  IconButton,
  Image,
  logos,
  Menu,
  MenuItem,
  Spacer,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tag,
  TextBody,
  TextCaption,
  TextOverline,
} from '@pypestream/design-system';
import { CSSProperties, FC, Fragment, memo, useCallback, useMemo } from 'react';
import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
  useManagerStateMatchesOneOf,
} from '../../../xstate/app.xstate';
import {
  ShortOrgType,
  UserAccountProject,
  UserDetailsType,
  UserTeams,
} from '../../../xstate/user-details-xstate-helper';

import './permissions-table.scss';

type PermissionsTableProps = {
  orgs: ShortOrgType[];
  canManageAccess: boolean;
};

const getProductIcon = (productName: string) => {
  switch (productName) {
    case ProductName.AgentAssist:
      return logos.agentAssist;
    case ProductName.Analytics:
      return logos.analytics;
    case ProductName.Organization:
      return logos.org;
    case ProductName.ProStudio:
      return logos.proStudio;
    default:
      return 'Unknown Product';
  }
};

const OrgCell: FC<{
  org: UserDetailsType['orgs'][number];
  addingTeamToOrg: boolean;
  canManageAccess: boolean;
  savingUserDetails: boolean;
}> = ({ org, addingTeamToOrg, canManageAccess, savingUserDetails }) => {
  const renderAccountRolesDropdown = useCallback(
    ({
      canSelectMoreRole,
      roleSelectorId,
      isAddRole,
    }: {
      canSelectMoreRole: boolean;
      roleSelectorId: string;
      isAddRole: boolean;
    }) => {
      return (
        <>
          {(canSelectMoreRole && (
            <div>
              {!isAddRole && <Icon name="chevron-down"></Icon>}
              <Dropdown trigger={roleSelectorId} placement="bottom-start">
                <Menu>
                  {org.availableAccountRoles?.map((availableAccountRole) => (
                    <MenuItem
                      key={availableAccountRole.role.id}
                      onClick={() => {
                        sendManagerEvent({
                          type: 'manager.userDetails.updateManagerRole',
                          role: availableAccountRole,
                          orgId: org.id || '',
                          operation: isAddRole ? 'add' : 'update',
                        });
                      }}
                      disabled={savingUserDetails}
                    >
                      {availableAccountRole.role.name}
                    </MenuItem>
                  ))}
                </Menu>
              </Dropdown>
            </div>
          )) ||
            null}
        </>
      );
    },
    [org.availableAccountRoles, org.id, savingUserDetails]
  );

  const hasDirectAccountRoleAssigned = org.accountRoles?.find(
    (role) => !role.teams
  );

  return (
    <TableCell
      rowspan={org.isCollapsed ? 1 : org.orgRowSpan}
      className="c-permissions-table--org-column"
    >
      <Stack alignItems="start" gutter="2xsmall" direction="column">
        <Stack alignItems="center" gutter="2xsmall">
          <IconButton
            name={org.isCollapsed ? 'chevron-down' : 'chevron-up'}
            size="xsmall"
            onClick={(e) => {
              e.stopPropagation();
              sendManagerEvent({
                type: 'manager.userDetails.toggleOrgCollapseState',
                orgId: org.id || '',
                collapse: !org.isCollapsed,
              });
            }}
          ></IconButton>
          {org.isPrimaryAccount && (
            <Icon name="home" size="xsmall" weight="medium" color="gray"></Icon>
          )}
          <TextBody variant="secondary">{org.name}</TextBody>
        </Stack>
        {org.parentAccount &&
          // Ensure that Pypestream is not displayed as the parent name when rendering other organizations as PrimaryAccount
          (org.parentAccount.parentId || !org.isPrimaryAccount) && (
            <TextBody
              variant="tertiary"
              i18nKey="manager/users:userDetails.permissionAndAccessTable.orgParent"
            >
              Parent: {org.parentAccount?.name}
            </TextBody>
          )}
      </Stack>
      {!org.isCollapsed && (
        <>
          <Spacer size="large"></Spacer>
          <Stack alignItems="center" gutter="xsmall">
            <Icon
              name="teams"
              size="xsmall"
              weight="medium"
              color="gray"
            ></Icon>
            <TextOverline>Teams</TextOverline>
          </Stack>
          <Spacer size="medium"></Spacer>
          {(org.assignedTeams && org.assignedTeams?.length > 0 && (
            <>
              <Stack direction="column">
                {org.assignedTeams?.map((team) => (
                  <Tag
                    minimal
                    removable={canManageAccess}
                    new-variant="outlined"
                    key={`${org.id}-${team.team.id}`}
                    onRemove={() => {
                      sendManagerEvent({
                        type: 'manager.userDetails.removeTeam',
                        team: team,
                        orgId: org.id || '',
                      });
                    }}
                    disabled={savingUserDetails}
                  >
                    <Icon name="teams"></Icon>
                    <TeamName team={team} />
                  </Tag>
                ))}
              </Stack>
              <Spacer size="small"></Spacer>
            </>
          )) ||
            null}
          {(canManageAccess &&
            org.availableTeams &&
            org.availableTeams?.length > 0 && (
              <Fragment key={`org-${org.id}-teams-section`}>
                <Button
                  variant="ghost"
                  size="small"
                  id={`org-${org.id}-team-selector`}
                  i18nKey="manager/teams:addTeam"
                  disabled={addingTeamToOrg || savingUserDetails}
                >
                  <Icon slot="prefix" name="add"></Icon>
                  Add Team
                </Button>
                <Dropdown
                  trigger={`org-${org.id}-team-selector`}
                  placement="bottom-start"
                  closeOnClickOutside
                >
                  <Menu>
                    {org.availableTeams?.map((team) => (
                      <MenuItem
                        key={team.id}
                        onClick={() => {
                          sendManagerEvent({
                            type: 'manager.userDetails.addTeam',
                            team,
                            orgId: org.id || '',
                          });
                        }}
                      >
                        {team.name}
                      </MenuItem>
                    ))}
                  </Menu>
                </Dropdown>
              </Fragment>
            )) ||
            (org.assignedTeams?.length == 0 && (
              <TextBody
                variant="secondary"
                size="small"
                i18nKey="manager/users:userDetails.permissionAndAccessTable.teamEmptyState"
              >
                No teams found.
              </TextBody>
            )) ||
            null}
          {addingTeamToOrg && (
            <TextBody variant="tertiary" size="small">
              Adding team to org
            </TextBody>
          )}
          <Spacer size="xlarge"></Spacer>
          <Stack alignItems="center" gutter="xsmall">
            <Image width="16" height="16" src={logos.manager}></Image>
            <TextOverline>Manager</TextOverline>
          </Stack>
          <Spacer></Spacer>
          <Stack direction="column">
            {org.accountRoles &&
              org.accountRoles?.map((role, index) => {
                const isTeamInheritedRole =
                  role.teams && role?.teams.length > 0;
                const roleSelectorId = `${org.id}-${role.role.id}-${isTeamInheritedRole ? 'inherited-role' : 'direct-role'}`;
                const canSelectMoreRole =
                  canManageAccess &&
                  !isTeamInheritedRole &&
                  org.isPrimaryAccount &&
                  (org.availableAccountRoles || [])?.length > 0;
                return (
                  <Tag
                    minimal
                    new-variant="outlined"
                    key={roleSelectorId}
                    removable={
                      org.isPrimaryAccount &&
                      !isTeamInheritedRole &&
                      canManageAccess
                    }
                    {...(canManageAccess &&
                      canSelectMoreRole && {
                        id: roleSelectorId,
                        interactive: !!canSelectMoreRole && !savingUserDetails,
                      })}
                    onRemove={() => {
                      sendManagerEvent({
                        type: 'manager.userDetails.updateManagerRole',
                        role: role,
                        orgId: org.id || '',
                        operation: 'remove',
                      });
                    }}
                    disabled={savingUserDetails}
                  >
                    <Icon name={isTeamInheritedRole ? 'teams' : 'users'}></Icon>
                    {role.role.name}
                    {isTeamInheritedRole && (
                      <RenderViaTeamDetails teams={role.teams || []} />
                    )}
                    {renderAccountRolesDropdown({
                      canSelectMoreRole,
                      roleSelectorId,
                      isAddRole: false,
                    })}
                  </Tag>
                );
              })}
            {((org.accountRoles || []).length === 0 ||
              !hasDirectAccountRoleAssigned) && (
              <ButtonGroup>
                <Tag
                  minimal
                  new-variant="outlined"
                  i18nKey="manager/users:userDetails.permissionAndAccessTable.roles.viewOnly"
                >
                  <Icon name="users"></Icon>
                  View Only
                </Tag>
                {org.isPrimaryAccount &&
                  !hasDirectAccountRoleAssigned &&
                  canManageAccess && (
                    <>
                      <IconButton
                        name="add"
                        id={`${org.id}-add-account-role`}
                        disabled={savingUserDetails}
                      />
                      {renderAccountRolesDropdown({
                        canSelectMoreRole:
                          org.isPrimaryAccount &&
                          (org.availableAccountRoles || [])?.length > 0,
                        roleSelectorId: `${org.id}-add-account-role`,
                        isAddRole: true,
                      })}
                    </>
                  )}
              </ButtonGroup>
            )}
          </Stack>
        </>
      )}
    </TableCell>
  );
};

const ProjectNameCell: FC<{
  project: UserAccountProject;
}> = ({ project }) => (
  <TableCell
    rowspan={project.project?.project_product_settings?.length || 1}
    style={{ '--c-table-cell-min-width': '120px' } as CSSProperties}
  >
    <TextBody variant="secondary" size="small">
      {project?.project.name}
    </TextBody>
  </TableCell>
);

const ProjectProductCell: FC<{
  projectProductSetting: UserAccountProject['project']['project_product_settings'][number];
}> = ({ projectProductSetting }) => (
  <TableCell nowrap>
    <Stack gutter="xsmall" nowrap>
      <Image
        width="16"
        height="16"
        src={getProductIcon(projectProductSetting.product.name)}
      />
      <TextCaption style={{ marginRight: 12 }}>
        {projectProductSetting.product?.displayName}
      </TextCaption>
    </Stack>
  </TableCell>
);

const ProjectProductRoleCell: FC<{
  project: UserAccountProject;
  productId: string;
  canManageAccess: boolean;
  savingUserDetails: boolean;
}> = ({ project, productId, canManageAccess, savingUserDetails }) => {
  const availableProductRoles = project.availableProjectRoles?.filter(
    (role) => role.productId === productId
  );
  const hasDirectRole = project._user_account_project_roles.find(
    (_user_account_project_role) =>
      !_user_account_project_role.teams &&
      _user_account_project_role.role.productId === productId
  );
  const rolePills = useMemo(
    () =>
      project._user_account_project_roles
        .map((_user_account_project_role) => {
          const isTeamInheritedRole =
            _user_account_project_role.teams &&
            _user_account_project_role.teams.length > 0;
          const roleSelectorId = `${project.project.id}-${productId}-${_user_account_project_role.role.id}-${isTeamInheritedRole ? 'inherited-role' : 'direct-role'}`;

          const canSelectMoreRole =
            !isTeamInheritedRole &&
            availableProductRoles?.length &&
            canManageAccess;

          return (
            (productId === _user_account_project_role.role.productId && (
              <Tag
                minimal
                removable={canManageAccess && !isTeamInheritedRole}
                key={roleSelectorId}
                {...(canManageAccess &&
                  canSelectMoreRole && {
                    id: roleSelectorId,
                    interactive: !!canSelectMoreRole && !savingUserDetails,
                  })}
                new-variant="outlined"
                onRemove={() => {
                  sendManagerEvent({
                    type: 'manager.userDetails.updateProjectProductRole',
                    projectId: project.project.id,
                    orgId: project.project.accountId,
                    role: {
                      id: _user_account_project_role.role.id,
                      name: _user_account_project_role.role.name || '',
                      productId:
                        _user_account_project_role.role.productId || '',
                      product: {
                        id: _user_account_project_role.role.productId || '',
                        name: (_user_account_project_role.role.product?.name ||
                          '') as ProductName,
                        displayName:
                          _user_account_project_role.role.product
                            ?.displayName || '',
                      },
                    },
                    operation: 'remove',
                  });
                }}
                disabled={savingUserDetails}
              >
                <Icon name={isTeamInheritedRole ? 'teams' : 'users'}></Icon>
                {_user_account_project_role.role.name}
                {isTeamInheritedRole && (
                  <RenderViaTeamDetails
                    teams={
                      (_user_account_project_role.teams &&
                        _user_account_project_role.teams) ||
                      []
                    }
                  />
                )}
                {(canSelectMoreRole && (
                  <div>
                    <Icon name="chevron-down"></Icon>
                    <Dropdown trigger={roleSelectorId} placement="bottom-start">
                      <Menu>
                        {availableProductRoles?.map((availableRole) => (
                          <MenuItem
                            key={availableRole.id}
                            onClick={() => {
                              sendManagerEvent({
                                type: 'manager.userDetails.updateProjectProductRole',
                                role: availableRole,
                                projectId: project.project.id,
                                orgId: project.project.accountId,
                                operation: 'update',
                              });
                            }}
                            disabled={savingUserDetails}
                          >
                            {availableRole.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Dropdown>
                  </div>
                )) ||
                  null}
              </Tag>
            )) ||
            null
          );
        })
        .filter((rolePill) => !!rolePill),
    [
      availableProductRoles,
      canManageAccess,
      productId,
      project._user_account_project_roles,
      project.project.accountId,
      project.project.id,
      savingUserDetails,
    ]
  );

  return (
    <TableCell>
      <Stack alignItems="center" gutter="xsmall">
        {rolePills}
        {/* Ability to add direct role only if no direct role is assigned to product*/}
        {canManageAccess && !hasDirectRole && (
          <div>
            <IconButton
              name="add"
              id={`${project.project.id}-${productId}`}
              disabled={savingUserDetails}
            />
            <Dropdown
              closeOnClickOutside
              trigger={`${project.project.id}-${productId}`}
              placement="bottom-start"
            >
              <Menu>
                {/* @todo - Fix this as it repeated code and will become hard to maintain */}
                {availableProductRoles?.map((availableRole) => (
                  <MenuItem
                    key={availableRole.id}
                    onClick={(e) => {
                      e.preventDefault();
                      sendManagerEvent({
                        type: 'manager.userDetails.updateProjectProductRole',
                        role: availableRole,
                        projectId: project.project.id,
                        orgId: project.project.accountId,
                        operation: 'add',
                      });
                    }}
                    disabled={savingUserDetails}
                  >
                    {availableRole.name}
                  </MenuItem>
                ))}
              </Menu>
            </Dropdown>
          </div>
        )}
      </Stack>
    </TableCell>
  );
};

const AddProjectCell: FC<{
  org: ShortOrgType;
  savingUserDetails: boolean;
}> = ({ org, savingUserDetails }) => (
  <TableCell colspan={3}>
    {(org.availableProjects && org.availableProjects?.length > 0 && (
      <>
        <Button
          variant="ghost"
          size="small"
          id={`org-${org.id}-project-seletor`}
          i18nKey="manager/users:userDetails.permissionAndAccessTable.addProject"
          disabled={savingUserDetails}
        >
          <Icon slot="prefix" name="add"></Icon>
          Add Project
        </Button>
        <Dropdown
          trigger={`org-${org.id}-project-seletor`}
          placement="bottom-start"
        >
          <Menu>
            {org.availableProjects?.map((project) => (
              <MenuItem
                key={project.id}
                onClick={() => {
                  sendManagerEvent({
                    type: 'manager.userDetails.addProject',
                    project: project,
                  });
                }}
                disabled={savingUserDetails}
              >
                {project.name}
              </MenuItem>
            ))}
          </Menu>
        </Dropdown>
      </>
    )) ||
      (org.assignedProjects?.length == 0 && (
        <TextBody
          variant="secondary"
          size="small"
          i18nKey="manager/users:userDetails.permissionAndAccessTable.projectsEmptyState"
        >
          No projects found. Create a project to get started.
        </TextBody>
      )) ||
      null}
  </TableCell>
);

const RenderViaTeamDetails = ({ teams }: { teams: UserTeams }) => {
  return (
    <span color="primary">
      {teams?.map((team, teamIndex) => (
        <Fragment key={team.team.id}>
          {/* Add opening bracket and via text for first team name */}
          {teamIndex === 0 && '(via - '}
          <TeamName team={team} />
          {/* Add closing bracket for last team name OR else add comma separator */}
          {(teamIndex + 1 == teams.length && `)`) || `, `}
        </Fragment>
      ))}
    </span>
  );
};

const TeamName = ({ team }: { team: UserTeams[number] }) => {
  const { adminBasePath } = useManagerCtxSelector((ctx) => ({
    adminBasePath: ctx.routes.admin,
  }));
  return (
    <a
      key={team.team.id}
      href={`${adminBasePath}/organization/${team.team.team_accounts[0]?.accountId}/teams-management/${team.team.id}`}
    >
      {team.team.name}
    </a>
  );
};

export const TableRowContent = ({
  org,
  addingTeamToOrg,
  canManageAccess,
  savingUserDetails,
}: {
  org: UserDetailsType['orgs'][number];
  addingTeamToOrg: boolean;
  canManageAccess: boolean;
  savingUserDetails: boolean;
}) => {
  return org.isCollapsed ? (
    <Fragment>
      <TableRow
        className="c-permissions-table--org-row c-permissions-table--org-row--collapsed"
        onClick={() => {
          sendManagerEvent({
            type: 'manager.userDetails.toggleOrgCollapseState',
            orgId: org.id || '',
            collapse: !org.isCollapsed,
          });
        }}
      >
        <OrgCell
          org={org}
          addingTeamToOrg={addingTeamToOrg}
          canManageAccess={canManageAccess}
          savingUserDetails={savingUserDetails}
        />
        <TableCell colspan={3}>
          {org.assignedProjects?.map((prj) => (
            <Tag
              className="tag-spaced"
              minimal
              variant="indicator"
              newVariant="filled"
              key={prj.id}
            >
              {prj.project.name}
            </Tag>
          ))}
          {!org.assignedProjects?.length && (
            <TextBody
              variant="secondary"
              size="small"
              i18nKey="manager/users:userDetails.permissionAndAccessTable.noProjectsAssigned"
            >
              No projects assigned
            </TextBody>
          )}
        </TableCell>
      </TableRow>
    </Fragment>
  ) : (
    <Fragment>
      <TableRow className="c-permissions-table--org-row">
        <OrgCell
          org={org}
          addingTeamToOrg={addingTeamToOrg}
          canManageAccess={canManageAccess}
          savingUserDetails={savingUserDetails}
        />
        {(org.assignedProjects && org.assignedProjects.length > 0 && (
          <>
            <ProjectNameCell project={org.assignedProjects[0]} />
            <ProjectProductCell
              projectProductSetting={
                org.assignedProjects[0].project.project_product_settings[0]
              }
            />
            <ProjectProductRoleCell
              project={org.assignedProjects[0]}
              productId={
                org.assignedProjects[0].project.project_product_settings[0]
                  .product.id
              }
              canManageAccess={canManageAccess}
              savingUserDetails={savingUserDetails}
            />
          </>
        )) ||
          (org.showAddProjectButton && (
            <AddProjectCell org={org} savingUserDetails={savingUserDetails} />
          )) || (
            // Else render empty cells to keep table alignment as expected
            <>
              <TableCell key="empty-cell-1" colspan={3}>
                <TextBody
                  variant="secondary"
                  size="small"
                  i18nKey="manager/users:userDetails.permissionAndAccessTable.projectsEmptyStateForViewUser"
                >
                  No projects assigned. Contact admin to get started.
                </TextBody>
              </TableCell>
            </>
          )}
      </TableRow>
      {org.assignedProjects?.map((project, projectIndex) => {
        if (projectIndex === 0) {
          return project.project?.project_product_settings
            ?.slice(1)
            ?.map((projectProductSetting) => {
              return (
                <TableRow
                  key={`${project.project.id}-${projectProductSetting.product?.id}`}
                >
                  <ProjectProductCell
                    projectProductSetting={projectProductSetting}
                  />
                  <ProjectProductRoleCell
                    project={project}
                    productId={projectProductSetting.product.id}
                    key={`${project.project.id}-${project._user_account_project_roles.length}`}
                    canManageAccess={canManageAccess}
                    savingUserDetails={savingUserDetails}
                  />
                </TableRow>
              );
            });
        }
        return (
          <Fragment key={project.project.id}>
            <TableRow>
              <ProjectNameCell project={project} />
              {project.project?.project_product_settings?.length > 0 ? (
                <>
                  <ProjectProductCell
                    projectProductSetting={
                      project.project?.project_product_settings[0]
                    }
                  />
                  <ProjectProductRoleCell
                    project={project}
                    productId={
                      project.project?.project_product_settings[0].product.id
                    }
                    key={`${project.project.id}-${project._user_account_project_roles.length}`}
                    canManageAccess={canManageAccess}
                    savingUserDetails={savingUserDetails}
                  />
                </>
              ) : (
                // Else render empty cells to keep table alignment as expected
                <>
                  <TableCell key="empty-cell-4"></TableCell>
                  <TableCell key="empty-cell-5"></TableCell>
                </>
              )}
            </TableRow>
            {project.project?.project_product_settings?.map(
              (projectProductSetting, projectProductSettingInd) => {
                if (projectProductSettingInd === 0) {
                  return null;
                }
                return (
                  <TableRow
                    key={`${project.project.id}-${projectProductSetting.product?.id}`}
                  >
                    <ProjectProductCell
                      projectProductSetting={projectProductSetting}
                    />
                    <ProjectProductRoleCell
                      project={project}
                      productId={projectProductSetting.product.id}
                      key={`${project.project.id}-${project._user_account_project_roles.length}`}
                      canManageAccess={canManageAccess}
                      savingUserDetails={savingUserDetails}
                    />
                  </TableRow>
                );
              }
            )}
          </Fragment>
        );
      })}
      {((org.assignedProjects || []).length > 0 &&
        org.showAddProjectButton &&
        (org.availableProjects || []).length > 0 && (
          <TableRow>
            <AddProjectCell org={org} savingUserDetails={savingUserDetails} />
          </TableRow>
        )) ||
        null}
    </Fragment>
  );
};

export const PermissionsTable: FC<PermissionsTableProps> = ({
  orgs,
  canManageAccess,
}) => {
  const addingTeamToOrg = useManagerStateMatches(
    'orgRelated.ready.userDetails.addTeamToOrg'
  );
  const savingUserDetails = !!useManagerStateMatchesOneOf([
    'orgRelated.ready.userDetails.autoSaveUserDetails',
    'orgRelated.ready.userDetails.saveUpdates',
  ]);

  return (
    <Table size="large" bordered className="c-permissions-table">
      <TableHead>
        <TableRow className="background-gray">
          <TableCell
            variant="th"
            i18nKey="manager/users:userDetails.permissionAndAccessTable.orgTitle"
          >
            <div className="table-expand-collapse-button">
              {orgs.some((org) => !org.isCollapsed) ? (
                <IconButton
                  name="chevrons-down-up"
                  size="xsmall"
                  onClick={() => {
                    sendManagerEvent({
                      type: 'manager.userDetails.toggleAllOrgCollapseState',
                      collapse: true,
                    });
                  }}
                />
              ) : (
                <IconButton
                  name="chevrons-up-down"
                  size="xsmall"
                  onClick={() => {
                    sendManagerEvent({
                      type: 'manager.userDetails.toggleAllOrgCollapseState',
                      collapse: false,
                    });
                  }}
                />
              )}
              <strong>Org</strong>
            </div>
          </TableCell>
          <TableCell
            variant="th"
            i18nKey="manager/users:userDetails.permissionAndAccessTable.projectTitle"
          >
            <strong>Project</strong>
          </TableCell>
          <TableCell
            variant="th"
            i18nKey="manager/users:userDetails.permissionAndAccessTable.toolTitle"
          >
            {orgs[0]?.isCollapsed ? 'Tool' : <strong>Tool</strong>}
          </TableCell>
          <TableCell
            variant="th"
            i18nKey="manager/users:userDetails.permissionAndAccessTable.roleTitle"
          >
            {orgs[0]?.isCollapsed ? 'Role' : <strong>Role</strong>}
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {orgs.map((org: ShortOrgType) => (
          <Fragment key={org.id}>
            <TableRowContent
              key={org.id}
              org={org}
              addingTeamToOrg={addingTeamToOrg}
              canManageAccess={canManageAccess}
              savingUserDetails={savingUserDetails}
            />
          </Fragment>
        ))}
        <TableRow>
          <TableCell colspan={5} />
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default memo(PermissionsTable);
