import { Icon, Spacer, Stack, TextCaption } from '@pypestream/design-system';
import { useController, useFormContext } from 'react-hook-form';
import { TranslationComponent, useTranslation } from '@pypestream/translations';

import { Product } from '../../../utils';
import { OutputProjectFormType } from '../..';
import { ProjectToolField } from '../create-project-flow';
import { ProjectFormButtons } from './project-form-buttons';
import { ProjectTitles } from './project-titles';

export type ProjectToolingPropsType = {
  nextStep: (formData?: Partial<OutputProjectFormType>) => void;
  prevStep: () => void;
  products: Product[];
};

export const ProjectTooling = ({
  nextStep,
  prevStep,
  products,
}: ProjectToolingPropsType) => {
  const form = useFormContext();
  const [t] = useTranslation();

  const { control, handleSubmit } = form;

  const { fieldState } = useController({
    name: 'productIds',
    control,
    rules: { validate: (value) => !!value?.length },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ProjectTitles
        step="project-tools"
        title={
          t('manager/projects:createProject.step3.title', {
            defaultValue: 'Select tooling to integrate',
          }) || ''
        }
      />
      <Spacer size="2xlarge" />
      <Stack justifyContent="center" gutter="large">
        {products.map(({ productId, logo, label }, index) => {
          const isRequired =
            index === 0 && form.getValues('productIds').length === 0;

          return (
            <ProjectToolField
              test-id={`${label?.replace(' ', '-').toLowerCase()}-tool`}
              key={productId}
              productId={productId}
              logo={logo}
              label={label}
              form={form}
              required={isRequired}
            />
          );
        })}
      </Stack>
      {fieldState.invalid && (
        <>
          <Spacer size="xlarge" />
          <TextCaption
            test-id="project-tools-required-msg"
            variant="danger"
            textAlign="center"
          >
            <Icon
              name="warning"
              size="xsmall"
              weight="regular"
              className="u-margin-right-xsmall"
            />
            <TranslationComponent i18nKey="manager/projects:createProject.step3.required">
              Please select one or more tools
            </TranslationComponent>
          </TextCaption>
        </>
      )}
      <Spacer size="xlarge" />
      <ProjectFormButtons
        step="project-tools"
        nextStep={handleSubmit(nextStep)}
        prevStep={prevStep}
      />
    </form>
  );
};
