import { html, unsafeCSS, PropertyValueMap, PropertyValues } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement, customElement } from '../base-element';
import styles from './nav.scss?inline';

@customElement('ps-nav')
export class NavWC extends BaseElement {
  static styles = unsafeCSS(styles);

  stickyListener: IntersectionObserver;

  stickyElem: HTMLDivElement;

  @property({ reflect: true, attribute: true, type: Boolean }) sticky: boolean;

  @state()
  protected isSticky: boolean;

  firstUpdated() {
    window.addEventListener('scroll', () => {
      if (!this.sticky) {
        this.stickyElem = this.renderRoot.querySelector(
          '.c-nav-sticky-elem'
        ) as HTMLDivElement;

        this.isSticky = window.pageYOffset > this.stickyElem.offsetTop;
      }
    });
  }

  async willUpdate(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ) {
    super.willUpdate(changedProperties as PropertyValues<this>);

    if (changedProperties.has('sticky')) {
      this.isSticky = this.sticky;
    }
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    if (this.stickyListener) {
      this.stickyListener.unobserve(this.stickyElem);
    }
  }

  render() {
    const classes = classMap({
      'c-nav': true,
      'c-nav--sticky': this.isSticky,
    });

    return html`
      <div class="c-nav-sticky-elem"></div>
      <div class=${classes}>
        <div class="c-nav__wrapper">
          <div class="c-nav__inner"><slot></slot></div>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-nav': NavWC;
  }
  enum PSElementTagNameMap {
    'ps-nav' = 'ps-nav',
  }
}
