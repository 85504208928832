import { AppletInstance, ProductName } from '@pypestream/api-services';
import {
  Account,
  AddUserToAccountMutation,
  CreateAccountMutation,
  CreateAppletInstanceMutation,
  GetAppletTemplatesQuery,
  GetChildAccountsQuery,
  GetTeamProjectsDetailsQuery,
  QueryMetadata,
  SetUserAccountProjectsRolesMutationVariables,
  UpdateAccountMutation,
  UpdateAppletInstanceMutation,
  UpdateUserMutation,
  urqlGql,
  UserStatus,
} from '@pypestream/api-services/urql';
import { changeLanguage } from '@pypestream/translations';
import {
  sort,
  transformProfilePicture,
  transformTimezonesData,
} from '@pypestream/utils';
import { assign as immerAssign } from '@xstate/immer';
import {
  compact,
  flatten,
  isEqual,
  orderBy,
  sortBy,
  uniq,
  uniqBy,
} from 'lodash';
import { TreeUtils } from 'simple-tree-utils';
import { assign, Machine, raise, Sender } from 'xstate';
import { getProducts, haveErrors, initialTools } from '../utils';
import { NotFoundError } from '../utils/errors';
import {
  globalAppService,
  sendManagerEvent,
  sendUserEvent,
} from './app.xstate';
import {
  GLOBAL_APP_SUB_MACHINE_IDS,
  sendUserMessage,
} from './app.xstate-utils';
import {
  ManagerContext,
  ManagerEvents,
  ManagerState,
  ManagerTypestates,
  Org,
  OrgWithChildren,
  Project,
  ProjectNew,
  Timezone,
} from './manager.xstate.types';
import {
  AccountRoles,
  AccountTeams,
  AgentAssistRoleNames,
  AnalyticsRoleNames,
  handleAddProjectAccess,
  handleOrgsDataProcessing,
  handleUpdateUserDetails,
  hasPendingUpdates,
  NEW_USER_TEMP_ID,
  OrgRowSpanCalculatorType,
  processAccountRoles,
  processTeamInheritedDetails,
  productsSortFunction,
  removeTeam,
  ShortOrgType,
  updateManagerRole,
  updateProjectProductRole,
  updateUserSettings,
  UserAccountProject,
  UserDetailsType,
  userDetailUpdateInitialState,
  UserDetailUpdates,
} from './user-details-xstate-helper';
import { UserInterpreter } from './user.xstate';
import { getXstateUtils } from './xstate.utils';

const { createInvokablePromise, createXstateHooks: createManagerXstateHooks } =
  getXstateUtils<
    ManagerContext,
    ManagerEvents,
    ManagerTypestates,
    ManagerState
  >();

export { createManagerXstateHooks };

export const treeUtils = new TreeUtils({
  idProp: 'id', // the key of a unique identifier for an object (source object)
  parentIdProp: 'parentId', // the key of a unique parent identifier (source object)
  childrenProp: 'children', // the key, where child nodes are stored (destination object tree)
});

// @todo: figure out why we can't easily (fully) cancel the subscription after running just once
let hasUpdateUserInfoSubRun = false;

export const managerMachine = Machine<
  ManagerContext,
  ManagerState,
  ManagerEvents
>({
  id: 'manager',
  predictableActionArguments: true,
  preserveActionOrder: true,
  invoke: {
    id: 'managerObserver',
    src: (ctx) => async (sendEvent: Sender<ManagerEvents>) => {
      // remove globalAppService subscription and featureFlags from manager context when managerProjectIdForContactCenter will be removed
      globalAppService.subscribe(async (state) => {
        const globalContext = state?.context;

        if (!globalContext) return;

        const { featureFlags } = globalContext;

        sendEvent({
          type: 'manager.setFeatureFlags',
          values: featureFlags,
        });
      });

      const userService = globalAppService.children.get(
        GLOBAL_APP_SUB_MACHINE_IDS.user
      ) as UserInterpreter;

      const userServiceSubscription = userService?.subscribe(async (state) => {
        const userContext = state?.context;

        const id = userContext?.user?.id;
        if (!id) return;

        if (hasUpdateUserInfoSubRun === false) {
          hasUpdateUserInfoSubRun = true;
          const defaultOrgId = userContext?.user?.defaultAccount?.defaultOrgId;
          const email = userContext?.user?.email;
          const status = userContext?.user?.status;
          const projectIdsAssignedThroughTeams = compact(
            userContext?.user?.assignedTeams
              ?.map(({ assignedProjects }) =>
                assignedProjects?.map((assignedProject) => assignedProject.id)
              )
              .flat()
          );

          sendEvent({
            type: 'manager.updateUserInfo',
            data: {
              ...ctx?.userInfo,
              id,
              // kratosId,
              defaultOrgId,
              email,
              status,
              isPypestreamEmployee:
                email?.endsWith('@pypestream.com') ||
                email?.endsWith('@pypestream.mailisk.net'),
              projectIds: projectIdsAssignedThroughTeams,
            },
          });
        }
      });

      if (hasUpdateUserInfoSubRun) {
        userServiceSubscription.unsubscribe();
      }
    },
  },
  context: {
    userInfo: {},
    selectedOrgId: undefined,
    projects: [],
    projectsNew: [],
    userProjectIds: [],
    errors: [],
    tools: initialTools,
    getProducts,
    routes: {
      home: '/',
      myAccount: '/my-account',
      users: '/users',
      teams: '/teams-management',
      roles: '/roles-management',
      projects: '/projects',
      orgs: '/child-orgs',
      logs: '/audit-logs',
      superAdmin: '/super-admin',
      wipTeams: '/teams',
      admin: import.meta.env.FE_ADMIN_URL,
    },
    appletTemplates: [],
    languages: [],
    localizationSettingsConfig: undefined,
    timezones: [],
    users: [],
    teams: [],
    logs: {
      count: 0,
      rows: [],
    },
    logsCache: {},
    childOrgs: {
      currentOrg: undefined,
      rows: [],
      count: 0,
    },
    userDetails: {},
  },
  type: 'parallel',
  on: {
    loggedIn: {
      actions: assign((ctx) => {
        console.log('logged in!');
        // ctx.selectedOrgId = orgId;
        return ctx;
      }),
    },
    'manager.selectProject': {
      actions: assign((ctx, { id }) => {
        ctx.selectedProject = id;
        return ctx;
      }),
      cond: (ctx, event) =>
        event.type === 'manager.selectProject' &&
        event.id !== ctx.selectedProject,
    },
    'manager.updateUserInfo': {
      target: '#checkingUserInfo',
      actions: assign((ctx, { data: { projectIds, ...userInfo } }) => {
        ctx.userInfo = userInfo;
        ctx.userProjectIds = projectIds;
        return ctx;
      }),
    },
    'manager.transformProjectsSettings': {
      target: '#projects.transforming',
    },

    'manager.createDbSnapshot': {
      target: '#dataBaseSnapshot.loading',
    },
    'manager.updateProject.name': {
      target: '#projectNameUpdating',
    },
    'manager.updateProject.description': {
      target: '#projectDescriptionUpdating',
    },
    'manager.updateProject.timeZoneId': {
      target: '#projectTimeZoneIdUpdating',
    },
    'manager.updateProject.projectIcon': {
      target: '#projectIconUpdating',
    },
    'manager.selectUser': {
      actions: assign((ctx, { id }) => {
        ctx.selectedUser = id;
        return ctx;
      }),
    },
    'manager.clearSelectedUser': {
      actions: assign((ctx) => {
        ctx.selectedUser = undefined;
        return ctx;
      }),
    },
    'manager.selectTeam': {
      actions: assign((ctx, { id }) => {
        ctx.selectedTeam = id;
        return ctx;
      }),
    },
    'manager.setFeatureFlags': {
      actions: assign((ctx, { values }) => {
        ctx.featureFlags = values;
        return ctx;
      }),
    },
    'manager.selectChildOrg': {
      actions: assign((ctx, { id }) => {
        ctx.selectedChildOrg = id;
        return ctx;
      }),
    },
    'manager.selectEnvironment': {
      actions: assign((ctx, { id }) => {
        ctx.selectedEnvironment = id;
        return ctx;
      }),
    },
  },
  states: {
    orgRelated: {
      type: 'compound',
      initial: 'idle',
      states: {
        idle: {
          id: 'idle',
        },
        checkingUserInfo: {
          id: 'checkingUserInfo',
          always: [
            {
              target: 'ready',
              cond: (context, event) => context.userInfo?.id !== undefined,
            },
            {
              target: 'idle',
            },
          ],
        },
        ready: {
          id: 'ready',
          type: 'parallel',
          states: {
            currentOrg: {
              id: 'currentOrg',
              initial: 'notSelected',
              on: {
                'manager.selectOrgId': {
                  actions: assign((ctx, { orgId }) => {
                    ctx.selectedOrgId = orgId || ctx.userInfo.defaultOrgId;
                    ctx.selectedProjectModal = undefined;
                    return ctx;
                  }),
                  target: '.selected',
                },
              },
              states: {
                notSelected: {},
                selected: {
                  invoke: createInvokablePromise({
                    src: async (ctx, event): Promise<void> => {
                      if (!ctx.selectedOrgId) return;

                      const { data, error } = await urqlGql.getAccountById({
                        id: ctx.selectedOrgId,
                      });

                      if (!data?.admin_?.accountById) {
                        throw new NotFoundError('Organization not found');
                      }

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                    },
                    onErrorAssignContext({ ctx, error }) {
                      if (error instanceof NotFoundError) {
                        ctx.errors = [error];
                      }
                    },
                  }),
                },
              },
            },
            orgs: {
              initial: 'idle',
              id: 'orgs',
              on: {
                'manager.selectOrgId': {
                  target: '#orgLoading',
                },
              },
              states: {
                idle: {
                  // entry: () => {
                  //   console.log('ready to load org info!');
                  // },
                  always: {
                    target: 'loading',
                    cond: (context, event) =>
                      context.userInfo?.id !== undefined,
                  },
                },
                loading: {
                  id: 'orgLoading',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | ManagerContext['orgs']> => {
                      const { data, error } = await urqlGql.getAccounts();

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      let orgs: Omit<Org, 'allChildAccounts'>[] = [];

                      const defaultAccount: Org | undefined =
                        data?.admin_?.currentUser?.defaultAccount;

                      if (defaultAccount) {
                        const { allChildAccounts, ...orgProps } =
                          defaultAccount;
                        orgs = [orgProps].concat(allChildAccounts || []);
                      }

                      return orgs;
                    },
                    onDoneTarget: 'loaded',
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.orgs = data;

                        const orgsWithChildren: OrgWithChildren[] =
                          treeUtils.list2Tree(data) as OrgWithChildren[];

                        orgsWithChildren.map((foo) => {
                          if ('id' in foo) {
                            foo.selected = foo?.id === ctx.selectedOrgId;
                          }

                          if ('children' in foo === undefined) {
                            foo.children = [];
                          }

                          return foo;
                        }) as OrgWithChildren[];

                        // console.log(orgsWithChildren);

                        const maxDepth = (root: OrgWithChildren) => {
                          // if root is undefined, depth is 0
                          if (!root) return 0;
                          // variable to store the maximum levels
                          let max = 0;
                          // helper function to traverse the tree
                          // recursively increment the levels by one
                          const dfs = (
                            node: OrgWithChildren,
                            levels: number
                          ) => {
                            // console.log(node.name, levels);

                            node.depth = levels;
                            // compare levels and max to pass the maximum levels
                            if (levels > max) max = levels;
                            // traverse all children of the current node
                            for (const child of node.children) {
                              // increment the levels by one
                              dfs(child, levels + 1);
                            }
                          };
                          // when root is not null, the tree has at least one level,
                          // so we pass down 1
                          dfs(root, 1);

                          // return the maximum levels
                          return max;
                        };

                        maxDepth(orgsWithChildren[0]);

                        ctx.orgTree = orgsWithChildren;
                      }
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                error: {},
                loaded: {
                  on: {
                    'manager.addOrg': {
                      target: 'adding',
                    },
                    'manager.updateOrg': {
                      target: 'updating',
                    },
                    'manager.deleteOrg': {
                      target: 'deleting',
                    },
                  },
                },
                adding: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      account: NonNullable<
                        CreateAccountMutation['admin_']
                      >['createAccount'];
                      callback: ((res: boolean) => void) | undefined;
                    }> => {
                      if (
                        event.type !== 'manager.addOrg' ||
                        !event.accountName ||
                        !ctx.selectedOrgId
                      )
                        return;

                      const {
                        accountName,
                        accountManagerId,
                        securitySettings,
                        accountIconId,
                      } = event;

                      const { data, error } = await urqlGql.createAccount({
                        parentAccountId: ctx.selectedOrgId,
                        accountName,
                        accountManagerId,
                        securitySettings,
                        accountIconId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return {
                        account: data?.admin_?.createAccount,
                        callback: event.callback,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (!data || !data.account) {
                        throw new Error(
                          'Returned data from createAccount is undefined'
                        );
                      }
                      ctx.orgs?.push(data.account);
                      if (data.callback) {
                        data.callback(true);
                      }
                    },
                    onDoneTarget: 'added',
                    onErrorTarget: 'loaded',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                added: {
                  after: {
                    100: 'loaded',
                  },
                },
                updating: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      updateAccount: NonNullable<
                        UpdateAccountMutation['admin_']
                      >['updateAccount'];
                      callback: ((res: boolean) => void) | undefined;
                    }> => {
                      if (
                        event.type !== 'manager.updateOrg' ||
                        !ctx.selectedOrgId
                      )
                        return;

                      const {
                        name,
                        accountManagerId,
                        securitySettings,
                        generalSettings,
                        resourceLimitSettings,
                        accountIconId,
                      } = event;
                      const { data, error } = await urqlGql.updateAccount({
                        id: ctx.selectedOrgId,
                        name,
                        accountManagerId,
                        securitySettings,
                        generalSettings,
                        resourceLimitSettings,
                        accountIconId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return {
                        updateAccount: data?.admin_?.updateAccount,
                        callback: event.callback,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined && !!data.updateAccount) {
                        const index = ctx.orgs?.findIndex(
                          (a) => a.id === data.updateAccount?.id
                        );
                        if (index === undefined) {
                          throw new Error(
                            'Returned applet from UpdateAppletInstance Mutation is not found'
                          );
                        }
                        if (index >= 0) {
                          ctx.orgs = ctx.orgs?.with(index, data.updateAccount);
                          if (data.callback) {
                            data.callback(true);
                          }
                        }
                        // update childOrgs context
                        if (
                          ctx.childOrgs.currentOrg &&
                          ctx.childOrgs.currentOrg?.id === data.updateAccount.id
                        ) {
                          ctx.childOrgs.currentOrg.name =
                            data.updateAccount.name;
                          ctx.childOrgs.currentOrg.pictureFile =
                            data.updateAccount.pictureFile;
                        }
                      }
                    },
                    onDoneTarget: 'updated',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating org's data: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `Organization ${data?.updateAccount?.name} updated successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                updated: {
                  after: {
                    100: 'loaded',
                  },
                },
                deleting: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<{
                      id: Account['id'];
                      callback: ((res: boolean) => void) | undefined;
                    } | void> => {
                      if (event.type !== 'manager.deleteOrg' || !event.id)
                        return;

                      if (!ctx.selectedOrgId) {
                        throw new Error(
                          'missing info needed to delete project! (projectId or selectedOrgId)'
                        );
                      }

                      if (ctx.userInfo.defaultOrgId === ctx.selectedOrgId) {
                        throw new Error('cannot delete default organization!');
                      }

                      const { data, error } = await urqlGql.deleteAccount({
                        id: event.id,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      return {
                        id: event.id,
                        callback: event.callback,
                      };
                    },
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `The organization deleted successfully`,
                            autoClose: 3000,
                          });
                          if (data && data.callback) {
                            data.callback(true);
                          }
                        },
                      },
                    ],
                    onDoneTarget: 'deleted',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                deleted: {
                  after: {
                    100: 'loaded',
                  },
                },
              },
            },
            currentProject: {
              id: 'currentProject',
              initial: 'notSelected',
              on: {
                'manager.selectProject': {
                  actions: assign((ctx, { id }) => {
                    ctx.selectedProject = id;
                    return ctx;
                  }),
                  target: '.selected',
                },
              },
              states: {
                notSelected: {},
                selected: {
                  invoke: createInvokablePromise({
                    src: async (ctx, event): Promise<void> => {
                      if (!ctx.selectedProject) return;

                      const { data, error } = await urqlGql.getProjectById({
                        id: ctx.selectedProject,
                      });

                      if (!data?.admin_?.projectById) {
                        throw new NotFoundError('Project not found');
                      }

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                    },
                    onErrorAssignContext({ ctx, error }) {
                      if (error instanceof NotFoundError) {
                        ctx.errors = [error];
                      }
                    },
                  }),
                },
              },
            },
            projects: {
              id: 'projects',
              initial: 'waitingForOrg',
              on: {
                'manager.selectOrgId': {
                  actions: assign((ctx, { orgId }) => {
                    ctx.projects = [];
                    ctx.userProjectIds = [...ctx.userProjectIds];
                    return ctx;
                  }),
                  target: '#loadingProjects',
                },
              },
              states: {
                waitingForOrg: {
                  on: {
                    'manager.selectOrgId': {
                      target: 'idle',
                    },
                  },
                },
                idle: {
                  entry: (ctx) => {
                    // console.log('ready to load projects info!', ctx.selectedOrgId);
                  },
                  always: [
                    {
                      target: '#loadingProjects',
                      cond: (ctx, event, data) => {
                        const cond = Boolean(
                          ctx.selectedOrgId !== undefined &&
                            ctx.userInfo.id !== undefined &&
                            ctx.userInfo?.productRoles &&
                            ctx.products?.length
                        );

                        return cond;
                      },
                    },
                  ],
                },
                loading: {
                  id: 'loadingProjects',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      _event
                    ): Promise<{
                      projects: ManagerContext['projects'];
                      userProjectIds: string[];
                    }> => {
                      if (
                        ctx.userInfo.id === undefined ||
                        ctx.selectedOrgId === undefined
                      ) {
                        throw Error('missing info needed to get projects!');
                      }

                      const { data, error } = await urqlGql.getProjects({
                        userId: ctx.userInfo.id,
                        accountId: ctx.selectedOrgId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const allProjects = compact(data?.admin_?.projects?.rows);
                      const userProjectIds = compact(
                        data?.admin_?.userProjects?.rows
                      );

                      return {
                        projects: allProjects,
                        userProjectIds: userProjectIds.length
                          ? uniq(
                              compact(
                                userProjectIds
                                  .map((item) => item.project?.id)
                                  .concat(ctx.userProjectIds)
                              )
                            )
                          : [...ctx.userProjectIds],
                      };
                    },
                    onDoneTarget: '#transforming',
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.projects = data.projects;
                        ctx.userProjectIds = data.userProjectIds;
                      }

                      return ctx;
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user projects: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                error: {},
                adding: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      projects: ManagerContext['projects'];
                      userInfo: ManagerContext['userInfo'];
                      newProjectId: string | undefined;
                      callback: ((res: boolean) => void) | undefined;
                    }> => {
                      if (
                        event.type !== 'manager.addProject' ||
                        !event.name ||
                        !ctx.selectedOrgId
                      )
                        return;

                      const {
                        timeZoneId,
                        name,
                        productIds,
                        description,
                        projectEnvironmentConfigs,
                        projectIconId,
                      } = event;

                      const { data, error } = await urqlGql.createProject({
                        name,
                        accountId: ctx.selectedOrgId,
                        productIds: productIds || [],
                        description: description || '',
                        timeZoneId: timeZoneId || undefined,
                        projectEnvironmentConfigs,
                        projectIconId: projectIconId
                          ? transformProfilePicture(projectIconId)
                          : '',
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      if (data?.admin_?.createProject === undefined) {
                        return {
                          projects: ctx.projects,
                          userInfo: ctx.userInfo,
                          newProjectId: undefined,
                          callback: event.callback,
                        };
                      }

                      const projects: ManagerContext['projects'] = [
                        {
                          // __typename: 'UserProject',
                          // accountId: ctx.selectedOrgId,
                          // project: data.admin_.createProject,
                          projectId: data.admin_.createProject.id,
                          accountId: data.admin_.createProject.accountId,
                          name: data.admin_.createProject.name,
                          description: data.admin_.createProject.description,
                          picture: data.admin_.createProject.picture,
                          updatedAt: new Date().toISOString(),
                          createdAt: new Date().toISOString(),
                          pictureFile: data.admin_.createProject.pictureFile,
                          projectProductSettings:
                            data.admin_.createProject.projectProductSettings,
                          projectEnvironmentConfig:
                            data.admin_.createProject.projectEnvironmentConfig,
                          localizationSettings:
                            data.admin_.createProject.localizationSettings,
                          users: data.admin_.createProject.users,
                        },
                        ...(ctx.projects || []),
                      ];

                      const products = ctx.getProducts({
                        ctx,
                      });

                      const projectProducts =
                        products.conditionalProducts.filter((product) =>
                          productIds.includes(product.productId)
                        );

                      let userInformation = ctx.userInfo;
                      const userMainOrgId = ctx.orgTree?.[0]?.id;

                      // update userInfo with new project for agentAssist product
                      if (
                        projectProducts.find(
                          (product) => product.name === ProductName.AgentAssist
                        ) &&
                        userInformation.productRoles?.agentAssistRolesSource &&
                        userMainOrgId === ctx.selectedOrgId
                      ) {
                        userInformation = {
                          ...userInformation,
                          productRoles: {
                            ...userInformation.productRoles,
                            agentAssistRolesSource: [
                              ...userInformation.productRoles
                                .agentAssistRolesSource,
                              {
                                project: {
                                  id: data.admin_.createProject.id,
                                  name: data.admin_.createProject.name,
                                },
                              },
                            ],
                          },
                        };
                      }

                      // update userInfo with new project for analytics product
                      if (
                        projectProducts.find(
                          (product) => product.name === ProductName.Analytics
                        ) &&
                        userInformation.productRoles?.analyticsRolesSource &&
                        userMainOrgId === ctx.selectedOrgId
                      ) {
                        userInformation = {
                          ...userInformation,
                          productRoles: {
                            ...userInformation.productRoles,
                            analyticsRolesSource: [
                              ...userInformation.productRoles
                                .analyticsRolesSource,
                              {
                                project: {
                                  id: data.admin_.createProject.id,
                                  name: data.admin_.createProject.name,
                                },
                              },
                            ],
                          },
                        };
                      }

                      if (
                        projectProducts.find(
                          (product) => product.name === ProductName.ProStudio
                        ) &&
                        userInformation.productRoles?.proStudioRolesSource &&
                        userMainOrgId === ctx.selectedOrgId
                      ) {
                        userInformation = {
                          ...userInformation,
                          productRoles: {
                            ...userInformation.productRoles,
                            proStudioRolesSource: [
                              ...userInformation.productRoles
                                .proStudioRolesSource,
                              {
                                project: {
                                  id: data.admin_.createProject.id,
                                  name: data.admin_.createProject.name,
                                },
                              },
                            ],
                          },
                        };
                      }
                      return {
                        projects,
                        userInfo: userInformation,
                        newProjectId: data.admin_.createProject.id,
                        callback: event.callback,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.projects = data.projects;
                        ctx.userInfo = data.userInfo;

                        if (
                          data.newProjectId &&
                          data.newProjectId !== undefined
                        ) {
                          ctx.userProjectIds.push(data.newProjectId);
                        }
                        if (data.callback) {
                          data.callback(true);
                        }
                      }
                    },
                    onDoneTarget: '#transformProjectsAfterDelay',
                    onErrorTarget: 'loaded',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },

                transformAfterDelay: {
                  id: 'transformProjectsAfterDelay',
                  after: {
                    50: {
                      target: 'loading',
                      actions: raise('manager.refetchProjects'),
                    },
                  },
                },
                deleting: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      projects: ManagerContext['projects'];
                      event: ManagerEvents;
                      project?: Project;
                    }> => {
                      if (event.type !== 'manager.deleteProject') return;

                      if (!event.projectId || !ctx.selectedOrgId) {
                        throw new Error(
                          'missing info needed to delete project! (projectId or selectedOrgId)'
                        );
                      }

                      const { data, error } = await urqlGql.deleteProject({
                        projectId: event.projectId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const deletedProject = ctx.projects?.find(
                        (project) => project.projectId === event.projectId
                      );
                      const filteredProjects = ctx.projects?.filter(
                        (project) => project.projectId !== event.projectId
                      );

                      return {
                        projects: filteredProjects,
                        event,
                        project: deletedProject,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.projects = data.projects;
                      }
                    },
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          if (data?.event?.type !== 'manager.deleteProject')
                            return;

                          sendUserMessage({
                            type: 'success',
                            text: `Project ${data.project?.name} deleted successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onDoneTarget: 'transforming',
                    onErrorTarget: 'error',
                  }),
                },
                updating: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      nextProjectsState: ManagerContext['projects'];
                      updatedProject?: Project;
                    }> => {
                      if (event.type !== 'manager.updateProject') return;

                      if (event.projectId === undefined) return;

                      const { data, error } = await urqlGql.updateProject({
                        projectId: event.projectId,
                        description: event.description || '',
                        name: event.name || '',
                        timeZoneId: event.timeZoneId || undefined,
                        productIds: event.productIds || [],
                        projectIconId: event.projectIconId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      if (
                        ctx.projects?.length === 0 ||
                        ctx.projects === undefined
                      ) {
                        return {
                          nextProjectsState: [],
                          updatedProject: undefined,
                        };
                      }

                      const updateChannels = Promise.all(
                        (event.environmentConfig || []).map(
                          async ({ name, description, domains }) => {
                            const projectEnvironmentConfigId =
                              ctx.projects
                                ?.find(
                                  ({ projectId }) =>
                                    projectId === event.projectId
                                )
                                ?.projectEnvironmentConfig?.find(
                                  ({ environment }) => {
                                    return environment?.name === name;
                                  }
                                )?.id || '';

                            const result =
                              await urqlGql.updateProjectEnvironmentConfig({
                                projectId: event.projectId || '',
                                description,
                                domains,
                                projectEnvironmentConfigId,
                              });

                            return result;
                          }
                        )
                      );

                      const result = await updateChannels;

                      result.map(
                        ({ data: channelsData, error: channelsError }) => {
                          if (haveErrors(channelsData)) {
                            throw new Error(channelsData.errors?.[0]?.message);
                          }

                          if (channelsError) {
                            throw new Error(channelsError.message);
                          }
                        }
                      );

                      const updatedEnvironmentConfigs = result.map(
                        (r) => r.data?.admin_?.updateProjectEnvironmentConfig
                      );

                      const updatedProject = data?.admin_?.updateProject;

                      const nextProjectsState = ctx.projects.map((item) => {
                        if (item.projectId === updatedProject?.projectId) {
                          return {
                            ...item,
                            ...updatedProject,
                            ...(event.environmentConfig?.length && {
                              projectEnvironmentConfig:
                                item.projectEnvironmentConfig?.map((config) => {
                                  const { environmentId, environment } = config;
                                  const updatedConfig =
                                    updatedEnvironmentConfigs?.find(
                                      (c) => c?.environmentId === environmentId
                                    );

                                  return {
                                    ...updatedConfig,
                                    environmentId,
                                    environment,
                                  };
                                }),
                            }),
                          };
                        }
                        return item;
                      });

                      return { nextProjectsState, updatedProject };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.projects = data.nextProjectsState;
                      }
                    },
                    onDoneTarget: 'transforming',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating project's data: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `Project ${data?.updatedProject?.name} updated successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                transforming: {
                  id: 'transforming',
                  entry: assign((ctx) => {
                    const projectsWithAvailableProduct =
                      ctx.projects?.filter(
                        ({ projectProductSettings }) =>
                          projectProductSettings?.length
                      ) || [];

                    const projectWithAvailableProduct =
                      projectsWithAvailableProduct.length === 1
                        ? projectsWithAvailableProduct[0]
                        : undefined;

                    const projectWithAvailableAgentAssistProduct =
                      ctx.projects?.find(({ projectId }) => {
                        const agentAssistRolesSource =
                          ctx.userInfo?.productRoles?.agentAssistRolesSource ||
                          [];

                        return agentAssistRolesSource.length === 1
                          ? agentAssistRolesSource[0]?.project?.id === projectId
                          : undefined;
                      });

                    const projectWithAvailableAnalyticsProduct =
                      ctx.projects?.find(({ projectId }) => {
                        const analyticsRolesSource =
                          ctx.userInfo?.productRoles?.analyticsRolesSource ||
                          [];

                        return analyticsRolesSource.length === 1
                          ? analyticsRolesSource[0]?.project?.id === projectId
                          : undefined;
                      });

                    const projectWithAvailableProStudioProduct =
                      ctx.projects?.find(({ projectId }) => {
                        const proStudioRolesSource =
                          ctx.userInfo?.productRoles?.proStudioRolesSource ||
                          [];

                        return proStudioRolesSource.length === 1
                          ? proStudioRolesSource[0]?.project?.id === projectId
                          : undefined;
                      });

                    const tools = [
                      projectWithAvailableProduct,
                      projectWithAvailableAgentAssistProduct,
                      projectWithAvailableAnalyticsProduct,
                      projectWithAvailableProStudioProduct,
                    ].map((projectWithProduct, index) => {
                      const { products: _tools } = ctx.getProducts({
                        project: projectWithProduct,
                        ctx,
                      });

                      return _tools[index];
                    });

                    const projects = ctx.projects?.map((p) => {
                      const {
                        selectedProjectURL,
                        conditionalProducts,
                        hasAvailableProduct,
                      } = ctx.getProducts({
                        project: p,
                        ctx,
                      });

                      return {
                        ...p,
                        selectedProjectURL,
                        conditionalProducts,
                        hasAvailableProduct,
                      };
                    });

                    const { conditionalProducts: createProjectTools } =
                      ctx.getProducts({ ctx });

                    return {
                      ...ctx,
                      projects,
                      createProjectTools,
                    };
                  }),
                  always: {
                    cond: (ctx) => Boolean(ctx.createProjectTools),
                    target: '#projectsLoaded',
                  },
                },
                loaded: {
                  id: 'projectsLoaded',
                  on: {
                    'manager.deleteProject': 'deleting',
                    'manager.addProject': 'adding',
                    'manager.updateProject': 'updating',
                    'manager.selectProjectModal': {
                      actions: assign((ctx, { id }) => {
                        ctx.selectedProjectModal = id;
                        return ctx;
                      }),
                    },
                    'manager.deselectProjectModal': {
                      actions: assign((ctx) => {
                        ctx.selectedProjectModal = undefined;
                        return ctx;
                      }),
                    },
                  },
                },
              },
            },

            projectsNew: {
              id: 'projectsNew',
              initial: 'waitingForOrg',
              on: {
                'manager.selectOrgId': {
                  actions: assign((ctx) => {
                    ctx.projectsNew = [];
                    return ctx;
                  }),
                  target: '.loading',
                },
                'manager.refetchProjects': {
                  actions: assign((ctx) => {
                    ctx.projectsNew = [];
                    return ctx;
                  }),
                  target: '.loading',
                },
              },
              states: {
                waitingForOrg: {
                  on: {
                    'manager.selectOrgId': {
                      target: 'idle',
                    },
                  },
                },
                idle: {
                  entry: (ctx) => {},
                  always: [
                    {
                      target: '#loadingProjectsNew',
                      cond: (ctx, event, data) => {
                        const cond = Boolean(
                          ctx.selectedOrgId !== undefined &&
                            ctx.userInfo.id !== undefined &&
                            ctx.userInfo?.productRoles &&
                            ctx.products?.length
                        );

                        return cond;
                      },
                    },
                  ],
                },
                loading: {
                  id: 'loadingProjectsNew',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      _event
                    ): Promise<{
                      projects: ManagerContext['projectsNew'];
                    }> => {
                      if (
                        ctx.userInfo.id === undefined ||
                        ctx.selectedOrgId === undefined
                      ) {
                        throw Error('missing info needed to get projects!');
                      }

                      const { data, error } =
                        await urqlGql.getUserProjectsHasura({
                          userId: ctx.userInfo.id,
                          accountId: ctx.selectedOrgId,
                        });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      const mappedProjects: ProjectNew[] = [];

                      if (data?.tenancy_user_accounts.length) {
                        data?.tenancy_user_accounts.forEach(
                          ({ user, user_account_projects }) => {
                            const projectsByTeams: ProjectNew[] = [];
                            const projectsByAccount = user_account_projects.map(
                              ({ project }) => {
                                return {
                                  ...project,
                                };
                              }
                            );
                            user._user_teams.forEach(({ team }) => {
                              team.team_accounts.forEach(
                                ({ team_account_projects }) => {
                                  team_account_projects.forEach(
                                    ({ project }) => {
                                      projectsByTeams.push({
                                        ...project,
                                      });
                                    }
                                  );
                                }
                              );
                            });
                            mappedProjects.push(
                              ...[...projectsByAccount, ...projectsByTeams]
                            );
                          }
                        );
                      }

                      return {
                        projects: uniqBy(
                          orderBy(
                            mappedProjects,
                            (project) => project.name.toLowerCase(),
                            'asc'
                          ),
                          'id'
                        ),
                      };
                    },
                    onDoneTarget: '#transformingNew',
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.projectsNew = data.projects;
                      }
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user projects: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                transforming: {
                  id: 'transformingNew',
                  entry: assign((ctx) => {
                    const projectsWithAvailableProduct =
                      ctx.projectsNew?.filter(
                        ({ projectProductSettings }) =>
                          projectProductSettings?.length
                      ) || [];

                    const projectWithAvailableProduct =
                      projectsWithAvailableProduct.length === 1
                        ? projectsWithAvailableProduct[0]
                        : undefined;

                    const projectWithAvailableAgentAssistProduct =
                      ctx.projectsNew?.find(({ id }) => {
                        const agentAssistRolesSource =
                          ctx.userInfo?.productRoles?.agentAssistRolesSource ||
                          [];

                        return agentAssistRolesSource.length === 1
                          ? agentAssistRolesSource[0]?.project?.id === id
                          : undefined;
                      });

                    const projectWithAvailableAnalyticsProduct =
                      ctx.projectsNew?.find(({ id }) => {
                        const analyticsRolesSource =
                          ctx.userInfo?.productRoles?.analyticsRolesSource ||
                          [];

                        return analyticsRolesSource.length === 1
                          ? analyticsRolesSource[0]?.project?.id === id
                          : undefined;
                      });

                    const projectWithAvailableProStudioProduct =
                      ctx.projectsNew?.find(({ id }) => {
                        const proStudioRolesSource =
                          ctx.userInfo?.productRoles?.proStudioRolesSource ||
                          [];

                        return proStudioRolesSource.length === 1
                          ? proStudioRolesSource[0]?.project?.id === id
                          : undefined;
                      });

                    const tools = [
                      projectWithAvailableProduct,
                      projectWithAvailableAgentAssistProduct,
                      projectWithAvailableAnalyticsProduct,
                      projectWithAvailableProStudioProduct,
                    ].map((projectWithProduct, index) => {
                      const { products: _tools } = ctx.getProducts({
                        project: projectWithProduct,
                        ctx,
                        isNewProjects: true,
                      });

                      return _tools[index];
                    });

                    const projectsNew = ctx.projectsNew?.map((p) => {
                      const {
                        selectedProjectURL,
                        conditionalProducts,
                        hasAvailableProduct,
                      } = ctx.getProducts({
                        project: p,
                        ctx,
                        isNewProjects: true,
                      });

                      return {
                        ...p,
                        selectedProjectURL,
                        conditionalProducts,
                        hasAvailableProduct,
                      };
                    });

                    const { conditionalProducts: createProjectTools } =
                      ctx.getProducts({ ctx, isNewProjects: true });

                    return {
                      ...ctx,
                      tools,
                      projectsNew,
                      createProjectTools,
                    };
                  }),
                  always: {
                    cond: (ctx) => Boolean(ctx.tools && ctx.createProjectTools),
                    target: '#projectsLoadedNew',
                  },
                },
                error: {},
                loaded: {
                  id: 'projectsLoadedNew',
                  on: {
                    'manager.selectOrgId': {
                      target: 'idle',
                    },
                  },
                },
              },
            },

            applets: {
              id: 'applets',
              initial: 'idle',
              states: {
                idle: {
                  entry: (ctx) => {
                    // console.log('ready to load projects info!', ctx.selectedOrgId);
                  },
                  always: [
                    {
                      target: 'loading',
                      cond: (ctx, event, data) => {
                        const cond = Boolean(
                          ctx.selectedProject !== undefined &&
                            ctx.userInfo.id !== undefined &&
                            !ctx.applets
                        );

                        return cond;
                      },
                    },
                  ],
                },
                loading: {
                  id: 'loadingApplets',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      _event
                    ): Promise<{
                      applets: ManagerContext['applets'];
                    }> => {
                      if (
                        ctx.userInfo.id === undefined ||
                        ctx.selectedProject === undefined
                      ) {
                        throw Error('missing info needed to get projects!');
                      }

                      const { data, error } =
                        await urqlGql.getAppletInstancesByProjectId({
                          projectId: ctx.selectedProject,
                        });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const allAppletInstances =
                        compact(
                          flatten(
                            compact(
                              data?.admin_?.projectById
                                ?.projectEnvironmentConfig
                            )?.map((prcc) => prcc.appletInstances)
                          )
                        ) || [];

                      return {
                        applets: allAppletInstances,
                      };
                    },
                    onDoneTarget: 'loaded',
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.applets = data.applets;
                      }

                      return ctx;
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user projects: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                error: {},
                adding: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<
                      | void
                      | NonNullable<
                          CreateAppletInstanceMutation['admin_']
                        >['createAppletInstance']
                    > => {
                      if (
                        event.type !== 'manager.addApplet' ||
                        !event.name ||
                        !ctx.selectedOrgId
                      )
                        return;

                      const {
                        description,
                        name,
                        projectEnvironmentConfigId,
                        appletVersionId,
                      } = event;

                      const { data, error } =
                        await urqlGql.createAppletInstance({
                          name: name,
                          projectEnvironmentConfigId:
                            projectEnvironmentConfigId,
                          description: description || '',
                          // appletVersionId: event.latestVersion.id,
                          appletVersionId: appletVersionId,
                        });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      return data?.admin_?.createAppletInstance;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (!data) {
                        throw new Error(
                          'Returned data from createAppletInstance is undefined'
                        );
                      }
                      ctx.applets?.push(data);
                    },
                    onDoneTarget: 'transformAfterDelay',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                transformAfterDelay: {
                  id: 'transformAppletsAfterDelay',
                  after: {
                    50: {
                      target: 'loaded',
                    },
                  },
                },
                deleting: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<AppletInstance['id'] | void> => {
                      if (event.type !== 'manager.deleteApplet' || !event.id)
                        return;

                      const { data, error } =
                        await urqlGql.deleteAppletInstance({
                          deleteAppletInstanceId: event.id,
                        });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      return event.id;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      ctx.applets = ctx.applets?.filter((a) => a.id !== data);
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },

                updating: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<
                      | void
                      | NonNullable<
                          UpdateAppletInstanceMutation['admin_']
                        >['updateAppletInstance']
                    > => {
                      if (event.type !== 'manager.updateApplet') return;

                      if (event.updateAppletInstanceId === undefined) return;

                      const { type, ...variables } = event;
                      const { data, error } =
                        await urqlGql.updateAppletInstance(variables);

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return data?.admin_?.updateAppletInstance;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        const index = ctx.applets?.findIndex(
                          (a) => a.id === data.id
                        );
                        if (index === undefined) {
                          throw new Error(
                            'Returned applet from UpdateAppletInstance Mutation is not found'
                          );
                        }
                        if (index >= 0) {
                          ctx.applets = ctx.applets?.with(index, data);
                        }
                      }
                    },
                    // onDoneTarget: 'idle',
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating project's data: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `Applet ${data?.name} updated successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {
                  id: 'AppletsLoaded',
                  on: {
                    'manager.addApplet': 'adding',
                    'manager.deleteApplet': 'deleting',
                    'manager.updateApplet': 'updating',
                  },
                },
              },
            },

            // Shouldn't live in this state machine (instead under User machine); shouldn't require us to pass in the accountId. Even if it did, it should be the accountId under our user account, not the accountId of the currently selected org
            userInfo: {
              id: 'userInfo',
              initial: 'idle',
              states: {
                idle: {
                  always: [
                    {
                      target: 'fetching',
                      cond: (context) =>
                        Boolean(
                          !context.userInfo?.recommendedAuthMethod &&
                            context.userInfo?.id !== undefined &&
                            context.selectedOrgId
                        ),
                    },
                  ],
                  on: {
                    'manager.updateUserPreferences': {
                      target: '#userInfo.updating',
                    },
                    'manager.refetchUserSettings': {
                      target: '#userInfo.fetching',
                    },
                  },
                },
                fetching: {
                  id: 'fetching',
                  invoke: createInvokablePromise({
                    src: async (ctx, event) => {
                      const {
                        userInfo: { id: userId, email, defaultOrgId },
                        selectedOrgId: accountId,
                      } = ctx;

                      const selectedOrgId =
                        event.type === 'manager.refetchUserSettings'
                          ? event.orgId
                          : accountId;

                      if (!userId || !selectedOrgId || !email) {
                        throw new Error('User id or accountId not provided');
                      }

                      const { data, error } = await urqlGql
                        .getUserSettings({
                          accountId: defaultOrgId || selectedOrgId,
                          userId,
                          email,
                        })
                        .toPromise();

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const nextUserInfoState: ManagerContext['userInfo'] = {
                        ...ctx.userInfo,
                        status:
                          data?.admin_?.currentUser?.status ||
                          ctx.userInfo.status,
                        productRoles: data?.admin_?.userProductRoles || {},
                        recommendedAuthMethod:
                          data?.admin_?.getLoginInfo?.recommendedAuthMethod,
                        registeredAuthMethods:
                          data?.admin_?.getLoginInfo?.registeredAuthMethods ||
                          [],
                        canManageAccess: Boolean(
                          data?.admin_?.organizationMetadata?.canManageAccess
                        ),
                        defaultLanguage:
                          data?.admin_?.currentUser?.settings?.defaultLanguage?.split(
                            '-'
                          )?.[0],
                      };

                      if (nextUserInfoState.defaultLanguage) {
                        await changeLanguage(nextUserInfoState.defaultLanguage);
                      }

                      return {
                        nextUserInfoState,
                        event,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.userInfo = data.nextUserInfoState;
                      }
                    },
                    onDoneTarget: 'idle',
                    onErrorTarget: 'error',
                    onDoneActions: [
                      {
                        type: 'transformProjectsSettings',
                        exec(
                          _ctx,
                          {
                            data: {
                              event: { type },
                            },
                          }
                        ) {
                          if (type === 'manager.refetchUserSettings') {
                            sendManagerEvent({
                              type: 'manager.transformProjectsSettings',
                            });
                          }
                        },
                      },
                    ],
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading My Account data via graphql: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                refetchUserSettings: {
                  id: 'refetchUserSettings',
                  invoke: createInvokablePromise({
                    src: async (ctx, event) => {
                      const {
                        userInfo: { id: userId, email },
                        selectedOrgId: accountId,
                      } = ctx;

                      const selectedOrgId =
                        event.type === 'manager.refetchUserSettings'
                          ? event.orgId
                          : accountId;

                      if (!userId || !selectedOrgId || !email) {
                        throw new Error('User id or accountId not provided');
                      }

                      const { data, error } = await urqlGql
                        .getUserSettings({
                          accountId: selectedOrgId,
                          userId,
                          email,
                        })
                        .toPromise();

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const nextState = {
                        ...ctx.userInfo,
                        selectedOrgId,
                        defaultLanguage:
                          data?.admin_?.currentUser?.settings?.defaultLanguage?.split(
                            '-'
                          )?.[0],
                      };

                      if (nextState.defaultLanguage) {
                        await changeLanguage(nextState.defaultLanguage);
                      }
                      const userService = globalAppService.children.get(
                        GLOBAL_APP_SUB_MACHINE_IDS.user
                      ) as UserInterpreter;
                      userService.send({
                        type: 'update.userPreferences',
                        userActionPayload: {
                          user: data?.admin_?.currentUser,
                        },
                      });

                      return {
                        nextState,
                        event,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.userInfo = data.nextState;
                      }
                    },
                    onDoneTarget: 'refetched',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading My Account data via graphql: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                refetched: {
                  after: {
                    100: 'idle',
                  },
                },
                updating: {
                  id: 'updating',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<
                      | void
                      | NonNullable<UpdateUserMutation['admin_']>['updateUser']
                    > => {
                      if (event.type !== 'manager.updateUserPreferences')
                        return;

                      const {
                        firstName,
                        lastName,
                        jobTitle,
                        status,
                        requiredConsentStatus,
                        optionalConsentStatus,
                        requiredConsentUpdatedAt,
                        optionalConsentUpdatedAt,
                        defaultLanguage,
                        defaultTimezone,
                        userIconId,
                      } = event.values;

                      const {
                        userInfo: { id: userId },
                        selectedOrgId: accountId,
                      } = ctx;

                      if (!userId || !accountId) {
                        throw new Error('User id or accountId not provided');
                      }

                      // we shouldn't have to re-send values that haven't changed
                      const { data, error } = await urqlGql.updateUser({
                        id: userId,
                        accountId,
                        firstName,
                        lastName,
                        userIconId,
                        settings: {
                          jobTitle,
                          defaultLanguage,
                          defaultTimezone,
                        },
                        status,
                        requiredConsentStatus,
                        optionalConsentStatus,
                        requiredConsentUpdatedAt,
                        optionalConsentUpdatedAt,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      return data?.admin_?.updateUser;
                    },
                    onDoneTarget: 'updated',
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `User ${data?.firstName} ${data?.lastName} updated successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating user info via graphql: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                updated: {
                  entry() {
                    setTimeout(
                      () => sendUserEvent({ type: 'user.refetch' }),
                      1
                    );
                  },
                  always: [{ target: '#refetchUserSettings' }],
                },
                error: {},
              },
            },

            // shouldn't exist
            routes: {
              id: 'routes',
              initial: 'idle',
              on: {
                'manager.selectOrgId': {
                  target: '#updatingRoutes',
                },
              },
              states: {
                idle: {
                  always: {
                    target: 'updating',
                    cond: (context) => context.selectedOrgId !== undefined,
                  },
                },
                updating: {
                  id: 'updatingRoutes',
                  entry: assign((ctx) => {
                    const { selectedOrgId, routes, userInfo } = ctx;
                    const { admin: adminBaseURL } = routes;
                    const adminURL = `${adminBaseURL}/organization/${selectedOrgId}`;
                    const basePath = selectedOrgId
                      ? `/organization/${selectedOrgId}`
                      : '';
                    const defaultOrgPath = userInfo
                      ? `/organization/${userInfo?.defaultOrgId}`
                      : '';

                    ctx.routes = {
                      ...ctx.routes,
                      home: basePath || '/',
                      myAccount: `${defaultOrgPath}/my-account`,
                      projects: `${basePath}/projects`,
                      users: `${basePath}/users`,
                      teams: `${adminURL}/teams-management`,
                      roles: `${adminURL}/roles-management`,
                      orgs: `${basePath}/child-orgs`,
                      logs: `${basePath}/audit-logs`,
                      superAdmin: `${basePath}/super-admin`,
                      wipTeams: `${basePath}/teams`,
                    };

                    return ctx;
                  }),
                  onDone: 'updated',
                },
                updated: {
                  on: {
                    'manager.selectOrgId': {
                      target: '#updatingRoutes',
                    },
                  },
                },
              },
            },
            projectDetails: {
              initial: 'idle',
              states: {
                idle: {},
                name: {
                  states: {
                    loading: {
                      id: 'projectNameUpdating',
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<ManagerContext['projects'] | void> => {
                          if (event.type !== 'manager.updateProject.name')
                            return;

                          if (event.projectId === undefined) return;

                          const { projectId } = event;

                          const { data, error } = await urqlGql.updateProject({
                            projectId,
                            name: event.data.value || '',
                          });

                          if (haveErrors(data)) {
                            throw new Error(data.errors?.[0]?.message);
                          }

                          if (error) {
                            throw new Error(error.message);
                          }

                          const updatedName = data?.admin_?.updateProject?.name;

                          const updatedProjects = ctx.projects?.map(
                            (project) => {
                              if (project.projectId === event.projectId) {
                                return {
                                  ...project,
                                  name: updatedName || '',
                                };
                              }
                              return project;
                            }
                          );

                          return updatedProjects;
                        },
                        onDoneAssignContext({ ctx, data }) {
                          if (data !== undefined) {
                            ctx.projects = data;
                          }
                        },
                        onDoneTarget: '#successNameUpdating',
                        onErrorTarget: '#errorNameUpdating',
                        onErrorActions: [
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error saving project's name: ${event.data.message}`,
                                autoClose: 3000,
                              });
                            },
                          },
                        ],
                      }),
                    },
                    success: {
                      id: 'successNameUpdating',
                    },
                    error: {
                      id: 'errorNameUpdating',
                    },
                  },
                },
                description: {
                  states: {
                    loading: {
                      id: 'projectDescriptionUpdating',
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<ManagerContext['projects'] | void> => {
                          if (
                            event.type !== 'manager.updateProject.description'
                          )
                            return;

                          if (event.projectId === undefined) return;

                          const projectName = ctx.projects?.find(
                            ({ projectId }) => projectId === event.projectId
                          )?.name;

                          if (projectName === undefined) return;

                          const { data, error } = await urqlGql.updateProject({
                            projectId: event.projectId,
                            name: projectName,
                            description: event.data.value || '',
                          });

                          if (haveErrors(data)) {
                            throw new Error(data.errors?.[0]?.message);
                          }

                          if (error) {
                            throw new Error(error.message);
                          }

                          const updatedDescription =
                            data?.admin_?.updateProject?.description;

                          const updatedProjects = ctx.projects?.map(
                            (project) => {
                              if (project.projectId === event.projectId) {
                                return {
                                  ...project,
                                  description: updatedDescription || '',
                                };
                              }
                              return project;
                            }
                          );

                          return updatedProjects;
                        },
                        onDoneAssignContext({ ctx, data }) {
                          if (data !== undefined) {
                            ctx.projects = data;
                          }
                        },
                        onDoneTarget: '#successDescriptionUpdating',
                        onErrorTarget: '#errorDescriptionUpdating',
                        onErrorActions: [
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error saving project's description: ${event.data.message}`,
                                autoClose: 3000,
                              });
                            },
                          },
                        ],
                      }),
                    },
                    success: {
                      id: 'successDescriptionUpdating',
                    },
                    error: {
                      id: 'errorDescriptionUpdating',
                    },
                  },
                },
                timeZoneId: {
                  states: {
                    loading: {
                      id: 'projectTimeZoneIdUpdating',
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<ManagerContext['projects'] | void> => {
                          if (event.type !== 'manager.updateProject.timeZoneId')
                            return;

                          if (event.projectId === undefined) return;

                          const projectName = ctx.projects?.find(
                            ({ projectId }) => projectId === event.projectId
                          )?.name;

                          if (projectName === undefined) return;

                          const { data, error } = await urqlGql.updateProject({
                            projectId: event.projectId,
                            name: projectName,
                            timeZoneId: (event.data.value || null)!,
                          });

                          if (haveErrors(data)) {
                            throw new Error(data.errors?.[0]?.message);
                          }

                          if (error) {
                            throw new Error(error.message);
                          }

                          const updatedTimeZone =
                            data?.admin_?.updateProject?.localizationSettings
                              ?.timeZone;

                          const updatedProjects = ctx.projects?.map(
                            (project) => {
                              if (project.projectId === event.projectId) {
                                return {
                                  ...project,
                                  localizationSettings: {
                                    ...project.localizationSettings,
                                    id: project.localizationSettings?.id || '',
                                    timeZone: {
                                      ...updatedTimeZone,
                                      id: updatedTimeZone?.id || '',
                                      identifier:
                                        updatedTimeZone?.identifier || '',
                                      label: updatedTimeZone?.label || '',
                                    },
                                  },
                                };
                              }
                              return project;
                            }
                          );

                          return updatedProjects;
                        },
                        onDoneAssignContext({ ctx, data }) {
                          if (data !== undefined) {
                            ctx.projects = data;
                          }
                        },
                        onDoneTarget: '#successTimeZoneIdUpdating',
                        onErrorTarget: '#errorTimeZoneIdUpdating',
                        onErrorActions: [
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error saving project's timezone: ${event.data.message}`,
                                autoClose: 3000,
                              });
                            },
                          },
                        ],
                      }),
                    },
                    success: {
                      id: 'successTimeZoneIdUpdating',
                    },
                    error: {
                      id: 'errorTimeZoneIdUpdating',
                    },
                  },
                },
                projectIcon: {
                  states: {
                    loading: {
                      id: 'projectIconUpdating',
                      entry: assign((ctx, event) => {
                        if (event.type !== 'manager.updateProject.projectIcon')
                          return ctx;

                        if (event.projectId === undefined) return ctx;

                        const updatedProjects = ctx.projects?.map((project) => {
                          if (project.projectId === event.projectId) {
                            let update;
                            if (event.data.name === 'projectIconId') {
                              update = {
                                pictureFile: {
                                  url: project.pictureFile?.url || '',
                                  publicId: event.data.value,
                                },
                              };
                            } else if (event.data.name === 'projectIcon') {
                              update = {
                                pictureFile: {
                                  publicId: project.pictureFile?.publicId || '',
                                  url: event.data.value,
                                },
                              };
                            }

                            return {
                              ...project,
                              ...update,
                            };
                          }
                          return project;
                        });

                        return { projects: updatedProjects };
                      }),
                      exit: '.success',
                    },
                    success: {
                      id: 'successProjectIconUpdating',
                    },
                    error: {
                      id: 'errorProjectIconUpdating',
                    },
                  },
                },
              },
            },
            currentUser: {
              id: 'currentUser',
              initial: 'notSelected',
              on: {
                'manager.selectUser': {
                  actions: immerAssign((ctx, { id }) => {
                    ctx.selectedUser = id;
                  }),
                  target: '.selected',
                },
              },
              states: {
                notSelected: {},
                selected: {
                  invoke: createInvokablePromise({
                    src: async (ctx, event): Promise<void> => {
                      if (
                        !ctx.selectedUser ||
                        // Don't load user details for NEW_USER_TEMP_ID which is used with user invite flow
                        ctx.selectedUser === NEW_USER_TEMP_ID
                      )
                        return;

                      const { data, error } = await urqlGql.getUserById({
                        id: ctx.selectedUser,
                      });

                      if (!data?.admin_?.userById) {
                        throw new NotFoundError('User not found');
                      }

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                    },
                    onErrorAssignContext({ ctx, error }) {
                      if (error instanceof NotFoundError) {
                        ctx.errors = [error];
                      }
                    },
                  }),
                },
              },
            },
            users: {
              id: 'users',
              initial: 'idle',
              on: {
                'manager.users.loadUsers': {
                  target: '.loading',
                },
              },
              states: {
                idle: {},
                loading: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<ManagerContext['users'] | undefined> => {
                      if (ctx.selectedOrgId === undefined) {
                        throw Error('Missing organization to load users!');
                      }

                      const { data, error } = await urqlGql.getUsers({
                        accountId: ctx.selectedOrgId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return data?.admin_?.users?.rows;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.users = data;
                      }
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading users: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {
                  on: {
                    'manager.selectOrgId': {
                      actions: assign((ctx) => {
                        ctx.users = [];
                        return ctx;
                      }),
                      target: 'loading',
                    },
                  },
                },
                error: {},
              },
            },
            currentTeam: {
              id: 'currentTeam',
              initial: 'notSelected',
              on: {
                'manager.selectTeam': {
                  actions: assign((ctx, { id }) => {
                    ctx.selectedTeam = id;
                    return ctx;
                  }),
                  target: '.selected',
                },
              },
              states: {
                notSelected: {},
                selected: {
                  invoke: createInvokablePromise({
                    src: async (ctx, event): Promise<void> => {
                      if (!ctx.selectedTeam) return;

                      const { data, error } = await urqlGql.getTeamById({
                        id: ctx.selectedTeam,
                      });

                      if (!data?.admin_?.teamById) {
                        throw new NotFoundError('Team not found');
                      }

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                    },
                    onErrorAssignContext({ ctx, error }) {
                      if (error instanceof NotFoundError) {
                        ctx.errors = [error];
                      }
                    },
                  }),
                },
              },
            },
            teams: {
              id: 'teams',
              initial: 'idle',
              on: {
                'manager.teams.loadTeams': {
                  target: '.loading',
                },
              },
              states: {
                idle: {},
                loading: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<ManagerContext['teams'] | undefined> => {
                      if (ctx.selectedOrgId === undefined) {
                        throw Error('Missing organization to load teams!');
                      }

                      const { data, error } = await urqlGql.getTeams({
                        accountId: ctx.selectedOrgId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return data?.admin_?.teams?.rows;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.teams = data;
                      }
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading teams: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {
                  on: {
                    'manager.selectOrgId': {
                      actions: assign((ctx) => {
                        ctx.teams = [];
                        return ctx;
                      }),
                      target: 'loading',
                    },
                  },
                },
                error: {},
              },
            },
            logs: {
              initial: 'idle',
              on: {
                'manager.logs.loadLogs': {
                  target: '#loadLogs',
                },
                'manager.selectOrgId': {
                  actions: assign((ctx) => {
                    ctx.logs = {
                      count: 0,
                      rows: [],
                    };
                    return ctx;
                  }),
                  target: '#loadLogs',
                },
              },
              states: {
                idle: {},
                loading: {
                  id: 'loadLogs',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<
                      | {
                          data: ManagerContext['logs'] | undefined;
                          queryMetadata: QueryMetadata;
                        }
                      | undefined
                    > => {
                      if (event.type !== 'manager.logs.loadLogs') return;

                      if (ctx.selectedOrgId === undefined) {
                        throw Error('Missing organization to load logs!');
                      }

                      const { queryMetadata, invalidateCache } = event;
                      const { logsCache } = ctx;
                      const cacheKey = `${ctx.selectedOrgId}_${queryMetadata.skipPages}_${queryMetadata.pageSize}`;

                      if (logsCache[cacheKey] && !invalidateCache) {
                        return {
                          data: logsCache[cacheKey],
                          queryMetadata,
                        };
                      }

                      if (invalidateCache && logsCache[cacheKey]) {
                        const { cacheKey: _, ...newLogsCache } = logsCache;
                        ctx.logsCache = { ...newLogsCache };
                      }

                      const { data, error } = await urqlGql.getAuditLogs({
                        accountId: ctx.selectedOrgId,
                        queryMetadata,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return {
                        data: data?.admin_?.auditLogs,
                        queryMetadata,
                      };
                    },
                    onDoneTarget: 'loaded',
                    onDoneAssignContext({ ctx, data }) {
                      if (data && data.data !== undefined) {
                        const { queryMetadata } = data;
                        ctx.logs = data.data;
                        ctx.logsCache[
                          `${ctx.selectedOrgId}_${queryMetadata.skipPages}_${queryMetadata.pageSize}`
                        ] = data.data;
                      }
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading logs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {},
                error: {},
              },
            },
            childOrgs: {
              id: 'childOrgs',
              initial: 'idle',
              on: {
                'manager.childOrgs.loadChildOrgs': {
                  target: '.loading',
                },
                'manager.selectOrgId': {
                  actions: assign((ctx) => {
                    ctx.childOrgs = {
                      rows: [],
                      count: 0,
                      currentOrg: undefined,
                    };
                    return ctx;
                  }),
                  target: '#childOrgsLoading',
                },
              },
              states: {
                idle: {},
                loading: {
                  id: 'childOrgsLoading',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<GetChildAccountsQuery['admin_'] | undefined> => {
                      if (ctx.selectedOrgId === undefined) {
                        throw Error('Missing organization to load child orgs!');
                      }

                      const { data, error } = await urqlGql.getChildAccounts({
                        accountId: ctx.selectedOrgId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return data?.admin_;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.childOrgs = {
                          rows: data.accounts?.rows,
                          count: data.accounts?.count,
                          currentOrg: data.accountById,
                        };
                      }
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading child orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {},
                error: {},
              },
            },
            currentEnvironment: {
              id: 'currentEnvironment',
              initial: 'notSelected',
              on: {
                'manager.selectEnvironment': {
                  actions: assign((ctx, { id }) => {
                    ctx.selectedEnvironment = id;
                    return ctx;
                  }),
                  target: '.selected',
                },
              },
              states: {
                notSelected: {},
                selected: {
                  invoke: createInvokablePromise({
                    src: async (ctx, event): Promise<void> => {
                      if (!ctx.selectedEnvironment) return;

                      const { data, error } =
                        await urqlGql.getProjectEnvironmentConfigById({
                          id: ctx.selectedEnvironment,
                        });

                      if (!data?.admin_?.projectEnvironmentConfigById) {
                        throw new NotFoundError('Environment not found');
                      }

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                    },
                    onErrorAssignContext({ ctx, error }) {
                      if (error instanceof NotFoundError) {
                        ctx.errors = [error];
                      }
                    },
                  }),
                },
              },
            },
            userDetails: {
              id: 'usersDetails',
              initial: 'idle',
              on: {
                'manager.loadUserDetails': '.loading',
                'manager.addNewUser': '.addNewUser.loadingPrerequisites',
                'manager.userDetails.setStateToIdle': '.idle',
                'manager.autoSaveUserDetails': '.autoSaveUserDetails',
                'manager.userDetails.addProject': {
                  actions: handleAddProjectAccess,
                  cond: (ctx) => !!ctx.userInfo.canManageAccess,
                },
                'manager.userDetails.addTeam': {
                  target: '.addTeamToOrg',
                  cond: (ctx) => !!ctx.userInfo.canManageAccess,
                },
                'manager.userDetails.updateProjectProductRole': {
                  actions: updateProjectProductRole,
                  cond: (ctx) => !!ctx.userInfo.canManageAccess,
                },
                'manager.userDetails.updateManagerRole': {
                  actions: updateManagerRole,
                  cond: (ctx) => !!ctx.userInfo.canManageAccess,
                },
                'manager.userDetails.removeTeam': {
                  actions: removeTeam,
                  cond: (ctx) => !!ctx.userInfo.canManageAccess,
                },
                'manager.userDetails.updateUserSettings': {
                  actions: updateUserSettings,
                },
                'manager.userDetails.discardUpdates': {
                  actions: immerAssign((ctx, event) => {
                    if (ctx.userDetails[event.userId]) {
                      ctx.userDetails[event.userId].state =
                        ctx.userDetails[event.userId].initialState;
                      ctx.userDetails[event.userId].userDetailUpdates =
                        userDetailUpdateInitialState;
                    }
                    return ctx;
                  }),
                  cond: (ctx) => !!ctx.userInfo.canManageAccess,
                },
                'manager.userDetails.saveUpdates': {
                  target: '.saveUpdates',
                  cond: (ctx, event) =>
                    !!ctx.userDetails[event.userId]?.userDetailUpdates
                      .hasPendingUpdates,
                },
                'manager.userDetails.inviteUser': {
                  target: '.addNewUser.invitingUser',
                  cond: (ctx, event) => !!ctx.userInfo.canManageAccess,
                },
                'manager.userDetails.deleteUser.openConfirmation': {
                  target: '.deleteUser.confirmUserDeletion',
                  cond: (ctx, event) => !!ctx.userInfo.canManageAccess,
                },
                'manager.userDetails.deleteUser.confirmed': {
                  target: '.deleteUser.deleting',
                  cond: (ctx, event) => !!ctx.userInfo.canManageAccess,
                },
                'manager.userDetails.toggleOrgCollapseState': {
                  actions: immerAssign((ctx, event) => {
                    if (
                      ctx.selectedUser &&
                      event.type ===
                        'manager.userDetails.toggleOrgCollapseState'
                    ) {
                      const currentUserDetails =
                        ctx.userDetails[ctx.selectedUser];

                      if (currentUserDetails) {
                        const orgIndexToUpdate = (
                          currentUserDetails.state?.orgs || []
                        ).findIndex((org) => org.id === event.orgId);

                        if (
                          currentUserDetails.state &&
                          currentUserDetails.initialState &&
                          orgIndexToUpdate >= 0
                        ) {
                          currentUserDetails.state.orgs[
                            orgIndexToUpdate
                          ].isCollapsed = event.collapse;

                          currentUserDetails.initialState.orgs[
                            orgIndexToUpdate
                          ].isCollapsed = event.collapse;
                        }
                      }
                    }
                  }),
                },
                'manager.userDetails.toggleAllOrgCollapseState': {
                  actions: immerAssign((ctx, event) => {
                    if (
                      ctx.selectedUser &&
                      event.type ===
                        'manager.userDetails.toggleAllOrgCollapseState'
                    ) {
                      ctx.userDetails[ctx.selectedUser].state?.orgs.forEach(
                        (org) => (org.isCollapsed = event.collapse)
                      );

                      ctx.userDetails[
                        ctx.selectedUser
                      ].initialState?.orgs.forEach(
                        (org) => (org.isCollapsed = event.collapse)
                      );
                    }
                  }),
                },
              },
              states: {
                idle: {},
                loading: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<{
                      userDetails: UserDetailsType;
                      userDetailUpdates: UserDetailUpdates;
                    } | null> => {
                      if (event.type !== 'manager.loadUserDetails') {
                        return null;
                      }

                      return Promise.all([
                        urqlGql.getAccountDetails({ id: event.orgId! }),
                        urqlGql.getUserDetails({
                          id: event.userId,
                        }),
                      ]).then(
                        ([
                          { data: orgDetails, error: orgDetailsError },
                          { data: userAccountDetails, error: userDetailsError },
                        ]) => {
                          if (
                            orgDetailsError ||
                            userDetailsError ||
                            !orgDetails
                          ) {
                            throw new Error(
                              `*OrgDetailsError - ${orgDetailsError?.message}. *UserDetailsError - ${userDetailsError?.message}`
                            );
                          }
                          const allOrgs = handleOrgsDataProcessing(orgDetails);

                          if (
                            allOrgs &&
                            userAccountDetails?.tenancy_users_by_pk &&
                            userAccountDetails.tenancy_users_by_pk.user_accounts
                              .length > 0
                          ) {
                            const {
                              accountRoles,
                              availableAccountRoles,
                              roleIdsToRemove,
                            }: {
                              accountRoles: AccountRoles;
                              availableAccountRoles: AccountRoles;
                              roleIdsToRemove: string[];
                            } = processAccountRoles(
                              userAccountDetails.tenancy_users_by_pk
                                ?.user_accounts[0]._user_account_roles,
                              event.loggedInUserHighestRole,
                              orgDetails?.admin_?.accountById?.roles
                            );

                            const assignedTeamIds =
                              userAccountDetails.tenancy_users_by_pk?._user_teams.map(
                                (team) => team.team.id
                              );
                            const userPrimaryAccountId =
                              userAccountDetails.tenancy_users_by_pk
                                ?.user_accounts[0].isPrimaryAccount &&
                              userAccountDetails.tenancy_users_by_pk
                                ?.user_accounts[0].accountId;

                            const projectRolesUpdate: SetUserAccountProjectsRolesMutationVariables['projectRolesUpdate'] =
                              [];
                            const userOrgs: ShortOrgType[] = allOrgs.map(
                              (org, currentOrgIndex) => {
                                const {
                                  assignedProjects,
                                  orgRowSpan,
                                  assignedProjectIds,
                                } =
                                  userAccountDetails.tenancy_users_by_pk!.user_accounts[0].user_account_projects!.reduce(
                                    (
                                      calculatedDetails: OrgRowSpanCalculatorType,
                                      currentProject: UserAccountProject
                                    ) => {
                                      if (
                                        org.id ===
                                        currentProject.project.accountId
                                      ) {
                                        const assignedProjectRoles =
                                          currentProject._user_account_project_roles
                                            .filter((role) => !role.teams)
                                            .map((role) => role.role.id);

                                        currentProject.project.project_product_settings.forEach(
                                          (product) => {
                                            const availableProductRoles =
                                              orgDetails?.admin_?.accountById?.roles?.rows?.filter(
                                                (role) =>
                                                  role.productId ===
                                                    product.product.id &&
                                                  !assignedProjectRoles.includes(
                                                    role.id!
                                                  )
                                              );
                                            if (
                                              !currentProject.availableProjectRoles
                                            ) {
                                              currentProject.availableProjectRoles =
                                                [];
                                            }

                                            if (availableProductRoles) {
                                              currentProject.availableProjectRoles.push(
                                                ...availableProductRoles
                                              );
                                            }
                                          }
                                        );

                                        const userAccountProjectRoles =
                                          currentProject._user_account_project_roles.reduce(
                                            (accumulatedRoles, currentRole) => {
                                              const sameProductRoleIdx =
                                                accumulatedRoles?.findIndex(
                                                  (roles) =>
                                                    roles.role.productId ===
                                                    currentRole.role.productId
                                                );
                                              const sameProductRole =
                                                accumulatedRoles[
                                                  sameProductRoleIdx
                                                ];

                                              if (
                                                (currentRole.teams || [])
                                                  ?.length === 0 &&
                                                sameProductRoleIdx >= 0
                                              ) {
                                                const existingProjectRoleUpdateIndex =
                                                  projectRolesUpdate.findIndex(
                                                    (project) =>
                                                      project.id ===
                                                      currentProject.project.id
                                                  );
                                                // If role is highest possible role for product, then keep that role assigned and remove other low level roles.
                                                if (
                                                  currentRole.role.name ===
                                                    AgentAssistRoleNames.AGENT_ASSIST_ADMIN ||
                                                  currentRole.role.name ===
                                                    AnalyticsRoleNames.ANALYTICS_ADMIN
                                                ) {
                                                  // make this role available with availableProjectRoles
                                                  currentProject.availableProjectRoles?.push(
                                                    {
                                                      id: sameProductRole.role
                                                        .id,
                                                      name: sameProductRole.role
                                                        .name,
                                                      productId:
                                                        sameProductRole.role
                                                          .productId,
                                                    }
                                                  );
                                                  if (
                                                    existingProjectRoleUpdateIndex >=
                                                    0
                                                  ) {
                                                    projectRolesUpdate[
                                                      existingProjectRoleUpdateIndex
                                                    ].rolesToRemove!.push(
                                                      sameProductRole.role.id
                                                    );
                                                  } else {
                                                    projectRolesUpdate.push({
                                                      id: currentProject.project
                                                        .id,
                                                      rolesToRemove: [
                                                        sameProductRole.role.id,
                                                      ],
                                                      rolesToAdd: [],
                                                    });
                                                  }
                                                  accumulatedRoles[
                                                    sameProductRoleIdx
                                                  ] = currentRole;
                                                } else {
                                                  currentProject.availableProjectRoles?.push(
                                                    {
                                                      id: currentRole.role.id,
                                                      name: currentRole.role
                                                        .name,
                                                      productId:
                                                        currentRole.role
                                                          .productId,
                                                      product: {
                                                        id: currentRole.role
                                                          .product?.id,
                                                        name: currentRole.role
                                                          .product
                                                          ?.name as ProductName,
                                                        displayName:
                                                          currentRole.role
                                                            .product
                                                            ?.displayName,
                                                      },
                                                    }
                                                  );
                                                  if (
                                                    existingProjectRoleUpdateIndex >=
                                                    0
                                                  ) {
                                                    projectRolesUpdate[
                                                      existingProjectRoleUpdateIndex
                                                    ].rolesToRemove!.push(
                                                      currentRole.role.id
                                                    );
                                                  } else {
                                                    projectRolesUpdate.push({
                                                      id: currentProject.project
                                                        .id,
                                                      rolesToRemove: [
                                                        currentRole.role.id,
                                                      ],
                                                      rolesToAdd: [],
                                                    });
                                                  }
                                                }

                                                return accumulatedRoles;
                                              } else {
                                                accumulatedRoles.push(
                                                  currentRole
                                                );
                                                return accumulatedRoles;
                                              }
                                            },
                                            [] as UserAccountProject['_user_account_project_roles']
                                          );

                                        currentProject._user_account_project_roles =
                                          sortBy(
                                            userAccountProjectRoles,
                                            'teams'
                                          );

                                        currentProject.project.project_product_settings.sort(
                                          productsSortFunction
                                        );

                                        calculatedDetails.assignedProjects?.push(
                                          currentProject
                                        );
                                        calculatedDetails.assignedProjectIds.push(
                                          currentProject.project.id
                                        );

                                        // Add 1 row span per project
                                        calculatedDetails.orgRowSpan =
                                          calculatedDetails.orgRowSpan + 1;

                                        // Add row span for project products.
                                        calculatedDetails.orgRowSpan =
                                          calculatedDetails.orgRowSpan +
                                          Math.max(
                                            currentProject.project
                                              .project_product_settings.length -
                                              1,
                                            0
                                          );
                                      }
                                      return calculatedDetails;
                                    },
                                    {
                                      assignedProjects: [],
                                      orgRowSpan: 0,
                                      assignedProjectIds: [],
                                    } as OrgRowSpanCalculatorType
                                  );

                                const showAddProjectButton =
                                  !!orgDetails?.admin_?.organizationMetadata
                                    ?.canManageAccess;
                                const availableProjects =
                                  org.projects?.rows?.filter(
                                    (project) =>
                                      !assignedProjectIds.includes(project.id)
                                  ) || [];
                                const availableTeams = org.teams?.rows?.filter(
                                  (team) => !assignedTeamIds.includes(team.id!)
                                );

                                let _orgRowSpan = orgRowSpan;
                                if (
                                  (showAddProjectButton &&
                                    (availableProjects.length > 0 ||
                                      assignedProjects?.length === 0)) ||
                                  (!showAddProjectButton &&
                                    assignedProjects?.length === 0)
                                ) {
                                  // Add extra row only if we are showing add-project button OR no projects available message.
                                  _orgRowSpan = orgRowSpan + 1;
                                }
                                // If we have org more than 3, then collapse all orgs by default.
                                let isCollapsed = allOrgs.length > 3;
                                // If we have old local state for user, make sure to bring collapsed state from local state for better UX.
                                if (
                                  ctx.userDetails[event.userId]?.state?.orgs[
                                    currentOrgIndex
                                  ]
                                ) {
                                  isCollapsed =
                                    !!ctx.userDetails[event.userId]?.state
                                      ?.orgs[currentOrgIndex]?.isCollapsed;
                                }
                                return {
                                  showAddProjectButton,
                                  name: org.name,
                                  id: org.id,
                                  parentAccount: org.parentAccount,
                                  accountRoles: sortBy(accountRoles, 'teams'),
                                  availableAccountRoles,
                                  assignedTeams:
                                    userAccountDetails.tenancy_users_by_pk?._user_teams.filter(
                                      (team) =>
                                        team.team.team_accounts[0].accountId ===
                                        org.id
                                    ),
                                  assignedProjects: orderBy(
                                    assignedProjects,
                                    (assignedProject) =>
                                      assignedProject.project.name.toLowerCase(),
                                    'asc'
                                  ),
                                  orgRowSpan: _orgRowSpan,
                                  availableTeams,
                                  availableProjects,
                                  isPrimaryAccount:
                                    userPrimaryAccountId === org.id,
                                  roles: orgDetails?.admin_?.accountById?.roles,
                                  isCollapsed,
                                };
                              }
                            );

                            const userDetails: UserDetailsType = {
                              firstName:
                                userAccountDetails.tenancy_users_by_pk
                                  .firstName,
                              lastName:
                                userAccountDetails.tenancy_users_by_pk.lastName,
                              id: userAccountDetails.tenancy_users_by_pk.id,
                              userName:
                                userAccountDetails.tenancy_users_by_pk.userName,
                              picture:
                                userAccountDetails.tenancy_users_by_pk.picture,
                              orgs: userOrgs,
                              canManageAccess:
                                !!orgDetails?.admin_?.organizationMetadata
                                  ?.canManageAccess,
                              settings:
                                userAccountDetails.tenancy_users_by_pk.settings,
                            };

                            const _userDetails =
                              userAccountDetails.tenancy_users_by_pk?._user_teams.reduce(
                                (acc, team) => {
                                  return processTeamInheritedDetails({
                                    teamDetails: team.team,
                                    selectedUserDetails: acc,
                                  });
                                },
                                userDetails as UserDetailsType
                              );

                            return {
                              userDetails: _userDetails,
                              userDetailUpdates: {
                                projectRolesUpdate,
                                hasPendingUpdates: false,
                                roleIdsToAdd: [],
                                roleIdsToRemove,
                                teamIdsToAdd: [],
                                teamIdsToRemove: [],
                                projectIdsToAdd: [],
                                projectIdsToRemove: [],
                              },
                            };
                          }

                          return null;
                        }
                      );
                    },
                    onDoneAssignContext({ ctx, data }) {
                      // Update context only if incoming data has differences.
                      if (!data) return;

                      if (!ctx.userDetails[data.userDetails.id]) {
                        ctx.userDetails[data.userDetails.id] = {
                          state: data.userDetails,
                          userDetailUpdates: data.userDetailUpdates,
                          initialState: data.userDetails,
                        };
                      } else if (
                        !isEqual(
                          ctx.userDetails[data.userDetails.id].state,
                          data.userDetails
                        )
                      ) {
                        ctx.userDetails[data.userDetails.id].state =
                          data.userDetails;
                      }
                      return ctx;
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading users: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {
                  entry: (ctx) => {
                    if (
                      hasPendingUpdates(
                        ctx.userDetails[ctx.selectedUser!]?.userDetailUpdates
                      ) &&
                      ctx.userDetails[ctx.selectedUser!]?.state?.canManageAccess
                    ) {
                      sendManagerEvent({
                        type: 'manager.autoSaveUserDetails',
                      });
                    } else {
                      sendManagerEvent({
                        type: 'manager.userDetails.setStateToIdle',
                      });
                    }
                  },
                },
                autoSaveUserDetails: {
                  invoke: createInvokablePromise({
                    src: async (ctx, event): Promise<void> => {
                      if (!ctx.selectedUser) return;

                      if (
                        (
                          ctx.userDetails[ctx.selectedUser!]?.userDetailUpdates
                            .projectRolesUpdate || []
                        ).length
                      ) {
                        const { data, error } =
                          await urqlGql.setUserAccountProjectsRoles({
                            userId: ctx.selectedUser,
                            projectRolesUpdate:
                              ctx.userDetails[ctx.selectedUser!]
                                ?.userDetailUpdates.projectRolesUpdate || [],
                            isConsolidatingDetails: true,
                          });

                        if (haveErrors(data)) {
                          throw new Error(data.errors?.[0]?.message);
                        }

                        if (error) {
                          throw new Error(error.message);
                        }
                      }

                      if (
                        (
                          ctx.userDetails[ctx.selectedUser!]?.userDetailUpdates
                            .roleIdsToRemove || []
                        ).length
                      ) {
                        await handleUpdateUserDetails({
                          userId: ctx.selectedUser,
                          // @todo - do a better job of getting the orgId
                          orgId:
                            ctx.userDetails[ctx.selectedUser!].state?.orgs.find(
                              (org) => org.isPrimaryAccount
                            )?.id || '',
                          userDetails: ctx.userDetails[ctx.selectedUser!],
                          isConsolidatingDetails: true,
                        });
                      }
                    },
                    onDoneTarget: 'idle',
                    onDoneAssignContext({ ctx, data }) {
                      if (!ctx.selectedUser) return;
                      ctx.userDetails[
                        ctx.selectedUser
                      ]!.userDetailUpdates.projectRolesUpdate = [];
                      ctx.userDetails[
                        ctx.selectedUser
                      ]!.userDetailUpdates.hasPendingUpdates = false;
                      return ctx;
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating user details: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                addTeamToOrg: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<{
                      teamDetails:
                        | GetTeamProjectsDetailsQuery['tenancy_teams_by_pk']
                        | undefined;
                      orgId: string;
                      team: NonNullable<AccountTeams>[number];
                    } | null> => {
                      if (
                        event.type !== 'manager.userDetails.addTeam' ||
                        !event.team.id
                      )
                        return null;

                      const { data, error } =
                        await urqlGql.getTeamProjectsDetails({
                          teamId: event.team.id,
                        });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      return {
                        teamDetails: data?.tenancy_teams_by_pk,
                        orgId: event.orgId,
                        team: event.team,
                      };
                    },
                    onDoneTarget: 'idle',
                    onDoneAssignContext({ ctx, data }) {
                      if (!data || !ctx.selectedUser) return;

                      const selectedUserDetails =
                        ctx.userDetails[ctx.selectedUser];
                      if (!selectedUserDetails || !data.teamDetails) return;
                      const orgToUpdate = selectedUserDetails.state?.orgs.find(
                        (org) => org.id === data.orgId
                      );

                      if (!orgToUpdate) return;

                      // Remove the team from available teams
                      orgToUpdate.availableTeams =
                        orgToUpdate.availableTeams?.filter(
                          (availableTeam) => availableTeam.id !== data.team.id
                        );

                      orgToUpdate.assignedTeams?.push({
                        team: data.teamDetails,
                      });

                      if (selectedUserDetails) {
                        processTeamInheritedDetails({
                          teamDetails: data.teamDetails,
                          selectedUserDetails: selectedUserDetails.state!,
                        });
                      }

                      if (!selectedUserDetails.userDetailUpdates.teamIdsToAdd) {
                        selectedUserDetails.userDetailUpdates.teamIdsToAdd = [];
                      }

                      if (
                        !(
                          selectedUserDetails.userDetailUpdates
                            .teamIdsToRemove || []
                        ).includes(data.teamDetails.id)
                      ) {
                        selectedUserDetails.userDetailUpdates.teamIdsToAdd.push(
                          data.teamDetails.id
                        );
                      } else {
                        selectedUserDetails.userDetailUpdates.teamIdsToRemove =
                          (
                            selectedUserDetails.userDetailUpdates
                              .teamIdsToRemove || []
                          ).filter((teamId) => teamId !== data.teamDetails?.id);
                      }

                      selectedUserDetails.userDetailUpdates.hasPendingUpdates =
                        hasPendingUpdates(
                          selectedUserDetails.userDetailUpdates
                        );
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Failed to add team to org, Please try again`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                addNewUser: {
                  states: {
                    loadingPrerequisites: {
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<{
                          userDetails: UserDetailsType;
                        } | null> => {
                          if (event.type !== 'manager.addNewUser') {
                            return null;
                          }

                          return urqlGql
                            .getAccountDetails({ id: event.orgId! })
                            .then((orgDetails) => {
                              if (!orgDetails.data) {
                                throw new Error(
                                  `*OrgDetailsError - ${orgDetails.error?.message}.`
                                );
                              }
                              const allOrgs = handleOrgsDataProcessing(
                                orgDetails.data
                              );

                              const canManageAccess =
                                !!orgDetails?.data.admin_?.organizationMetadata
                                  ?.canManageAccess;
                              const userDetails: UserDetailsType = {
                                firstName: '',
                                lastName: '',
                                id: '',
                                userName: '',
                                picture: '',
                                orgs:
                                  allOrgs?.map((org) => ({
                                    ...org,
                                    isPrimaryAccount: org.id === event.orgId,
                                    showAddProjectButton: canManageAccess,
                                    assignedProjects: [],
                                    availableProjects: org.projects?.rows,
                                    assignedTeams: [],
                                    availableTeams: org.teams?.rows,
                                    accountRoles: [],
                                    availableAccountRoles: processAccountRoles(
                                      [],
                                      event.loggedInUserHighestRole,
                                      orgDetails.data?.admin_?.accountById
                                        ?.roles
                                    ).availableAccountRoles,
                                    roles:
                                      orgDetails.data?.admin_?.accountById
                                        ?.roles,
                                    isCollapsed: allOrgs.length > 3,
                                  })) || [],
                                canManageAccess,
                              };

                              return { userDetails };
                            });
                        },
                        onDoneAssignContext({ ctx, data }) {
                          // Update context only if incoming data has differences.
                          if (!data) return;
                          ctx.userDetails[NEW_USER_TEMP_ID] = {
                            state: data.userDetails,
                            userDetailUpdates: userDetailUpdateInitialState,
                            initialState: data.userDetails,
                          };
                          ctx.selectedUser = NEW_USER_TEMP_ID;
                          return ctx;
                        },
                        onDoneTarget: '#usersDetails.idle',
                        onErrorTarget: '#usersDetails.error',
                        onErrorActions: [
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error adding new user: ${event.data.message}`,
                                autoClose: 3000,
                              });
                            },
                          },
                        ],
                      }),
                    },
                    invitingUser: {
                      entry: immerAssign((ctx, event) => {
                        if (event.type !== 'manager.userDetails.inviteUser')
                          return;

                        if (ctx.userDetails[NEW_USER_TEMP_ID].state) {
                          ctx.userDetails[NEW_USER_TEMP_ID].state.firstName =
                            event.firstName;
                          ctx.userDetails[NEW_USER_TEMP_ID].state.lastName =
                            event.lastName;
                          ctx.userDetails[NEW_USER_TEMP_ID].state.userName =
                            event.email;
                        }
                      }),
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<
                          | {
                              userId: string;
                              createdAt: NonNullable<
                                NonNullable<
                                  AddUserToAccountMutation['admin_']
                                >['addUserToAccount']
                              >['createdAt'];
                              lastActiveAt: NonNullable<
                                NonNullable<
                                  AddUserToAccountMutation['admin_']
                                >['addUserToAccount']
                              >['lastActiveAt'];
                            }
                          | undefined
                        > => {
                          if (event.type !== 'manager.userDetails.inviteUser')
                            return;
                          const { orgId, firstName, lastName, email } = event;

                          try {
                            const {
                              data: newUserDetails,
                              error: newUserDetailsError,
                            } = await urqlGql.addUserToAccount({
                              accountId: orgId!,
                              userName: email,
                              firstName,
                              lastName,
                            });

                            if (newUserDetailsError) {
                              throw new Error(
                                `Error adding new user: ${newUserDetailsError.message}`
                              );
                            }

                            if (newUserDetails?.admin_?.addUserToAccount?.id) {
                              const { userId } = await handleUpdateUserDetails({
                                userId:
                                  newUserDetails?.admin_?.addUserToAccount?.id,
                                orgId: event.orgId!,
                                userDetails: ctx.userDetails[NEW_USER_TEMP_ID],
                              });

                              return {
                                userId,
                                createdAt:
                                  newUserDetails?.admin_?.addUserToAccount
                                    ?.createdAt,
                                lastActiveAt:
                                  newUserDetails?.admin_?.addUserToAccount
                                    ?.lastActiveAt,
                              };
                            }
                            return undefined;
                          } catch (error) {
                            throw new Error(
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-expect-error
                              error?.message || 'Error adding user'
                            );
                          }
                        },
                        onDoneAssignContext({ ctx, data }) {
                          if (!data) return;

                          sendUserMessage({
                            type: 'success',
                            text: 'Sent invite successfully',
                            autoClose: 3000,
                          });

                          ctx.userDetails[NEW_USER_TEMP_ID].state!.id =
                            data.userId;

                          ctx.userDetails[data.userId] = {
                            state: ctx.userDetails[NEW_USER_TEMP_ID].state,
                            userDetailUpdates: userDetailUpdateInitialState,
                            initialState:
                              ctx.userDetails[NEW_USER_TEMP_ID].state,
                          };

                          ctx.users?.push({
                            id: ctx.userDetails[data.userId].state!.id,
                            firstName:
                              ctx.userDetails[data.userId].state!.firstName,
                            lastName:
                              ctx.userDetails[data.userId].state!.lastName,
                            userName:
                              ctx.userDetails[data.userId].state!.userName,
                            createdAt: data.createdAt,
                            lastActiveAt: data.lastActiveAt,
                            status: UserStatus.Invited,
                          });

                          ctx.users = sortBy(ctx.users, 'firstName');
                          ctx.selectedUser = data.userId;
                          return ctx;
                        },
                        onDoneTarget: 'invited',
                        onErrorTarget: 'error',
                        onErrorActions: [
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error adding new user: ${event.data.message}`,
                                autoClose: 3000,
                              });
                            },
                          },
                        ],
                      }),
                    },
                    invited: {
                      after: {
                        1000: {
                          // Once user is invited, set state to idle.
                          target: '#usersDetails.idle',
                          actions: immerAssign((ctx, event) => {
                            ctx.userDetails[NEW_USER_TEMP_ID] = {
                              state: null,
                              userDetailUpdates: userDetailUpdateInitialState,
                              initialState: null,
                            };
                          }),
                        },
                      },
                    },
                    error: {},
                  },
                },
                deleteUser: {
                  states: {
                    confirmUserDeletion: {},
                    deleting: {
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<
                          | {
                              deletedUserId: string;
                            }
                          | undefined
                        > => {
                          if (
                            event.type !==
                            'manager.userDetails.deleteUser.confirmed'
                          ) {
                            return;
                          }

                          try {
                            const {
                              data: deletedUserDetails,
                              error: deletedUserError,
                            } = await urqlGql.removeUserFromAccount({
                              accountId: event.orgId!,
                              userIds: [event.userId!],
                            });

                            if (deletedUserError) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error deleting user: ${deletedUserError.message}`,
                                autoClose: 3000,
                              });
                              return;
                            }

                            if (
                              !deletedUserDetails?.admin_
                                ?.removeUsersFromAccount
                            )
                              return;

                            if (
                              deletedUserDetails?.admin_
                                ?.removeUsersFromAccount[0].errorDetails
                            ) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error deleting user: ${deletedUserDetails?.admin_?.removeUsersFromAccount[0].errorDetails}`,
                                autoClose: 3000,
                              });
                              return;
                            }

                            if (
                              deletedUserDetails?.admin_
                                ?.removeUsersFromAccount[0].succeeded
                            ) {
                              return { deletedUserId: event.userId! };
                            }

                            return undefined;
                          } catch (error) {
                            throw new Error(
                              `Something went wrong deleting user - ${error}`
                            );
                          }
                        },
                        onDoneAssignContext({ ctx, data }) {
                          // Update context only if incoming data has differences.
                          if (!data) return;
                          ctx.users = ctx.users?.filter(
                            (user) => user.id !== data.deletedUserId
                          );
                          ctx.selectedUser = undefined;
                          return ctx;
                        },
                        onDoneTarget: 'deleted',
                        onErrorTarget: 'error',
                        onErrorActions: [
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error deleting selected user: ${event.data.message}`,
                                autoClose: 3000,
                              });
                            },
                          },
                        ],
                      }),
                    },
                    deleted: {},
                    error: {},
                  },
                },
                saveUpdates: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<{ userId: string } | undefined> => {
                      if (event.type !== 'manager.userDetails.saveUpdates')
                        return;
                      const { userId, orgId } = event;
                      const userDetails = ctx.userDetails[userId];
                      if (!userDetails || !orgId) return;

                      return handleUpdateUserDetails({
                        userId,
                        orgId,
                        userDetails,
                      });
                    },
                    onDoneTarget: 'idle',
                    onDoneAssignContext({ ctx, data }) {
                      sendUserMessage({
                        type: 'success',
                        text: 'User details updated successfully',
                        autoClose: 3000,
                      });
                      if (!ctx.selectedUser) return;
                      // Update initial state to save state as a starting point.
                      ctx.userDetails[ctx.selectedUser].initialState =
                        ctx.userDetails[ctx.selectedUser].state;
                      ctx.userDetails[ctx.selectedUser]!.userDetailUpdates =
                        userDetailUpdateInitialState;
                      return ctx;
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating user details: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                error: {},
              },
            },
            importUsers: {
              id: 'importUsers',
              initial: 'idle',
              on: {
                'manager.users.importUsers': {
                  target: '.loading',
                },
              },
              states: {
                idle: {},
                loading: {
                  invoke: createInvokablePromise({
                    src: async (ctx, event): Promise<void> => {
                      if (ctx.selectedOrgId === undefined) {
                        throw Error('Missing organization to import users!');
                      }

                      if (event.type !== 'manager.users.importUsers') return;

                      const request = new XMLHttpRequest();
                      request.withCredentials = true;
                      request.open(
                        'POST',
                        `${import.meta.env.FE_ADMIN_URL}/rest/import-users/${ctx.selectedOrgId}/`
                      );
                      const formData = new FormData();
                      formData.append('file', event.file);
                      request.send(formData);

                      await new Promise((resolve, reject) => {
                        request.onload = () => {
                          const res = JSON.parse(request.response);

                          if (request.status >= 200 && request.status <= 400) {
                            resolve(res);
                          } else {
                            reject(res);
                          }
                        };
                      });
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error importing users`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'success',
                            text: `Users successfully imported`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {},
                error: {},
              },
            },
          },
        },
      },
    },
    global: {
      type: 'parallel',
      states: {
        // none of these should be nested --> might make sense to have a localization machine
        appletTemplates: {
          id: 'appletTemplates',
          initial: 'loading',
          states: {
            loading: {
              invoke: createInvokablePromise({
                id: 'getAppletTemplate',
                src: async (
                  ctx,
                  event
                ): Promise<
                  NonNullable<GetAppletTemplatesQuery['admin_']>['applets']
                > => {
                  const { data, error } = await urqlGql.getAppletTemplates();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }

                  return data?.admin_?.applets;
                },
                onDoneTarget: 'loaded',
                onDoneAssignContext({ ctx, data }) {
                  ctx.appletTemplates = data || [];
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error fetching applet-templates: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            loaded: {},
            error: {},
          },
        },
        languages: {
          id: 'languages',
          initial: 'loading',
          states: {
            loading: {
              invoke: createInvokablePromise({
                id: 'getLanguages',
                src: async (
                  ctx,
                  event
                ): Promise<{
                  languages: ManagerContext['languages'];
                  localizationSettingsConfig: ManagerContext['localizationSettingsConfig'];
                }> => {
                  const { data, error } = await urqlGql.getLanguages();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }

                  const languages = data?.admin_?.locales
                    ? sort(data?.admin_?.locales).asc('name')
                    : [];

                  if (languages.length) {
                    languages.unshift({
                      name: 'Select a language',
                      id: '',
                      locale: '',
                      __typename: 'Locale',
                      languageCode: '',
                    });
                  }

                  return {
                    languages,
                    localizationSettingsConfig:
                      data?.admin_?.localizationSettingsConfig,
                  };
                },
                onDoneTarget: 'loaded',
                onDoneAssignContext({ ctx, data }) {
                  ctx.languages = data.languages;
                  ctx.localizationSettingsConfig =
                    data.localizationSettingsConfig;
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error fetching languages: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            loaded: {},
            error: {},
          },
        },
        // should only be 24 time zones
        timezones: {
          id: 'timezones',
          initial: 'loading',
          states: {
            loading: {
              invoke: createInvokablePromise({
                id: 'getTimeZones',
                src: async (
                  ctx,
                  event
                ): Promise<ManagerContext['timezones'] | []> => {
                  const { data, error } = await urqlGql.getTimeZones();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }

                  const timeZoneData = data?.admin_?.timeZones;
                  const timeZones = timeZoneData
                    ? sort(timeZoneData).asc('label')
                    : [];

                  if (timeZoneData?.length) {
                    timeZones.unshift({
                      label: 'Select a time zone',
                      identifier: '',
                      id: '',
                      __typename: 'TimeZone',
                    });
                  }

                  return transformTimezonesData<Timezone>(timeZones);
                },
                onDoneTarget: 'loaded',
                onDoneAssignContext({ ctx, data }) {
                  ctx.timezones = data;
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error fetching TimeZones: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            loaded: {},
            error: {},
          },
        },

        // products isn't affected by current org so this shouldn't live here
        products: {
          initial: 'idle',
          states: {
            idle: {
              // entry: () => {
              // console.log('ready to load product info!');
              // },
              always: {
                target: 'loading',
                cond: (context, event) => {
                  return context.userInfo?.status === UserStatus.Active;
                },
              },
            },
            loading: {
              invoke: createInvokablePromise({
                src: async (
                  ctx,
                  event
                ): Promise<{
                  products: ManagerContext['products'];
                  allProducts: ManagerContext['allProducts'];
                }> => {
                  const { data, error } = await urqlGql.getProducts();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }

                  return {
                    allProducts: data?.admin_?.allProducts,
                    products: data?.admin_?.allToolProducts,
                  };
                },
                onDoneTarget: 'loaded',
                onDoneAssignContext({ ctx, data }) {
                  if (data !== undefined) {
                    ctx.products = data.products;
                    ctx.allProducts = data.allProducts;
                  }
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error fetching user orgs: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            error: {},
            loaded: {
              // entry: (ctx) => {
              // console.log('loaded products!', ctx.products);
              // },
              always: {
                target: 'loading',
                cond: (context, event) => {
                  // return context.selectedOrgId !== context.
                  return false;
                },
              },
            },
          },
        },
      },
    },
    superAdmin: {
      initial: 'idle',
      states: {
        idle: {},
        dataBaseSnapshot: {
          id: 'dataBaseSnapshot',
          initial: 'loading',
          states: {
            loading: {
              invoke: createInvokablePromise({
                src: async (_ctx, event): Promise<void> => {
                  if (event.type !== 'manager.createDbSnapshot') return;

                  const { data, error } = await urqlGql.makeDbSnapshot();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }
                },
                onDoneTarget: 'loaded',
                onErrorTarget: 'error',
                onDoneActions: [
                  {
                    type: 'sendUserMessage',
                    exec(_ctx, _event) {
                      sendUserMessage({
                        type: 'success',
                        text: `DB snapshot created successfully`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(_ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error making db snapshot: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            loaded: {},
            error: {},
          },
        },
      },
    },
  },
});
