/* eslint-disable react/prop-types */

import {
  AppletInstance,
  DomainTypeEnum,
  ProductName,
  urqlGql,
} from '@pypestream/api-services';
import { CreateProjectMutationVariables } from '@pypestream/api-services/urql';
import {
  AutoSave,
  AutoSaveState,
  Button,
  EntityTypes,
  Filepond,
  FilepondProps,
  IconButton,
  ImageProps,
  Input,
  InputProps,
  Modal,
  ModalIcon,
  Select,
  SelectOption,
  SelectProps,
  Stack,
  TextBody,
  TextTitle,
  Textarea,
  TextareaProps,
} from '@pypestream/design-system';
import { TranslationComponent, useTranslation } from '@pypestream/translations';
import { FC, ForwardedRef, forwardRef, useRef, useState } from 'react';
import { UseFormReturn, useController } from 'react-hook-form';

import { Product } from '../../utils';
import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
} from '../../xstate/app.xstate';
import { ProductCTA } from '../product-cta';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReturnFormType = UseFormReturn<any, unknown, undefined>;

type InputControllerPropsType = {
  form: ReturnFormType;
  name: string;
  index: number;
  placeholder?: string;
  isForbiddenForRemove?: boolean;
  disabled?: boolean;
  variant?: InputProps['variant'];
  validate?: boolean;
  required?: boolean;
  remove: (index: number) => void;
};

type ProjectDetailsFieldName =
  | 'name'
  | 'description'
  | 'timeZoneId'
  | 'projectIcon';
// | 'productIds';

export type FormProjectInfoType = {
  name: string;
  description?: string;
  projectIconId?: string;
  projectIcon?: string;
};

export type FormProjectLocalizationType = {
  timeZoneId?: string;
};

export type FormProjectToolingType = {
  productIds: string[];
};

export type ProjectEnvironmentType = {
  id: string;
  name: string;
  description?: string;
  domains?: {
    type: DomainTypeEnum;
    url: string;
  }[];
};

export type ProjectEnvironmentsType = ProjectEnvironmentType[];

export type FormProjectEnvironmentsType = {
  applets: Partial<AppletInstance>[];
  projectEnvironmentConfigs: ProjectEnvironmentsType;
};

export type FormProjectStateType = FormProjectInfoType &
  FormProjectLocalizationType &
  FormProjectToolingType &
  FormProjectEnvironmentsType;

export type OutputProjectFormType = Omit<
  CreateProjectMutationVariables,
  'accountId'
>;

export const Environments = {
  Production: 'Production',
  Testing: 'Testing',
  Development: 'Development',
  Staging: 'Staging',
};

export type EnvironmentsType = keyof typeof Environments;

const useAutosave = (
  form: ReturnFormType,
  fieldName: ProjectDetailsFieldName
) => {
  const success = useManagerStateMatches(
    `orgRelated.ready.projectDetails.${fieldName}.success`
  )
    ? AutoSaveState.success
    : undefined;

  const error = useManagerStateMatches(
    `orgRelated.ready.projectDetails.${fieldName}.error`
  )
    ? AutoSaveState.error
    : undefined;

  const loading = useManagerStateMatches(
    `orgRelated.ready.projectDetails.${fieldName}.loading`
  )
    ? AutoSaveState.loading
    : undefined;

  const projectId = useManagerCtxSelector((ctx) => ctx.selectedProject);

  const autosaveFieldState: AutoSaveState | undefined =
    loading || success || error;

  const onFileldSaved = ({ detail: { value: data } }: CustomEvent) => {
    sendManagerEvent({
      type: `manager.updateProject.${fieldName}`,
      projectId,
      data,
    });
  };

  const onFieldChanged = ({ detail: { value: updatedValue } }: CustomEvent) => {
    form.setValue(fieldName, updatedValue.value);
  };

  return {
    autosaveFieldState,
    onFileldSaved,
    onFieldChanged,
  };
};

export class ProjectFormFields {
  public ProjectNameField = forwardRef(
    (
      props: InputProps & { _form: ReturnFormType; autosave?: boolean },
      ref: ForwardedRef<InputProps>
    ) => {
      const [t] = useTranslation();

      const { field: nameField, fieldState: nameFiledState } = useController({
        name: 'name',
        control: props._form.control || undefined,
        rules: {
          required:
            t('manager/projects:createProject.required', {
              defaultValue: 'This is a required field',
            }) || '',
        },
      });

      const { autosaveFieldState, onFileldSaved, onFieldChanged } = useAutosave(
        props._form,
        'name'
      );

      const field = (
        <Input
          test-id="project-name"
          ref={ref}
          placeholder={
            t('manager/projects:createProject.step1.name', {
              defaultValue: 'Project name',
            }) || ''
          }
          type="text"
          autocomplete="off"
          value={nameField.value}
          helpText={nameFiledState.error?.message}
          hasError={nameFiledState.invalid}
          name={nameField.name}
          onChange={nameField.onChange}
          {...props}
        />
      );

      const component = props.autosave ? (
        <AutoSave
          fieldState={autosaveFieldState}
          onValueSaved={onFileldSaved}
          onValueChanged={onFieldChanged}
        >
          {field}
        </AutoSave>
      ) : (
        field
      );

      return component;
    }
  );

  public ProjectDescriptionField = forwardRef(
    (
      props: TextareaProps & { _form: ReturnFormType; autosave?: boolean },
      ref: ForwardedRef<TextareaProps>
    ) => {
      const [t] = useTranslation();

      const { autosaveFieldState, onFileldSaved, onFieldChanged } = useAutosave(
        props._form,
        'description'
      );

      const field = (
        <Textarea
          test-id="project-description"
          ref={ref}
          placeholder={
            t('manager/projects:createProject.step1.description', {
              defaultValue: 'Description (optional)',
            }) || ''
          }
          autocomplete="off"
          rows={1}
          {...props}
        />
      );

      const component = props.autosave ? (
        <AutoSave
          fieldState={autosaveFieldState}
          onValueSaved={onFileldSaved}
          onValueChanged={onFieldChanged}
        >
          {field}
        </AutoSave>
      ) : (
        field
      );

      return component;
    }
  );

  public ProjectIconField: FC<{
    uploadProps?: FilepondProps;
    imageProps?: ImageProps;
    form: ReturnFormType;
    readOnly?: boolean;
  }> = ({ uploadProps, imageProps, form, readOnly }) => {
    const { field: projectIconField } = useController({
      name: 'projectIcon',
      control: form.control || undefined,
    });

    const { field: projectIconIdField } = useController({
      name: 'projectIconId',
      control: form.control || undefined,
    });

    const [t] = useTranslation();

    const projectId = useManagerCtxSelector((ctx) => ctx.selectedProject);

    const accountId = useManagerCtxSelector((ctx) => ctx.selectedOrgId);

    const field = (
      <Filepond
        test-id="project-icon"
        readonly={readOnly}
        entityType={EntityTypes.PROJECT}
        accountId={accountId}
        previewLabel={
          t(
            'manager/projects:projectDetails.generalTab.details.placeholders.dragPhoto',
            { defaultValue: 'Drag a photo here' }
          ) || ''
        }
        previewCta={
          t(
            'manager/projects:projectDetails.generalTab.details.placeholders.selectFile',
            { defaultValue: 'Select a file' }
          ) || ''
        }
        pictureSrc={projectIconField.value}
        name={projectIconField.name}
        onChange={({ url, pictureId }) => {
          // this doesn't work!

          sendManagerEvent({
            type: 'manager.updateProject.projectIcon',
            projectId,
            data: {
              name: 'projectIcon',
              value: url || '',
            },
          });
          sendManagerEvent({
            type: 'manager.updateProject.projectIcon',
            projectId,
            data: {
              name: 'projectIconId',
              value: pictureId || '',
            },
          });

          projectIconField.onChange(url || '');

          // what's this used for? is this still needed?
          projectIconIdField.onChange(pictureId || '');
        }}
        {...uploadProps}
      />
    );
    return field;
  };

  public TimeZoneSelect = forwardRef(
    (
      props: SelectProps & { _form: ReturnFormType; autosave?: boolean },
      ref: ForwardedRef<SelectProps>
    ) => {
      const timeZones = useManagerCtxSelector(({ timezones }) => timezones);
      const { autosaveFieldState, onFileldSaved, onFieldChanged } = useAutosave(
        props._form,
        'timeZoneId'
      );

      const field = (
        <Select
          test-id="project-timezone"
          searchKeys={['identifier', 'label', 'searchKeys']}
          data={timeZones}
          placeholder="Select a time zone"
          ref={ref}
          {...props}
        >
          {timeZones.map((timeZone) => {
            return (
              <SelectOption key={timeZone.id} value={timeZone.id}>
                {timeZone.label}
              </SelectOption>
            );
          })}
        </Select>
      );

      const component = props.autosave ? (
        <AutoSave
          fieldState={autosaveFieldState}
          onValueSaved={onFileldSaved}
          onValueChanged={onFieldChanged}
        >
          {field}
        </AutoSave>
      ) : (
        field
      );

      return component;
    }
  );

  public ProjectEnvironmentField: FC<InputControllerPropsType> = ({
    form,
    name,
    index,
    placeholder,
    isForbiddenForRemove,
    variant,
    validate = true,
    required = false,
    disabled,
    remove,
  }) => {
    const [t] = useTranslation();

    const rules = validate
      ? {
          required:
            t('manager/projects:createProject.required', {
              defaultValue: 'This is a required field',
            }) || '',
          validate: async (value: string) => {
            const result = await urqlGql.validateProjectEnvironmentUrls({
              urls: [value],
            });

            let errorMessage =
              t('manager/projects:createProject.step2.errors.deafult', {
                defaultValue: 'This field should be a valid url.',
              }) || '';

            const intersection =
              result?.data?.admin_?.validateProjectEnvironmentUrls?.urls
                ?.map(
                  ({ intersections }) =>
                    intersections?.map((url) => url).join(', ') || ''
                )
                .join(', ') || '';

            if (intersection) {
              errorMessage =
                t('manager/projects:createProject.step2.errors.invalidURL', {
                  defaultValue: `This URL (${intersection}) is used in another project`,
                  intersection,
                }) || '';
            }

            const validationError =
              result?.data?.admin_?.validateProjectEnvironmentUrls?.urls
                ?.map(({ validationResult }) => validationResult)
                .join(', ');

            if (validationError) {
              errorMessage =
                t('manager/projects:createProject.step2.errors.invalidURL', {
                  defaultValue: 'This URL is invalid',
                }) || '';
            }

            return (
              Boolean(
                result?.data?.admin_?.validateProjectEnvironmentUrls?.urls?.every(
                  ({ isValid }) => isValid
                )
              ) || errorMessage
            );
          },
        }
      : {};

    const { field, fieldState } = useController({
      name: `${name}.${index}.url`,
      control: form.control,
      rules,
    });

    return (
      <>
        <Input
          test-id={`project-environment-url-${index}`}
          variant={variant}
          placeholder={placeholder}
          required={required}
          type="text"
          value={field.value}
          helpText={fieldState.error?.message}
          hasError={fieldState.invalid}
          name={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          disabled={disabled}
        />
        {!isForbiddenForRemove && (
          <IconButton name="delete" onClick={() => remove(index)} />
        )}
      </>
    );
  };

  public ProjectToolField: FC<{
    logo: Product['logo'];
    label: string;
    productId: string;
    confirmRemove?: boolean;
    form: ReturnFormType;
    productName?: ProductName;
    required?: boolean;
    disabled?: boolean;
    'test-id'?: string;
  }> = ({
    form,
    logo,
    label,
    productId,
    productName,
    required,
    disabled = false,
    confirmRemove = false,
    'test-id': testId,
  }) => {
    const [t] = useTranslation();

    const { field } = useController({
      name: 'productIds',
      control: form.control,
      rules: { validate: (value) => !!value?.length },
    });
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const onInputChange = (checked: boolean) => {
      if (checked) {
        if (field.value?.includes(productId)) return;

        field.onChange([...field.value, productId]);
      } else {
        if (!field.value?.includes(productId)) return;

        field.onChange(field.value?.filter((id: string) => id !== productId));
      }
    };

    const closeModal = () => setIsModalOpened(false);

    const onClick = () => setIsModalOpened(true);

    const fieldIsChecked = !!field.value?.includes(productId);

    const eventHandler = confirmRemove
      ? fieldIsChecked
        ? { onClick }
        : { onInputChange }
      : { onInputChange };

    return (
      <>
        <ProductCTA
          test-id={testId}
          ref={inputRef}
          logo={logo}
          inputName={field.name}
          inputValue={productId}
          required={required}
          disabled={disabled}
          name={
            t(`manager/common:products.${logo}`, {
              defaultValue: label,
            }) || ''
          }
          checked={fieldIsChecked}
          productName={productName}
          {...eventHandler}
        />
        {confirmRemove && (
          <Modal
            test-id="delete-tool-modal"
            open={isModalOpened}
            size="medium"
            stayOnClickOutside
            stayOnEsc
            onClose={closeModal}
          >
            <ModalIcon
              name="error"
              slot="header"
              style={{ display: 'flex', justifyContent: 'center' }}
            />
            <Stack slot="header" alignItems="center" direction="column">
              <TextTitle size="small">
                <TranslationComponent i18nKey="manager/projects:projectDetails.deleteToolModal.title">
                  Are you sure?
                </TranslationComponent>
              </TextTitle>
              <TextBody variant="secondary">
                <TranslationComponent i18nKey="manager/projects:projectDetails.deleteToolModal.subTitle">
                  You&lsquo;ll permanently lose your:
                </TranslationComponent>

                <ul>
                  <li>
                    <TranslationComponent i18nKey="manager/projects:projectDetails.deleteToolModal.line1">
                      access to this tool
                    </TranslationComponent>
                  </li>
                  <li>
                    <TranslationComponent i18nKey="manager/projects:projectDetails.deleteToolModal.line2">
                      assigned roles
                    </TranslationComponent>
                  </li>
                  <li>
                    <TranslationComponent i18nKey="manager/projects:projectDetails.deleteToolModal.line3">
                      data inside the tool
                    </TranslationComponent>
                  </li>
                </ul>
              </TextBody>
            </Stack>
            <Stack slot="footer" justifyContent="end">
              <Button
                test-id="cancel-delete-tool"
                variant="ghost"
                size="large"
                onClick={closeModal}
              >
                <TranslationComponent i18nKey="manager/common:cancel">
                  Cancel
                </TranslationComponent>
              </Button>
              <Button
                test-id="confirm-delete-tool"
                size="large"
                variant="warning"
                onClick={() => {
                  onInputChange(false);
                  closeModal();
                }}
              >
                <TranslationComponent i18nKey="manager/projects:projectDetails.deleteToolModal.deleteButton">
                  Remove Tool
                </TranslationComponent>
              </Button>
            </Stack>
          </Modal>
        )}
      </>
    );
  };
}
