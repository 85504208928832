import {
  Button,
  ButtonGroup,
  DataTable,
  DataTableWC,
  Dropdown,
  Icon,
  Menu,
  MenuItem,
  PageBody,
  PageSection,
  Search,
  Spacer,
  Stack,
  Text,
  TextTitle,
} from '@pypestream/design-system';
import {
  dateFnsLocales,
  fallbackLanguage,
  TranslationComponent,
  useTranslation,
} from '@pypestream/translations';
import { formatTimeWithTimezone } from '@pypestream/utils';
import { html } from 'lit/static-html.js';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../components';
import {
  OrganizationRoleNames,
  useAuthRole,
  useUserTimeZone,
} from '../../../hooks';
import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
  useManagerStateMatchesAllOf,
} from '../../../xstate/app.xstate';
import { NEW_USER_TEMP_ID } from '../../../xstate/user-details-xstate-helper';

export const Users: React.FC = () => {
  const loaded = useManagerStateMatches('orgRelated.ready.users.loaded');
  const isReadyToLoad = useManagerStateMatchesAllOf([
    'orgRelated.ready.users.idle',
    'orgRelated.ready.currentOrg.selected',
  ]);
  const isImporting = useManagerStateMatches(
    'orgRelated.ready.importUsers.loading'
  );

  const { orgId, users, defaultLanguage } = useManagerCtxSelector((ctx) => ({
    orgId: ctx.selectedOrgId,
    users: ctx.users,
    defaultLanguage: ctx.userInfo?.defaultLanguage,
  }));

  const userTimeZone = useUserTimeZone();
  const navigate = useNavigate();

  const tableRef = useRef<DataTableWC>(null);
  const [t] = useTranslation();
  const isAdminOrManagerRole = useAuthRole([
    OrganizationRoleNames.ADMIN,
    OrganizationRoleNames.SUPER_ADMIN,
    OrganizationRoleNames.MANAGER,
  ]);

  useEffect(() => {
    if (isReadyToLoad) {
      sendManagerEvent('manager.users.loadUsers');
    }
  }, [isReadyToLoad]);

  return (
    <PageBody background="none">
      <>
        <PageSection>
          {!loaded ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Loader relative />
            </div>
          ) : (
            <>
              <Stack alignItems="center" justifyContent="space-between">
                <TextTitle size="xsmall">
                  <TranslationComponent i18nKey="manager/users:usersList.users">
                    Users
                  </TranslationComponent>
                </TextTitle>
                <Stack alignItems="center" justifyContent="end" gutter="small">
                  <Search
                    size="large"
                    style={{ maxWidth: '240px' }}
                    onInput={(e) => {
                      tableRef.current?.table.setGlobalFilter(
                        String(e.target?.value) || ''
                      );
                    }}
                  />
                  {isAdminOrManagerRole && (
                    <ButtonGroup>
                      <Button
                        disabled={isImporting}
                        size="large"
                        variant="secondary"
                        id="bulkInviteUsersMenu"
                      >
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          id="bulkInviteUsersInput"
                          accept=".csv"
                          onChange={(e) => {
                            if (!e.target.files?.[0]) return;
                            sendManagerEvent('manager.users.importUsers', {
                              file: e.target.files[0],
                            });
                          }}
                        />
                        <Icon slot="suffix" name="chevron-down" size="xsmall" />
                        {isImporting ? (
                          <TranslationComponent i18nKey="manager/users:usersList.bulkInviting">
                            Inviting...
                          </TranslationComponent>
                        ) : (
                          <TranslationComponent i18nKey="manager/users:usersList.bulkInviteUsers">
                            Bulk Invite
                          </TranslationComponent>
                        )}
                      </Button>
                      <Dropdown
                        trigger="bulkInviteUsersMenu"
                        placement="bottom"
                      >
                        <Menu>
                          <MenuItem
                            onClick={() =>
                              document
                                .getElementById('bulkInviteUsersInput')
                                ?.click()
                            }
                          >
                            <Icon slot="prefix" name="upload" size="xsmall" />
                            Upload CSV
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              window.open(
                                import.meta.env
                                  .FE_BULK_INVITE_USERS_TEMPLATE_URL,
                                '_blank'
                              )
                            }
                          >
                            <Icon slot="prefix" name="open" size="xsmall" />
                            Open Template
                          </MenuItem>
                        </Menu>
                      </Dropdown>
                      <Button
                        size="large"
                        onClick={() => {
                          navigate(
                            `/organization/${orgId}/users/${NEW_USER_TEMP_ID}`
                          );
                        }}
                      >
                        <Icon slot="prefix" name="plus" size="xsmall" />
                        <TranslationComponent i18nKey="manager/users:usersList.inviteUser">
                          Invite User
                        </TranslationComponent>
                      </Button>
                    </ButtonGroup>
                  )}
                </Stack>
              </Stack>
              <Spacer size="xlarge"></Spacer>
              <DataTable
                ref={tableRef}
                filterColumns={['name', 'email']}
                columns={[
                  {
                    accessorKey: 'name',
                    accessorFn: (row) => `${row.firstName} ${row.lastName}`,
                    header: () =>
                      t('manager/users:usersList.table.name', {
                        defaultValue: 'Name',
                      }) || '',
                    cell: ({ row, renderValue }) => html`
                      <ps-stack
                        gutter="small"
                        justifyContent="start"
                        alignItems="center"
                        direction="row"
                        nowrap
                      >
                        <ps-avatar
                          label="${row.original.firstName}"
                          src="${row.original.picture || ''}"
                        ></ps-avatar>
                        <ps-stack
                          gutter="none"
                          display="inline-flex"
                          direction="column"
                        >
                          <ps-text size="2xsmall" font-weight="medium"
                            >${`${row.original.firstName} ${row.original.lastName}`}</ps-text
                          >
                        </ps-stack>
                        ${row.original.status === 'INVITED'
                          ? html`<ps-tag minimal new-variant="filled"
                              >Pending invite</ps-tag
                            >`
                          : ''}
                      </ps-stack>
                    `,
                  },
                  {
                    accessorKey: 'email',
                    accessorFn: (row) => row.userName,
                    header: () =>
                      t('manager/users:usersList.table.email', {
                        defaultValue: 'Email',
                      }) || '',
                    cell: ({ row, renderValue }) =>
                      html`<ps-text-body variant="secondary"
                        >${row.original.userName}</ps-text-body
                      >`,
                  },
                  {
                    accessorKey: 'lastActive',
                    header: () =>
                      t('manager/users:usersList.table.lastActive', {
                        defaultValue: 'Last Active',
                      }) || '',
                    cell: ({ row, renderValue }) => {
                      return html`<ps-text-body variant="secondary"
                        >${row.original.status === 'INVITED'
                          ? `${t('manager/users:usersList.table.invited', {
                              defaultValue: 'Invited',
                            })}  `
                          : ''}${formatTimeWithTimezone({
                          fromDate: row.original.lastActiveAt,
                          locale:
                            dateFnsLocales[defaultLanguage || fallbackLanguage],
                          userTimeZone,
                        })}</ps-text-body
                      >`;
                    },
                  },
                ]}
                data={users?.map((user) => ({
                  ...user,
                  name: `${user.firstName} ${user.lastName}`,
                }))}
                onRowSelectionChange={(event) => {
                  if (event.detail.selected && event.detail.selected.length) {
                    event.detail.selected.map((item) => {
                      const dataTable = event.currentTarget as DataTableWC;

                      dataTable['table'].resetRowSelection();

                      sendManagerEvent({
                        type: 'manager.selectUser',
                        id: item.original.id,
                      });

                      navigate(
                        `/organization/${orgId}/users/${item.original.id}`
                      );

                      return item;
                    });
                  }
                }}
                enableRowSelection
              ></DataTable>

              {!users ||
                (!users.length && (
                  <Text
                    size="xsmall"
                    textAlign="center"
                    className="u-margin-top-xlarge"
                  >
                    <TranslationComponent i18nKey="manager/users:usersList.createToGetStarted">
                      Create a user to get started.
                    </TranslationComponent>
                  </Text>
                ))}
            </>
          )}
        </PageSection>
      </>
    </PageBody>
  );
};
