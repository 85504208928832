import {
  TableRow,
  TableRowWC,
  TableCell,
  Text,
  Stack,
  logos,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Dropdown,
  Image,
} from '@pypestream/design-system';
import { format, parseISO } from 'date-fns';
import { FC, useCallback, useRef } from 'react';
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router-dom';
import {
  ToolsTranslationMapper,
  TranslationComponent,
  i18n,
} from '@pypestream/translations';

import {
  sendManagerEvent,
  useManagerCtxSelector,
} from '../../xstate/app.xstate';
import { ManagerContext, ProjectNew } from '../../xstate/manager.xstate.types';
import { DEFAULT_SQUARE_ICON_BASE64 } from '..';
import { useFormatTimeWithTimezone } from '../../hooks';

interface ProjectItemProps {
  item: NonNullable<ManagerContext['projectsNew']>[number];
  onClick: () => void;
}

export const ProjectItem: FC<ProjectItemProps> = ({ item, onClick }) => {
  const {
    accountId,
    id,
    selectedProjectURL,
    conditionalProducts,
    hasAvailableProduct,
    files,
  } = item;

  const translatedUpdatedAt = useFormatTimeWithTimezone({
    fromDate: item.updatedAt as string,
    formatType: 'strict',
  });

  const picture = files?.[0]?.url;

  const navigate = useNavigate();

  const { userInfo, isEmployee } = useManagerCtxSelector((ctx) => ({
    userInfo: ctx.userInfo,
    isEmployee: ctx.userInfo.isPypestreamEmployee,
  }));

  const canManageAccess = userInfo?.canManageAccess;

  const buttonUUID = useRef(`icon-button-${nanoid()}`);

  const handleProjectItemClick = useCallback(
    (e: React.MouseEvent<TableRowWC>, _: ProjectNew) => {
      const element = e.target as HTMLElement;
      if (
        element.closest('ps-icon-button') ||
        element.tagName === 'PS-TEXTAREA'
      ) {
        return;
      }

      if (!selectedProjectURL) {
        onClick();

        return;
      }

      window.open(selectedProjectURL, '_self');
    },
    [onClick, selectedProjectURL]
  );

  const renderProjectItem = () => {
    const { t } = i18n;

    return (
      <TableRow
        data-name={item.name}
        key={id}
        {...(hasAvailableProduct
          ? {
              actionable: hasAvailableProduct,
              onClick: (e) => handleProjectItemClick(e, item),
            }
          : {
              style: {
                cursor: 'not-allowed',
              },
              title:
                t`manager/common:homepage.projects.YouDontHaveAccessToTheToolsInThisProject` ||
                '',
            })}
      >
        <TableCell nowrap>
          <Stack alignItems="center" nowrap>
            <Image
              test-id={`${item.name}-project-icon`}
              width="48px"
              height="48px"
              src={picture}
              alt={`${item.name || 'Project Icon'}`}
              fallback={DEFAULT_SQUARE_ICON_BASE64}
            />
            <Text size="2xsmall" display="inline-flex">
              {item?.name}
            </Text>
          </Stack>
        </TableCell>
        <TableCell nowrap>
          <Stack
            gutter="small"
            alignItems="center"
            justifyContent="start"
            nowrap
          >
            {conditionalProducts
              ?.filter((product) => product.integrated)
              .map((product) => {
                return (
                  <Tooltip
                    key={product.productId}
                    content={
                      product.disabled
                        ? t`manager/common:homepage.projects.YouDontHaveAccessToThisTool` ||
                          ''
                        : t(`${ToolsTranslationMapper[product.name]}`) || ''
                    }
                  >
                    <img
                      key={product.productId}
                      src={logos[product.logo]}
                      style={{
                        filter: product.disabled
                          ? 'grayscale(100%)'
                          : undefined,
                        height: '20px',
                        width: '20px',
                      }}
                      alt={t(`${ToolsTranslationMapper[product.name]}`) || ''}
                      title={t(`${ToolsTranslationMapper[product.name]}`) || ''}
                    />
                  </Tooltip>
                );
              })}
          </Stack>
        </TableCell>
        <TableCell nowrap>
          <Stack
            gutter="small"
            alignItems="center"
            justifyContent="start"
            nowrap
          >
            <Tooltip
              test-id={`${item.name}-updated-at-tooltip`}
              content={format(parseISO(item?.updatedAt), 'MM/dd/yyyy')}
            >
              <Text size="2xsmall">{translatedUpdatedAt}</Text>
            </Tooltip>
          </Stack>
        </TableCell>
        {canManageAccess && (
          <TableCell align="end">
            <IconButton
              test-id={`${item.name}-meatballs`}
              name="meatballs"
              id={buttonUUID.current}
            />
            <Dropdown
              trigger={buttonUUID.current}
              placement="bottom-end"
              width="auto"
            >
              <Menu>
                <MenuItem
                  test-id={`${item.name}-manage-project`}
                  disabled={!accountId || !id || !isEmployee}
                  onClick={(e) => {
                    e.preventDefault();
                    sendManagerEvent({
                      type: 'manager.selectProject',
                      id: id,
                    });
                    navigate(`/organization/${accountId}/projects/${id}`);
                    window.scrollTo(0, 0);
                  }}
                >
                  <TranslationComponent i18nKey="manager/common:homepage.projects.manageProject">
                    Manage Project
                  </TranslationComponent>
                </MenuItem>
              </Menu>
            </Dropdown>
          </TableCell>
        )}
      </TableRow>
    );
  };

  return hasAvailableProduct ? (
    renderProjectItem()
  ) : (
    <Tooltip content="Contact your admin for access">
      {renderProjectItem()}
    </Tooltip>
  );
};
