import { TextTitle, Spacer, TextBody } from '@pypestream/design-system';

interface ProjectTitlesProps {
  step: string;
  title: string;
  subTitle?: string;
}

export const ProjectTitles = ({
  title,
  subTitle,
  step,
}: ProjectTitlesProps) => {
  return (
    <>
      <TextTitle
        test-id={`${step}-create-project-form-title`}
        size="small"
        textAlign="center"
      >
        {title}
      </TextTitle>
      {subTitle ? (
        <>
          <Spacer size="small" />
          <TextBody
            test-id={`${step}-create-project-form-sub-title`}
            variant="secondary"
            textAlign="center"
          >
            {subTitle}
          </TextBody>
        </>
      ) : null}
    </>
  );
};
