import * as React from 'react';
import { createComponent } from '@lit/react';
import {
  FilepondWC,
  type SetProfilePictureParams,
  type SetProfilePicture,
  type UploadResponse,
  type FilepondProps as FilepondPropsWC,
} from './filepond.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export {
  type SetProfilePicture,
  type UploadResponse,
  type SetProfilePictureParams,
};

export type FilepondProps = GenerateComponentTypeWithChildren<FilepondWC> & {
  onChange?: FilepondPropsWC['onChange'];
  onUpload?: SetProfilePicture;
};

export type FilepondPropsWithRef = GeneratePropsWithRef<FilepondProps>;

export const Filepond = createComponent({
  tagName: FilepondWC.tagname,
  elementClass: FilepondWC,
  react: React,
}) as FilepondPropsWithRef;
