import * as React from 'react';
import { createComponent } from '@lit/react';
import { NavControlsWC } from './nav-controls.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type NavControlsProps = GenerateComponentTypeWithChildren<NavControlsWC>;

export type NavControlsPropsWithRef = GeneratePropsWithRef<NavControlsProps>;

export const NavControls = createComponent({
  tagName: NavControlsWC.tagname,
  elementClass: NavControlsWC,
  react: React,
}) as NavControlsPropsWithRef;
