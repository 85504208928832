import { Trigger } from '@pypestream/api-services';
import { MenuItem, SelectWC, Spacer, Stack } from '@pypestream/design-system';
import { FormContextType, toIdSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import React from 'react';
import { ActionsMenu } from './actions-menu';
import { PackageVersionsSelect } from './package-versions-select';
import { PackagesSelect } from './packages-select';
import { GetPackagesByPackageTypeQuery } from '@pypestream/api-services/dist/generated/graphql.urql';
import { includePrereleaseFilter } from '../../utils/include-prerelease-filter';
import { getDefaultRegistry } from '@rjsf/core';

const {
  fields: { SchemaField },
} = getDefaultRegistry();

interface TriggerItemProps {
  trigger: Trigger;
  packages: NonNullable<
    NonNullable<GetPackagesByPackageTypeQuery['admin_']>['packages']
  >;
  formContext: FormContextType;
  onPackageChange: (e: React.ChangeEvent<SelectWC>, index?: number) => void;
  onPackageVersionChange: (
    e: React.ChangeEvent<SelectWC>,
    index?: number
  ) => void;
  onAdd: () => void;
  onRemove: () => void;
  onSchemaChange: (options: unknown) => void;
  onBlur: (id: string, value: unknown) => void;
  onFocus: (id: string, value: unknown) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registry: any;
  index: number;
}

export const TriggerItem: React.FC<TriggerItemProps> = ({
  trigger,
  packages,
  onPackageChange,
  onPackageVersionChange,
  onAdd,
  onRemove,
  onSchemaChange,
  onBlur,
  onFocus,
  registry,
  index,
  formContext,
}) => {
  const pkg = packages.find((p) => p.pkgName === trigger.name);

  const packageSchema = pkg?.latestVersion?.schema;
  return (
    <div>
      <Stack>
        <PackagesSelect
          packages={packages}
          onChange={(e) => onPackageChange(e, index)}
          id="triggers_select"
          value={trigger.name || undefined}
        />
        <PackageVersionsSelect
          versions={
            packages
              .find((p) => p.pkgName === trigger.name)
              ?.versions?.filter((v) =>
                includePrereleaseFilter(
                  v.version,
                  formContext.includePrerelease
                )
              ) || []
          }
          onChange={(e) => onPackageVersionChange(e, index)}
          id="triggers_select"
          value={trigger.packageVersionId || undefined}
        />

        <ActionsMenu>
          <MenuItem disabled={false} onClick={onAdd}>
            Add Trigger
          </MenuItem>
          <MenuItem onClick={onRemove}>Remove Trigger</MenuItem>
        </ActionsMenu>
      </Stack>
      <Spacer size="small" />
      {!packageSchema ? (
        trigger?.name && <div>Schema not found</div>
      ) : (
        <>
          <SchemaField
            onChange={onSchemaChange}
            onBlur={onBlur}
            formData={trigger.options}
            onFocus={onFocus}
            readonly={false}
            name={trigger.packageVersionId || ''}
            disabled={false}
            registry={registry}
            idSchema={toIdSchema(validator, packageSchema, `triggers_${index}`)}
            schema={packageSchema}
          />
        </>
      )}
      <Spacer size="large" />
    </div>
  );
};
