import './skeleton.scss';

const Skeleton = () => {
  return (
    <div className="c-skeleton">
      <div className="c-skeleton__row">
        <div className="c-skeleton__avatar" />
        <div className="c-skeleton__project" />
      </div>
      <div className="c-skeleton__items">
        <div className="c-skeleton__tools" />
        <div className="c-skeleton__date" />
        <div className="c-skeleton__actions" />
      </div>
    </div>
  );
};

export default Skeleton;
