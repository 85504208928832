/* eslint-disable @typescript-eslint/ban-ts-comment */
export enum ProductName {
  Manager = 'Manager',
  ContactCenter = 'ContactCenter',
  Analytics = 'Analytics',
  Admin = 'Admin',
  ProStudio = 'ProStudio',
}

export type BaseURLsToTools = Record<ProductName, string>;

enum Environment {
  Production = 'production',
  Candidate = 'candidate',
  UAT = 'uat',
  EU = 'eu',
  main = 'main',
}

type LowercaseEnum<T> = {
  // @ts-ignore
  [K in keyof T]: Lowercase<T[K]>;
};

export const createEnumObject = (
  enumObj: typeof ProductName
): LowercaseEnum<typeof ProductName> => {
  const result: Partial<LowercaseEnum<typeof ProductName>> = {};

  for (const [key, value] of Object.entries(enumObj)) {
    // @ts-ignore
    result[key as keyof typeof enumObj] = (
      value as string
    ).toLowerCase() as Lowercase<typeof value>;
  }

  return result as LowercaseEnum<typeof ProductName>;
};

const company = 'pypestream';
const topLevelDomains = {
  productionUS: 'com',
  productionEU: 'eu',
  development: 'dev',
};
const managerLocal = 'localhost:5173';
const analyticsLocal = 'localhost.pypestream.dev:8000';

export const generateBaseURLsToTools = (
  location: Location
): BaseURLsToTools => {
  const { host } = location;

  const { Manager, ContactCenter, Analytics, Admin, ProStudio } =
    createEnumObject(ProductName);

  const baseUrls = {
    contactCenterCandidate: `https://${ContactCenter}.${Environment.Candidate}.${company}.${topLevelDomains.development}`,
    contactCenterProdUS: `https://${ContactCenter}.${company}.${topLevelDomains.productionUS}`,
    contactCenterProdEU: `https://${ContactCenter}.${company}.${topLevelDomains.productionEU}`,

    analyticsLocal: `https://${analyticsLocal}`,
    analyticsCandidate: `https://${Analytics}.${Environment.Candidate}.${company}.${topLevelDomains.development}`,
    analyticsProdUS: `https://${Analytics}.${company}.${topLevelDomains.productionUS}`,
    analyticsProdEU: `https://${Analytics}.${company}.${topLevelDomains.productionEU}`,

    managerLocal: `http://${managerLocal}`,
    managerCandidate: `https://${Manager}.${company}.${topLevelDomains.development}`,
    managerProdUS: `https://${Manager}.${company}.${topLevelDomains.productionUS}`,
    managerProdEU: `https://${Manager}.${company}.${topLevelDomains.productionEU}`,

    adminCandidate: `https://${Admin}.${Environment.Candidate}.${company}.${topLevelDomains.development}`,
    adminProdUS: `https://${Admin}.${company}.${topLevelDomains.productionUS}`,
    adminProdEU: `https://${Admin}.${company}.${topLevelDomains.productionEU}`,

    proStudioCandidate: `https://${ProStudio}.${Environment.Candidate}.${company}.${topLevelDomains.development}`,
    proStudioProdUS: `https://${ProStudio}.${company}.${topLevelDomains.productionUS}`,
    proStudioProdEU: `https://${ProStudio}.${company}.${topLevelDomains.productionEU}`,
  };

  const defaultUrls = {
    ContactCenter: baseUrls.contactCenterCandidate,
    Analytics: baseUrls.analyticsCandidate,
    Admin: baseUrls.adminCandidate,
    ProStudio: baseUrls.proStudioCandidate,
  };

  const productionUSUrls = {
    ContactCenter: baseUrls.contactCenterProdUS,
    Analytics: baseUrls.analyticsProdUS,
    Admin: baseUrls.adminProdUS,
    ProStudio: baseUrls.proStudioProdUS,
  };

  const productionEUUrls = {
    ContactCenter: baseUrls.contactCenterProdEU,
    Analytics: baseUrls.analyticsProdEU,
    Admin: baseUrls.adminProdEU,
    ProStudio: baseUrls.proStudioProdEU,
  };

  const environments = {
    productionUS: {
      Manager: baseUrls.managerProdUS,
      ...productionUSUrls,
    },
    productionEU: {
      Manager: baseUrls.managerProdEU,
      ...productionEUUrls,
    },
    candidate: {
      Manager: baseUrls.managerCandidate,
      ...defaultUrls,
    },
    uat: {
      Manager: `https://${Manager}.${Environment.UAT}.${company}.${topLevelDomains.development}`,
      ContactCenter: `https://${ContactCenter}.${Environment.UAT}.${company}.${topLevelDomains.development}`,
      Analytics: `https://${Analytics}.${Environment.UAT}.${company}.${topLevelDomains.development}`,
      Admin: `https://${Admin}.${Environment.UAT}.${company}.${topLevelDomains.development}`,
      ProStudio: `https://${ProStudio}.${Environment.UAT}.${company}.${topLevelDomains.development}`,
    },
    preview: (dynamicPart: string | null, appName: ProductName) => ({
      ...defaultUrls,
      Manager:
        dynamicPart && appName === ProductName.Manager
          ? `https://${dynamicPart}.${Manager}.${company}.${topLevelDomains.development}`
          : baseUrls.managerCandidate,
      Analytics:
        dynamicPart && appName === ProductName.Analytics
          ? `https://${Analytics}-pr-${dynamicPart}.preview.${company}.${topLevelDomains.development}`
          : baseUrls.analyticsCandidate,
    }),
    local: (appName: ProductName) => ({
      ...defaultUrls,
      Manager: appName === ProductName.Manager ? '' : baseUrls.managerCandidate,
      Analytics:
        appName === ProductName.Analytics
          ? baseUrls.analyticsLocal
          : baseUrls.analyticsCandidate,
    }),
  };

  if (host.endsWith(`.${company}.${topLevelDomains.productionUS}`)) {
    return environments.productionUS;
  }
  if (host.endsWith(`.${company}.${topLevelDomains.productionEU}`)) {
    return environments.productionEU;
  }
  if (
    host === `${Manager}.${company}.${topLevelDomains.development}` ||
    host.endsWith(
      `.${Environment.Candidate}.${company}.${topLevelDomains.development}`
    )
  ) {
    return environments.candidate;
  }
  if (
    host.endsWith(
      `.${Environment.UAT}.${company}.${topLevelDomains.development}`
    )
  ) {
    return environments.uat;
  }

  const commitHashManagerRegex = new RegExp(
    `^[a-z0-9]{10}\\.${Manager}\\.${company}\\.${topLevelDomains.development}$`
  );

  if (commitHashManagerRegex.test(host)) {
    const commitHash = host.split('.')[0];
    return environments.preview(commitHash, ProductName.Manager);
  }

  const analyticsPreviewRegex = new RegExp(
    `^${Analytics}-pr-(\\d+)\\.preview\\.${company}\\.${topLevelDomains.development}$`
  );

  if (analyticsPreviewRegex.test(host)) {
    const match = host.match(analyticsPreviewRegex);
    const prNumber = match ? match[1] : null;
    return environments.preview(prNumber, ProductName.Analytics);
  }
  if (host === managerLocal) {
    return environments.local(ProductName.Manager);
  }

  if (host === analyticsLocal) {
    return environments.local(ProductName.Analytics);
  }

  const productPattern = [...Object.values(createEnumObject(ProductName))].join(
    '|'
  );
  const newEnvironmentRegex = new RegExp(
    `^(${productPattern})\\.([a-z0-9-]+)\\.${company}\\.${topLevelDomains.development}$`
  );
  const newEnvMatch = host.match(newEnvironmentRegex);

  if (newEnvMatch) {
    const product = newEnvMatch[1];
    const environment = newEnvMatch[2];
    const rest = host.split(`${product}.${environment}.`)[1];

    return {
      Manager: `https://${Manager}.${environment}.${rest}`,
      ContactCenter: `https://${ContactCenter}.${environment}.${rest}`,
      Analytics: `https://${Analytics}.${environment}.${rest}`,
      Admin: `https://${Admin}.${environment}.${rest}`,
      ProStudio: `https://${ProStudio}.${environment}.${rest}`,
    };
  }

  return environments.candidate;
};
