/* eslint-disable wc/guard-super-call */
import { classMap } from 'lit/directives/class-map.js';
import { html, unsafeCSS } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { animateTo, shimKeyframesHeightAuto, stopAnimations } from './animate';
import { getAnimation, setDefaultAnimation } from './animation-registry';
import { watch } from '../base-element/directives/watch.directive';
import { BaseElement, customElement } from '../base-element';
import { Translate } from '../base-element/mixins/translation-mixin';
// eslint-disable-next-line import/no-cycle
import { ChildTreeItemWC } from '../../smart-components/universal-nav/universal-nav-tree-child.wc';
import { IconWC } from '../icon/icon.wc';

import styles from './tree-item.scss?inline';

/**
 * @summary A tree item serves as a hierarchical node that lives inside a tree component.
 * @status stable
 * @since 2.0

 * @dependency ps-icon
 *
 * @event ps-expand - Emitted when the tree item expands.
 * @event ps-after-expand - Emitted after the tree item expands and all animations are complete.
 * @event ps-collapse - Emitted when the tree item collapses.
 * @event ps-after-collapse - Emitted after the tree item collapses and all animations are complete.
 *
 * @slot - The default slot.
 *
 * @csspart base - The component's base wrapper.
 * @csspart item - The tree item's container. This element wraps everything except slotted tree item children.
 * @csspart item--disabled - Applied when the tree item is disabled.
 * @csspart item--expanded - Applied when the tree item is expanded.
 * @csspart item--selected - Applied when the tree item is selected.
 * @csspart expand-button - The container that wraps the tree item's expand button and spinner.
 * @csspart label - The tree item's label.
 * @csspart children - The container that wraps the tree item's nested children.
 */

@customElement('ps-tree-item')
export class TreeItemWC extends Translate(BaseElement) {
  static styles = unsafeCSS(styles);

  static dependencies = {
    'ps-icon': IconWC,
  };

  /** Expands the tree item. */
  @property({ type: Boolean, reflect: true }) expanded = false;

  /** Draws the tree item in a selected state. */
  @property({ type: Boolean, reflect: true }) selected = false;

  /** Disables the tree item. */
  @property({ type: Boolean, reflect: true }) disabled = false;

  @state() isLeaf = false;

  @query('slot:not([name])') defaultSlot: HTMLSlotElement;

  @query('slot[name=children]') childrenSlot: HTMLSlotElement;

  @query('.c-tree-item__item') itemElement: HTMLDivElement;

  @query('.c-tree-item__children') childrenContainer: HTMLDivElement;

  connectedCallback() {
    super.connectedCallback();

    this.setAttribute('role', 'treeitem');
    this.setAttribute('tabindex', '-1');
    this.dir = 'ltr';

    if (this.isNestedItem()) {
      this.slot = 'children';
    }
  }

  firstUpdated() {
    this.childrenContainer.hidden = !this.expanded;
    this.childrenContainer.style.height = this.expanded ? 'auto' : '0';

    this.isLeaf = this.getChildrenItems().length === 0;
    this.handleExpandedChange();
  }

  private async animateCollapse() {
    this.emit('ps-collapse');

    await stopAnimations(this.childrenContainer);

    const { keyframes, options } = getAnimation(this, 'tree-item.collapse', {
      dir: this.dir,
    });
    await animateTo(
      this.childrenContainer,
      shimKeyframesHeightAuto(keyframes, this.childrenContainer.scrollHeight),
      options
    );
    this.childrenContainer.hidden = true;

    this.emit('ps-after-collapse');
  }

  static isTreeItem(node: Element) {
    return (
      (node instanceof TreeItemWC &&
        node.getAttribute('role') === 'treeitem') ||
      node instanceof ChildTreeItemWC
    );
  }

  // Checks whether the item is nested into an item
  private isNestedItem(): boolean {
    const parent = this.parentElement;
    return !!parent && TreeItemWC.isTreeItem(parent);
  }

  private handleChildrenSlotChange() {
    this.isLeaf = this.getChildrenItems().length === 0;
  }

  private async animateExpand() {
    this.emit('ps-expand');

    await stopAnimations(this.childrenContainer);
    this.childrenContainer.hidden = false;

    const { keyframes, options } = getAnimation(this, 'tree-item.expand', {
      dir: this.dir,
    });
    await animateTo(
      this.childrenContainer,
      shimKeyframesHeightAuto(keyframes, this.childrenContainer.scrollHeight),
      options
    );
    this.childrenContainer.style.height = 'auto';

    this.emit('ps-after-expand');
  }

  @watch('disabled')
  handleDisabledChange() {
    this.setAttribute('aria-disabled', this.disabled ? 'true' : 'false');
  }

  @watch('selected')
  handleSelectedChange() {
    this.setAttribute('aria-selected', this.selected ? 'true' : 'false');
  }

  @watch('expanded', { waitUntilFirstUpdate: true })
  handleExpandedChange() {
    if (!this.isLeaf) {
      this.setAttribute('aria-expanded', this.expanded ? 'true' : 'false');
    } else {
      this.removeAttribute('aria-expanded');
    }
  }

  @watch('expanded', { waitUntilFirstUpdate: true })
  handleExpandAnimation() {
    if (this.expanded) {
      this.animateExpand();
    } else {
      this.animateCollapse();
    }
  }

  /** Gets all the nested tree items in this node. */
  getChildrenItems({
    includeDisabled = true,
  }: { includeDisabled?: boolean } = {}): TreeItemWC[] {
    return this.childrenSlot
      ? ([...this.childrenSlot.assignedElements({ flatten: true })].filter(
          (item: HTMLElement | Element) => {
            if ('disabled' in item) {
              if (includeDisabled || !item.disabled) {
                return TreeItemWC.isTreeItem(item);
              }
              return false;
            }
            return TreeItemWC.isTreeItem(item);
          }
        ) as TreeItemWC[])
      : [];
  }

  render() {
    const showExpandButton = !this.isLeaf;

    return html`
      <div
        part="base"
        class="${classMap({
          'c-tree-item': true,
          'c-tree-item--expanded': this.expanded,
          'c-tree-item--selected': this.selected,
          'c-tree-item--disabled': this.disabled,
          'c-tree-item--leaf': this.isLeaf,
          'c-tree-item--has-expand-button': showExpandButton,
        })}"
      >
        <div
          class="c-tree-item__item"
          part="
            item
            ${this.disabled ? 'item--disabled' : ''}
            ${this.expanded ? 'item--expanded' : ''}
            ${this.selected ? 'item--selected' : ''}
          "
        >
          <ps-icon-button
            part="expand-button"
            class=${classMap({
              'c-tree-item__expand-button': true,
              'c-tree-item__expand-button--visible': showExpandButton,
            })}
            tabindex="-1"
            name="chevron-right"
            size="xsmall"
            variant="primary"
            @click=${(e: MouseEvent) => {
              e.stopPropagation();
              if (this.disabled) return;
              this.expanded = !this.expanded;
            }}
          >
          </ps-icon-button>
          ${this.slotted('prefix')
            ? html`<slot name="prefix" class="c-tree-item__prefix"></slot>`
            : null}
          <slot class="c-tree-item__label" part="label"></slot>
        </div>

        <div class="c-tree-item__children" part="children">
          <div class="c-tree-item__children-group" role="group">
            <slot
              name="children"
              @slotchange="${this.handleChildrenSlotChange}"
            ></slot>
          </div>
        </div>
      </div>
    `;
  }
}

setDefaultAnimation('tree-item.expand', {
  keyframes: [
    { height: '0', opacity: '0', overflow: 'hidden' },
    { height: 'auto', opacity: '1', overflow: 'hidden' },
  ],
  options: { duration: 250, easing: 'cubic-bezier(0.4, 0.0, 0.2, 1)' },
});

setDefaultAnimation('tree-item.collapse', {
  keyframes: [
    { height: 'auto', opacity: '1', overflow: 'hidden' },
    { height: '0', opacity: '0', overflow: 'hidden' },
  ],
  options: { duration: 200, easing: 'cubic-bezier(0.4, 0.0, 0.2, 1)' },
});

declare global {
  interface HTMLElementTagNameMap {
    'ps-tree-item': TreeItemWC;
  }
}
