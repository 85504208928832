import {
  Action,
  Package,
  PackageTypeEnum,
  PackageVersion,
} from '@pypestream/api-services';
import { getDefaultRegistry } from '@rjsf/core';
import { toIdSchema, WidgetProps } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import React from 'react';
import { usePackages } from '../use-packages';
import { includePrereleaseFilter } from '../utils/include-prerelease-filter';
import { PackageVersionsSelect } from './triggers-widget/package-versions-select';

const {
  fields: { SchemaField },
} = getDefaultRegistry();

export type ActionPackage = Action & Package;

export const ActionsWidget: React.FC<WidgetProps> = (props) => {
  const {
    value = [],
    onChange,
    onBlur,
    onFocus,
    registry,
    formContext,
  } = props;

  const { packages } = usePackages(PackageTypeEnum.Action);

  return (
    <div>
      {value.map((actionSchema: Action, index: number) => {
        const packageVersion = actionSchema.packageVersionId
          ? actionSchema.package?.versions?.find(
              (v) => v?.id === actionSchema.packageVersionId
            )
          : actionSchema.package?.latestVersion;
        if (!packageVersion?.schema || !packageVersion.id) {
          return <div key={index}>Schema not found</div>;
        }
        const versions = actionSchema.package?.versions;
        return (
          <React.Fragment key={index}>
            <PackageVersionsSelect
              versions={
                versions
                  ? versions
                      .filter((v): v is PackageVersion => !!v?.version)
                      .filter(
                        (v) =>
                          v?.version &&
                          includePrereleaseFilter(
                            v?.version,
                            formContext.includePrerelease
                          )
                      )
                  : []
              }
              value={actionSchema.packageVersionId || 'latest'}
              onChange={(e) =>
                onChange(
                  value.with(0, {
                    ...value[0],
                    packageVersionId: e.target.value,
                    packageVersion: packages
                      .find((p) => p.pkgName === value[index].name)
                      ?.versions?.find((v) => v.id === e.target.value),
                  })
                )
              }
            />
            <br />
            <SchemaField
              readonly={false}
              disabled={false}
              {...props}
              onChange={(options) => {
                onChange(value.with(index, { ...value[index], options }));
              }}
              value={value}
              onBlur={onBlur}
              formData={actionSchema.options}
              onFocus={onFocus}
              uiSchema={{
                codeBlock: {
                  'ui:widget': 'CodeBlockWidget',
                },
              }}
              name={packageVersion.id}
              registry={registry}
              idSchema={toIdSchema(validator, packageVersion.schema, `actions`)}
              schema={packageVersion.schema}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};
