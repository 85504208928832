type UrqlGqlModule =
  | typeof import('@pypestream/api-services/urql.limited-access.candidate').urqlGqlLimitedCandidate
  | typeof import('@pypestream/api-services/urql.limited-access.prodUS').urqlGqlLimitedProdUS
  | typeof import('@pypestream/api-services/urql.limited-access.prodEU').urqlGqlLimitedProdEU
  | typeof import('@pypestream/api-services/urql.limited-access.uat').urqlGqlLimitedUat
  | typeof import('@pypestream/api-services/urql.limited-access.main').urqlGqlLimitedMain;

type UrqlGqlLimitedClient = Promise<UrqlGqlModule>;

export const ENV_MODULE_MAP: Record<string, () => UrqlGqlLimitedClient> = {
  prodUS: async () =>
    (await import('@pypestream/api-services/urql.limited-access.prodUS'))
      .urqlGqlLimitedProdUS,
  prodEU: async () =>
    (await import('@pypestream/api-services/urql.limited-access.prodEU'))
      .urqlGqlLimitedProdEU,
  uat: async () =>
    (await import('@pypestream/api-services/urql.limited-access.uat'))
      .urqlGqlLimitedUat,
  main: async () =>
    (await import('@pypestream/api-services/urql.limited-access.main'))
      .urqlGqlLimitedMain,
  candidate: async () =>
    (await import('@pypestream/api-services/urql.limited-access.candidate'))
      .urqlGqlLimitedCandidate,
};

export const setEnvModuleMapForTesting = (map: typeof ENV_MODULE_MAP) => {
  Object.assign(ENV_MODULE_MAP, map);
};

export const urqlClient = async (
  _host?: Location['host']
): UrqlGqlLimitedClient => {
  const { host: locationHost } = window.location;
  const host = _host || locationHost;

  let env;

  if (host.endsWith('pypestream.com')) {
    env = 'prodUS';
  }

  if (host.endsWith('pypestream.eu')) {
    env = 'prodEU';
  }

  if (host.endsWith('uat.pypestream.dev')) {
    env = 'uat';
  }

  if (host.endsWith('main.pypestream.dev')) {
    env = 'main';
  }

  if (
    env === undefined ||
    host.endsWith('contactcenter.main.pypestream.dev') // https://pypestream.atlassian.net/browse/MNGR-703
  ) {
    env = 'candidate';
  }

  // @todo - Remove before stable release
  console.log('Detected environment in design system:', env);

  const moduleLoader = ENV_MODULE_MAP[env];
  return moduleLoader();
};
